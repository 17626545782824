import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { IMedia } from "./InvestmentsAndFundsEditController.web";
import { toast } from "react-toastify";



export interface IFavourite{
    animal: string;
    sport: string;
    food: string;
    drink: string;
    alcohol: string;
    holiday_destination: string;
    clothing_brand: string;
    singer: string;
    actor: string;
    movie: string;
    clothing_item: string;
    age: string;
    date: string;
    about_age: string;
    home: string;
    colour: string;
    holiday: string;
    have_image_clothing_item: boolean;
    files: (File | IMedia)[];
}

interface IFavouriteAttributes {
  id: number;
  animal: string;
  sport: string;
  food: string;
  drink: string;
  alcohol: string;
  holiday: string;
  holiday_destination: string;
  clothing_brand: string;
  singer: string;
  actor: string;
  movie: string;
  clothing_item: string;
  age: string;
  date: string;
  about_age: string;
  home: string;
  colour: string;
  account_id: number;
  have_image_clothing_item: boolean;
  files: IMedia[];
}

interface IFavouriteInformationResponse {
  id: string;
  type: string;
  attributes: IFavouriteAttributes;
}

export interface IMetaData {
  message: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNext: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
favourite: IFavourite,
maxFavCloth: number,
loading: boolean,
isEdit: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutFavouriteController extends BlockComponent<
  Props,
  S,
  SS
> {
  maxFileSize : number = 15 * 1024 * 1024;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        favourite: {
            animal: '',
            sport: '',
            food: '',
            drink: '',
            alcohol: '',
            holiday_destination: '',
            clothing_brand: '',
            singer: '',
            actor: '',
            movie: '',
            clothing_item: '',
            age: '',
            date: '',
            about_age: '',
            home: '',
            colour: '',
            holiday: '',
            have_image_clothing_item: false, 
            files: [], 
          },
          maxFavCloth: 0,
          loading: false,
          isEdit: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId === this.callGetFavouriteDataApiId){
        if(responseJSON.data){
          this.handleGetFavouriteApiResponse(responseJSON.data as IFavouriteInformationResponse);
        }else{
          let messError = responseJSON?.errors[0]
          if(messError){
              let key = Object.keys(messError)[0]
              let message = key + " " + messError[key]
              this.props.handleError(message.replace("_", " "))
          }
        }
      }
    else if (apiRequestCallId === this.callEditFavouriteDataApiId) {
        this.setState({ isEdit: false })
        if (responseJSON.data) {
            this.props.handleSuccess()
        } else {
            let messError = responseJSON?.errors[0]
            if (messError) {
                let key = Object.keys(messError)[0]
                let message = key + " " + messError[key]
                this.props.handleError(message.replace("_", " "))
            }
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  callGetFavouriteDataApiId: string = "";
  callEditFavouriteDataApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getMaxOutFits();
    this.getFavouriteData();
  }

  getMaxOutFits = async () =>{
    let subscriptionData = await getStorageData("active_subscription");
    let parsedSubscriptionData = JSON.parse(subscriptionData)?.features?.max_favourite_outfits
    if(parsedSubscriptionData){
      this.setState({maxFavCloth: parseInt(parsedSubscriptionData)})
    }
  }

  getFavouriteData = async() => {
    const token = await getStorageData("token");

    this.callGetFavouriteDataApiId = sendAPIRequest(
        configJSON.getAboutMeFavouriteDataApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };

  handleGetFavouriteApiResponse = (response: IFavouriteInformationResponse) => {
        const attributes = response.attributes;
  
        let favourite = {
          animal: attributes.animal,
          sport: attributes.sport,
          food: attributes.food,
          drink: attributes.drink,
          alcohol: attributes.alcohol,
          holiday_destination: attributes.holiday_destination,
          clothing_brand: attributes.clothing_brand,
          singer: attributes.singer,
          actor: attributes.actor,
          movie: attributes.movie,
          clothing_item: attributes.clothing_item,
          age: attributes.age,
          date: attributes.date,
          about_age: attributes.about_age,
          home: attributes.home,
          colour: attributes.colour,
          holiday: attributes.holiday,
          have_image_clothing_item: attributes.have_image_clothing_item, 
          files: attributes.files?.map((mediaFile) => {
            return({
              file_id: mediaFile.file_id,
              file_name: mediaFile.file_name,
              content_type: mediaFile.content_type,
              file_size: mediaFile.file_size,
              url: mediaFile.url,
            })
          }) || [], 
        } as IFavourite;

        this.setState({ favourite });   
  };

  toggleEditMode = () => {
    this.setState({ isEdit: true });
  };

  handleAboutFavouriteDataFormSubmit = async (values: IFavourite) => {
    if(!this.state.isEdit){
      this.toggleEditMode();
      return;
    }

    const token = await getStorageData("token");
    const formData = new FormData();
    this.setState({loading: true});

      Object.entries(values).forEach(([keyName, value]) => {
        if(keyName !== "files"){
          formData.append(`favourite[${keyName}]`, value);
        }
        if (keyName === 'files' && values.have_image_clothing_item && value.length > 0) {
          value.forEach((file: File) => {
            if (!('content_type' in file)) {
              formData.append(`favourite[files][]`, file as Blob);
            }
          });
        }
      });

    this.callEditFavouriteDataApiId = sendAPIRequest(
      configJSON.getAboutMeFavouriteDataApiEndPoint,
      {
        method: configJSON.formAPiMethod,
        headers: {
          token,
        },
        body: formData,
      }
    );
  };


  handleSwitchChange = async (
    value: boolean,
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
     setFieldValue(`have_image_clothing_item`, value);
  }

  handleFavFileUpload = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    prevFiles: (File | IMedia)[]) => {
    if (prevFiles.length > 0 && 'content_type' in prevFiles[0]) {
      prevFiles = [];
    }
    if (event.target.files) {
      const favFile = Array.from(event.target.files);
      const oversizedFiles = favFile.filter(file => file.size > this.maxFileSize);    
    if (oversizedFiles.length > 0) {
      this.props.handleError(configJSON.maxFileSizeError)
      return 
    }
    let files= [...prevFiles, ...favFile]
    if(files.length > this.state.maxFavCloth){
      let fileLengthError = `Maximum ${this.state.maxFavCloth} files is allowed.`
      this.props.handleError(fileLengthError)
      return
    }
    setFieldValue(`files`,files)
    }
  };

  calenderIcon = () => {
    return (
      <div style={{ cursor: "grab" }}>
        <svg fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.5 9.08984H20.5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.9955 13.6992H12.0045" stroke-width="2" stroke="#A190C2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 13.6992H8.30329" stroke-width="2" stroke="#A190C2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 16.6992H8.30329" stroke-width="2" stroke="#A190C2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </div>
    );
  };
  // Customizable Area End
}
