import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { IMedia } from "./InvestmentsAndFundsEditController.web";
import { toast } from "react-toastify";



export interface IMoment {
    moment: string;
    description: string;
    files: (File | IMedia)[],
};

export interface IProudMoment {
    proudMoment: IMoment[],
};

export interface IProudMomentsResponse {
    id: string;
    type: string;
    attributes: IProudMomentAttributes;
}

interface IProudMomentAttributes {
    id: number;
    moment: string;
    description: string;
    account_id: number;
    files: IMedia[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleError: (error: string) => void
    handleSuccess: () => void
    handleNext: (path: string) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    aboutMoment: IProudMoment,
    isLoading: boolean,
    isEdit: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AboutMomentEditController extends BlockComponent<
    Props,
    S,
    SS
> {
    maxFileSize: number = 15 * 1024 * 1024;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            aboutMoment: {
                proudMoment: [{
                    moment: '',
                    description: '',
                    files: [],
                }]
            },
            isLoading: false,
            isEdit: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.callGetAboutMomentDataApiId) {
            if (responseJSON.data) {
                this.handleGetMomentApiResponse(responseJSON.data as IProudMomentsResponse[]);
            } else {
                let messError = responseJSON?.errors[0]
                if (messError) {
                    let key = Object.keys(messError)[0]
                    let message = key + " " + messError[key]
                    this.props.handleError(message.replace("_", " "))
                }
            }
        }
        else if (apiRequestCallId === this.callEditAboutMomentDataApiId) {
            this.setState({ isEdit: false })
            if (responseJSON.data) {
                this.props.handleSuccess()
            }
            else {
                let proudError = responseJSON?.errors[0]
                if (proudError) {
                    let key = Object.keys(proudError)[0]
                    let message = key + " " + proudError[key]
                    this.props.handleError(message.replace("_", " "))
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    callGetAboutMomentDataApiId: string = "";
    callEditAboutMomentDataApiId: string = "";

    async componentDidMount() {
        super.componentDidMount();
        this.getMomentData();
    }

    getMomentData = async () => {
        const token = await getStorageData("token");

        this.callGetAboutMomentDataApiId = sendAPIRequest(
            configJSON.getAboutMeMomentDataApiEndPoint,
            {
                method: configJSON.getApiRequest,
                headers: {
                    token,
                },
            }
        );
    };

    handleGetMomentApiResponse = (response: IProudMomentsResponse[]) => {
        console.log(response);

        let aboutMoment = {
            proudMoment: response.length > 0 ? response.map(
                (moment) => {
                    return (
                        {
                            moment: moment.attributes.moment,
                            description: moment.attributes.description,
                            files: moment.attributes.files?.map((mediaFile) => {
                                return ({
                                    file_id: mediaFile.file_id,
                                    file_name: mediaFile.file_name,
                                    content_type: mediaFile.content_type,
                                    file_size: mediaFile.file_size,
                                    url: mediaFile.url,
                                })
                            }) || [],
                        }
                    )
                }
            ) : [{
                moment: '',
                description: '',
                files: [],
            }]
        } as IProudMoment;

        this.setState({ aboutMoment });
    };

    toggleEditMode = () => {
        this.setState({ isEdit: true });
    };

    // Convert file (either an existing IMedia or new File) to Blob
    convertToBlob = async (file: IMedia | File): Promise<Blob> => {
        if ('content_type' in file) {
            return await urlToFile(file.url, file.file_name);
        } else {
            return file as Blob;
        }
    };

    handleAboutMomentInformationsFormSubmit = async (values: IProudMoment) => {
        if (!this.state.isEdit) {
            this.toggleEditMode();
            return;
        }

        const token = await getStorageData("token");
        const formData = new FormData();
        this.setState({ isLoading: true });

        await this.appendMomentDetails(formData, values.proudMoment);

        this.callEditAboutMomentDataApiId = sendAPIRequest(
            configJSON.getAboutMeMomentDataApiEndPoint,
            {
                method: configJSON.postApiRequest,
                headers: {
                    token,
                },
                body: formData,
            }
        );
    };

    // Helper to append moment details to formData
  appendMomentDetails = async (formData: FormData, momentDetails: IMoment[]) => {
    for (const moment of momentDetails) {
      for (const [keyName, value] of Object.entries(moment)) {
        if (value) {
          if (keyName === "files" && Array.isArray(moment.files) && moment.files.length > 0) {
            await this.appendMomentsFiles(formData, moment.files);
          } else {
            formData.append(`moments[][${keyName}]`, value as string);
          }
        }
      }
    }
  };

  // Helper to append moment files to formData
  appendMomentsFiles = async (formData: FormData, files: (File | IMedia)[]) => {
    for (const file of files) {
      const blobFile = await this.convertToBlob(file);
      formData.append(`moments[][files][]`, blobFile);
    }
  };


  handleProudFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
        (field: string,
            value: any,
            shouldValidate?: boolean | undefined): void;
        (arg0: string, arg1: string): void;
    },
    index: number,
    prevFiles: (File | IMedia)[]) => {
    if (prevFiles.length > 0 && 'content_type' in prevFiles[0]) {
        prevFiles = [];
    }
    if (event.target.files) {
        const proudFiles = Array.from(event.target.files);
        const oversizedFiles = proudFiles.filter(file => file.size > this.maxFileSize);
        if (oversizedFiles.length > 0) {
            this.props.handleError(configJSON.maxFileSizeError)
            return
        }
        let files = [...prevFiles, ...proudFiles]
        setFieldValue(`proudMoment.${index}.files`, files)
    }
};

    // Customizable Area End
}
