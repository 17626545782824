import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import MyLocationController, {
    configJSON,
    Props,
} from "./MyLocationController.web";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../landingpage/src/WebHeader.web";
import GenericModal from "../../../components/src/GenericModal";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import { rightTickImg } from "../../forgot-password/src/assets";
import AddEditLocation from "./AddEditLocation.web";
import { theme } from "./GeoLocationSearch.web";
import { IMedia } from "./GeoLocationSearchController.web";


// Customizable Area End

export default class MyLocation extends MyLocationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderMarkerImage = (_file: File | IMedia) => {
        let file = (_file as IMedia)
        return (file.content_type.startsWith("image/") ? <img src={file.url} alt="file" className="markerImage" /> :
            <video src={file.url} muted className="markerImage" />
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { addLocationDialog, successMes, sucessDialog, locationBreadCrumb, markersList } = this.state;
        const { navigation } = this.props
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Wrapper>
                        <WebHeader navigation={navigation} />
                        <Box className="mainContainer">
                            <BreadcrumbStepper isMap={true} steps={locationBreadCrumb} onStepClick={(path) => this.handleNavigation(path)} />

                            <Grid container className="listContainer">
                                {markersList.map((_data, index) => (
                                    <>
                                        <Grid item xs={12} className="markerDetails">
                                            {_data.attributes?.files[0] &&
                                                <Grid item style={{ paddingRight: "15px" }}>
                                                    {this.renderMarkerImage(_data.attributes.files[0])}
                                                </Grid>}
                                            <Grid item className="details">
                                                <Grid container className="locationContainer">
                                                    <Grid item className="locationName">
                                                        <Typography variant="h1" style={{ cursor: "grab" }} data-test-id="textLocations" onClick={() => this.handleEdits(_data.attributes)} >{_data.attributes.location_name}</Typography>
                                                    </Grid>
                                                    <Grid item >
                                                        <Button variant="text" data-test-id="viewLocation" onClick={() => this.handleView(_data.attributes)}>
                                                            {configJSON.viewLocation}
                                                        </Button>
                                                        <Button variant="text" data-test-id="editLocation" onClick={() => this.handleEdit(_data.attributes)}>
                                                            {configJSON.editMarker}
                                                        </Button>
                                                        <Button variant="text" style={{ color: "#DC2626" }} data-test-id="deleteLocation" onClick={() => this.handleDeleteClick(_data.attributes.id)}>
                                                            {configJSON.deleteMarker}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Typography variant="h6" className="description">
                                                    {_data.attributes.description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {this.isLastIndex(index) && <Grid item xs={12} className="markerUnderLine" />}
                                    </>

                                ))}
                            </Grid>
                        </Box>
                        <WebFooter handleNavigation={this.handleNavigation} />
                    </Wrapper>
                    {addLocationDialog && (
                        <AddEditLocation
                            id="addEditLocation"
                            markerDetails={this.state.isReview ? this.markerDetails() : this.markerDetailsText()}
                        />
                    )}
                    {sucessDialog && <GenericModal
                        open={sucessDialog}
                        onClose={this.handleSucessClose}
                        data-test-id="successDialog"
                    >
                        <ThemeProvider theme={theme}>
                            <Wrapper>
                                {successMes === "" ?
                                    <Box className="contentWrapper">
                                        <Box className="descriptionWrapper">
                                            <Typography
                                                variant="h1"
                                                align="center"
                                            >
                                                {configJSON.deleteLocation}
                                            </Typography>
                                            <Typography align="center" variant="h2">
                                                {configJSON.deleteMessage}
                                            </Typography>
                                        </Box>
                                        <Box width='100%'>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                data-test-id="deleteButton"
                                                onClick={this.handleDeleteMark}
                                            >
                                                {configJSON.deleteLocation}
                                            </Button>
                                        </Box>
                                    </Box>
                                    : <Box className="contentWrapper">
                                        <Box className="descriptionWrapper">
                                            <img alt="success"
                                                src={rightTickImg}
                                                className="sucessImage" />
                                            <Typography align="center" variant="body2">
                                                {successMes}
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                            </Wrapper>
                        </ThemeProvider>
                    </GenericModal>}
                </ThemeProvider>
            </>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export const Wrapper = styled("div")(({ theme }) => ({
    "& .mainContainer": {
        padding: '40px 56px'
    },
    "& .markerDetails": {
        padding: "24px 0 23px",
        display: "flex",
    },
    "& .markerUnderLine": {
        height: "1px",
        borderBottom: "1px dashed #CBD5E1"
    },
    "& .markerImage": {
        width: 122,
        borderRadius: 8,
        aspectRatio: 1,
        objectFit: "cover",
    },
    "& .details": {
        flexGrow: 1
    },
    "& .listContainer": {
        padding: '20px 0'
    },
    "& .locationName": {
        width: '30%',
        flexGrow: 1,
        "& h1": {
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingRight: '10px'
        }
    },
    "& .sucessImage": {
        width: "56px",
        margin: "0px auto",
        height: "56px",
    },
    "& .description": {
        display: '-webkit-box',
        WebkitLineClamp: 4,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    "& .contentWrapper": {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
    },
    "& .descriptionWrapper": {
        maxWidth: "339px",
        display: "flex",
        marginBottom: "32px",
        gap: "16px",
        flexDirection: "column",
        justifyContent: "center",
    },
    "& .locationContainer": {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    [theme.breakpoints.down(600)]: {
        "& .mainContainer": {
            padding: '40px'
        },
        "& .locationContainer": {
            flexDirection: "column",
        },
        "& .locationName": {
            width: '100%'
        }
    }

}));
// Customizable Area End