import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { IMedia } from "./InvestmentsAndFundsEditController.web";
import { toast } from "react-toastify";

export interface IHaveHobbies {
    name: string,
    location: string,
    duration: string,
    files: (File | IMedia)[],
};

export interface IHobbies {
    have_hobbies: string;
    hobby_details_attributes: IHaveHobbies[]
};

export interface IHobbiesInformationResponse {
    id: string;
    type: string;
    attributes: IHobbiesInformationAttributes;
}

interface IHobbiesInformationAttributes {
    id: number;
    have_hobbies: number;
    account_id: number;
    hobby_details: IHobbyDetails;
}

interface IHobbyDetails {
    data: IHobbyDetailData[];
}

interface IHobbyDetailData {
    id: string;
    type: string;
    attributes: IHobbyDetailAttributes;
}

interface IHobbyDetailAttributes {
    id: number;
    name: string;
    location: string;
    duration: string;
    files: IMedia[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleError: (error: string) => void
    handleSuccess: () => void
    handleNext: (path: string) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    hobbies: IHobbies,
    maxHobbies: number,
    isloading: boolean,
    isEdit: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AboutHobbiesController extends BlockComponent<
    Props,
    S,
    SS
> {
    maxFileSize: number = 15 * 1024 * 1024;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            hobbies: {
                have_hobbies: '0',
                hobby_details_attributes: [{
                    name: "",
                    location: "",
                    duration: "",
                    files: []
                }]
            },
            maxHobbies: 0,
            isloading: false,
            isEdit: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.callGetAboutHobbiesDataApiId) {
            if (responseJSON.data) {
                this.handleGetHobbiesApiResponse(responseJSON.data as IHobbiesInformationResponse);
            } else {
                let messError = responseJSON?.errors[0]
                if (messError) {
                    let key = Object.keys(messError)[0]
                    let message = key + " " + messError[key]
                    this.props.handleError(message.replace("_", " "))
                }
            }
        }
        else if (apiRequestCallId === this.callEditAboutHobbiesDataApiId) {
            this.setState({ isEdit: false })
            if (responseJSON.data) {
                this.props.handleSuccess()
            } else {
                let hobbiesErr = responseJSON?.errors[0]
                if (hobbiesErr) {
                    let key = Object.keys(hobbiesErr)[0]
                    let message = key + " " + hobbiesErr[key]
                    this.props.handleError(message.replace("_", " "))
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    callGetAboutHobbiesDataApiId: string = "";
    callEditAboutHobbiesDataApiId: string = "";

    async componentDidMount() {
        super.componentDidMount();
        this.getMaxHobbies();
        this.getHobbiesData();
    }

    getMaxHobbies = async () => {
        let subscriptionData = await getStorageData("active_subscription");
        let parsedSubscriptionData = JSON.parse(subscriptionData)?.features?.max_hobbies
        if (parsedSubscriptionData) {
            this.setState({ maxHobbies: parseInt(parsedSubscriptionData) })
        }
    }

    getHobbiesData = async () => {
        const token = await getStorageData("token");

        this.callGetAboutHobbiesDataApiId = sendAPIRequest(
            configJSON.getAboutMeHobbiesDataApiEndPoint,
            {
                method: configJSON.getApiRequest,
                headers: {
                    token,
                },
            }
        );
    };

    handleGetHobbiesApiResponse = (response: IHobbiesInformationResponse) => {
        const attributes = response.attributes;

        let hobbies = {
            have_hobbies: attributes.have_hobbies.toString(),
            hobby_details_attributes: attributes.hobby_details.data.length > 0 ? attributes.hobby_details.data.map(
                (hobby) => {
                    return (
                        {
                            name: hobby.attributes.name,
                            location: hobby.attributes.location,
                            duration: hobby.attributes.duration,
                            files: hobby.attributes.files?.map((mediaFile) => {
                                return ({
                                    file_id: mediaFile.file_id,
                                    file_name: mediaFile.file_name,
                                    content_type: mediaFile.content_type,
                                    file_size: mediaFile.file_size,
                                    url: mediaFile.url,
                                })
                            }) || [],
                        }
                    )
                }
            ) : [{
                name: "",
                location: "",
                duration: "",
                files: []
            }]
        } as IHobbies;

        this.setState({ hobbies });
    };

    checkForAddHobbiesBtn = (index: number, length: number) => {
        return this.state.isEdit && index === 0 && length < this.state.maxHobbies
    };

    checkForRemoveBtn = (index: number, length: number) => {
        return this.state.isEdit && index !== 0
    };

    // Convert file (either an existing IMedia or new File) to Blob
    convertToBlob = async (file: IMedia | File): Promise<Blob> => {
        if ('content_type' in file) {
            return await urlToFile(file.url, file.file_name);
        } else {
            return file as Blob;
        }
    };

    toggleEditMode = () => {
        this.setState({ isEdit: true });
    };

    handleAboutHobbiesDataFormSubmit = async (values: IHobbies) => {
        if (!this.state.isEdit) {
            this.toggleEditMode();
            return;
        }

        const token = await getStorageData("token");
        const formData = await this.buildFormData(values);
        this.setState({ isloading: true });

        this.callEditAboutHobbiesDataApiId = sendAPIRequest(
            configJSON.getAboutMeHobbiesDataApiEndPoint,
            {
                method: configJSON.postApiRequest,
                headers: {
                    token,
                },
                body: formData,
            }
        );
    };

    private async buildFormData(values: IHobbies) {
        const formData = new FormData();
    
        // Add top-level fields
        Object.entries(values).forEach(([keyName, value]) => {
            if (keyName !== "hobby_details_attributes") {
                formData.append(`hobby[${keyName}]`, value);
            }
        });
    
        // Add hobby details attributes
        if(values.have_hobbies === "1"){
            for (const hobby of values.hobby_details_attributes) {
                await this.appendHobbyDetails(formData, hobby);
            }
        }
    
        return formData;
    }
    
    private async appendHobbyDetails(formData: FormData, hobby: IHaveHobbies) {
        for (const [keyName, value] of Object.entries(hobby)) {
            if (!value) continue;
    
            if (keyName === 'files' && Array.isArray(hobby.files) && hobby.files.length > 0) {
                await this.appendFiles(formData, hobby.files);
            } else {
                formData.append(`hobby[hobby_details_attributes][][${keyName}]`, value as string);
            }
        }
    }
    
    private async appendFiles(formData: FormData, files: (File | IMedia)[]) {
        for (const file of files) {
            const blobFile = await this.convertToBlob(file);
            formData.append(`hobby[hobby_details_attributes][][files][]`, blobFile);
        }
    }

    validationSchema = () => {
        if (this.state.isEdit) {
            return (
                Yup.object().shape({
                    hobby_details_attributes: Yup.array().when(['have_hobbies'], {
                        is: (have_hobbies) => have_hobbies === "1",
                        then: Yup.array().of(Yup.object().shape({
                            name: Yup.string().nullable().required("Please enter name"),
                            location: Yup.string().nullable().required("Please enter location")
                        })),
                        otherwise: Yup.array().of(Yup.object().nullable()),
                    })
                })
            )
        }
        else {
            return Yup.object().shape({});
        }
    }


    handleHoddieFile = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
        {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void;
            (arg0: string, arg1: string): void;
        },
        index: number,
        prevFiles: (File | IMedia)[]) => {
        if (prevFiles.length > 0 && 'content_type' in prevFiles[0]) {
            prevFiles = [];
        }
        if (event.target.files) {
            const hobbiesFile = Array.from(event.target.files);
            const oversizedFiles = hobbiesFile.filter(file => file.size > this.maxFileSize);
            if (oversizedFiles.length > 0) {
                this.props.handleError(configJSON.maxFileSizeError)
                return
            }
            let files = [...prevFiles, ...hobbiesFile]
            setFieldValue(`hobby_details_attributes.${index}.files`, files)
        }
    };
    

    // Customizable Area End
}
