import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@material-ui/core";
import React, { Component } from "react";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface Props {
  variant?: "outlined" | "filled" | "standard";
  className?: string;
  placeholder: string;
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown | string | undefined }>
  ) => void;
  value?: string | number;
  options: { name: string; value: number }[];
  error?: boolean;
  errorMsg?: string;
  disabled?: boolean,
  setValue?: boolean,
  emptyOption?:boolean
}

export default class CustomSelect extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      value,
      placeholder,
      onChange,
      variant = "outlined",
      className = "",
      options,
      error = false,
      errorMsg = "",
      disabled,
      setValue,
      emptyOption
    } = this.props;
    return (
      <Wrapper>
        <FormControl fullWidth>
          { !value && <InputLabel
            className="select-label"
            id="demo-simple-select-helper-label">
            {placeholder}
          </InputLabel>}
          <Select
            variant={variant}
            value={value || placeholder}
            IconComponent={KeyboardArrowDownIcon}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              style: {
                maxHeight: 400,
              },
            }}
            style={{margin: '0px'}}
            inputProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                     backgroundColor: '#FFF'
                  }
                }
              }
            }}
            className={`select ${className}`}
            onChange={onChange}
            error={error}
            placeholder={placeholder}
            disabled={disabled}
          >
            {emptyOption && 
             <MenuItem value={placeholder} disabled>
             {placeholder}
           </MenuItem>
            }
            {options.map((Item) => {
              return (
                <MenuItem key={Item.value} value={setValue ? Item.value : Item.name}>
                  {Item.name}
                </MenuItem>
              );
            })}
          </Select>
          {error ? (
            <FormHelperText className="errorMsg">
              <InfoOutlinedIcon className="icon"/> {errorMsg}
            </FormHelperText>
          ) : null}
        </FormControl>
      </Wrapper>
    );
  }
}
  const Wrapper = styled("div")(({ theme }) => ({
    "& .select-label": {
      color: "#94A3B8",
      fontSize: "22px",
      fontFamily: "Lato",
      fontWeight: 500,
      lineHeight: "22px",
      padding: "16.6px 16px",
      width: "130%",
    },
    "& .MuiFormControl-fullWidth":{
      width:'100%',
      background: "#FFF",
      borderRadius: "8px",
      
    },
    "& .select": {
      "& > div": {
        boxSizing: "border-box"
      }
    },
    "& .MuiOutlinedInput-input":{
      padding: '14.5px 14px'
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      fontFamily: "Outfit",
      color: "#64748B",
      fontWeight: 400,
      margin: "0px"
    },
    "& .adornmentColor": {
      color: "#64748B",
    },
    "& .errorMsg": {
      color: "#DC2626",
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
    },
    "& .icon": {
      width: "16px", 
      height: "16px",
      marginRight: "2px",
      marginBottom: "3px",
    },
    "& .MuiInputBase-input::placeholder": {
      fontFamily: "Lato",
      opacity: 1,
      color: "#94A3B8",
      fontWeight: 400,
    },
    "& [class*=MuiInputBase-root].Mui-disabled":{
      backgroundColor: "#F1F5F9",
      color: "#64748B",
    },
    "& [class*=selectField].Mui-focused": {
      borderColor: "#9176C4 !important",
    },
  }));
