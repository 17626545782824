import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import LegalDataEditController, {
    configJSON,
    Props,
} from "./LegalDataEditController.web";
import {
    Box,
    Button,
    Container,
    createTheme,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import { Field, FieldArray, Form, Formik, FormikErrors, FormikTouched, FormikValues } from "formik";
import CustomSelect from "../../../components/src/CustomSelect";
import { ILegalInformations, IPolicyDetails } from "../../../blocks/customform/src/LegalInformationController.web";
import CustomInput from "../../../components/src/CustomInput";
import PhoneInput from "react-phone-input-2";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import { DialogWrapper, styledMyLocationTwoToneIcon, StyledRadio } from "../../../blocks/customform/src/LegalInformation.web";
import { countries } from "../../../blocks/customisableuserprofiles/src/CustomisableUserProfilesController";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ErrorMessage from "../../../components/src/ErrorMessage";
import GenericModal from "../../../components/src/GenericModal";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import MediaUpload from "../../../components/src/MediaUpload.web";

  const paymentFrequency = [
    { value: 1, name: "Monthly" },
    { value: 2, name: "Quaterly" },
    { value: 3, name: "Half- yearly" },
    { value: 4, name: "Yearly" },
  ];

  export const theme = createTheme({
    overrides: {
      MuiTypography: {
        h1: {
          fontFamily: "Quattrocento",
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "28px",
        },
        h2: {
          fontFamily: "Lato",
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "28px",
          color: "#9176C4",
        },
        h3: {
          fontFamily: "Quattrocento",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "22px",
        },
        h6: {
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "22px",
          color: "#0F172A",
        },
        subtitle1: {
          fontFamily: "Lato",
          fontSize: "18px",
          fontWeight: 600,
          lineHeight: "26px",
          color: "#0F172A",
        },
        body2: {
          fontFamily: "Lato",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "24px",
          color: "#64748B",
        },
      },
      MuiFormLabel: {
          root: {
            color: "#334155", 
            fontFamily: "Lato", 
            fontSize: "14px", 
            lineHeight: "22px",
            fontWeight: 400,
          }
      },
  
      MuiButton: {
        outlined: {
          width: "248px",
          height: "48px",
          color: "#9176C4",
          fontFamily: "Lato",
          textTransform: "none",
          fontSize: '16px',
          fontWeight: 600,
          borderRadius: "100px",
        },
        contained: {
          width: "248px",
          height: "48px",
          backgroundColor: "#A190C2",
          color: "#FFFFFF",
          textTransform: "none",
          fontFamily: "Lato",
          fontSize: '16px',
          fontWeight: 600,
          borderRadius: "100px",
        },
      },
      MuiRadio: {
          colorSecondary: {
            '&$checked': {
              color: "#9176C4",
          },
          }
      },
      MuiCheckbox: {
        colorPrimary: {
          '&$checked': {
            color: "#A190C2",
          },
        }
      },
      MuiSwitch: {
        root: {
          borderRadius: "40px",
          padding: 0,
          width: "48px",
          height: "28px",
        },
        switchBase: {
          color: "#fff",
          padding: "2px",
          '&$checked': {
            transform: 'translateX(20px)',
            color: "#fff",
            '& + $track': {
              opacity: 1,
              borderColor: "#A190C2 !important",
              backgroundColor: "#A190C2 !important",
            },
      },
        },
        thumb: {
          width: "24px",
          boxShadow: 'none',
          height: "24px",
        },
        track: {
          color: "fff",
          backgroundColor: "gray",
          borderRadius: "40px",
          opacity: 1,
        },
        colorPrimary:{
          '&$checked':{
            padding: "2px",
            color: "#fff",
          }
        }
      },
      MuiInputBase: {
        root: {
          minHeight: '48px',
        },
      },
    },
  });
// Customizable Area End

export default class LegalDataEdit extends LegalDataEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLegalRepresentative = (
      values: FormikValues, 
      errors: FormikErrors<ILegalInformations>, 
      touched: FormikTouched<ILegalInformations>, 
      setFieldValue: {
        (field: string,
          value: any,
          shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
      }) => {
        const {
          isEdit
        } = this.state;

      return(
          <Box mb={3} className={`${isEdit ? "formSectionBackground" : ""}`}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <Typography data-test-id="legalRepresentativeTitle" variant={this.state.isEdit ? "h3" : "h2"}>
                        {configJSON.legalRepresentativeTitle}
                      </Typography>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">{configJSON.fullNameFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                      <Field
                          data-test-id="full_name"
                          className="inputField"
                          name="full_name"
                          as={CustomInput}
                          size="small"
                          disabled={!isEdit}
                          placeholder={configJSON.fullNamePlaceholder}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue("legal_representative.full_name", e.target.value)
                          }
                          value={values.legal_representative.full_name}
                          error={touched.legal_representative?.full_name && errors.legal_representative?.full_name}
                          errorMsg={errors.legal_representative?.full_name}
                      />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                  <FormLabel component="label">{configJSON.emailIDFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                      <Field
                          data-test-id="email_id"
                          name="email_id"
                          size="small"
                          className="inputField"
                          as={CustomInput}
                          disabled={!isEdit}
                          placeholder={configJSON.emailIDPlaceholder}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue("legal_representative.email", e.target.value)
                          }
                          value={values.legal_representative.email}
                          error={touched.legal_representative?.email && errors.legal_representative?.email}
                          errorMsg={errors.legal_representative?.email}
                      />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">
                        {configJSON.phoneNumberFieldLabel}<span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Box>
                          <PhoneInput
                          data-test-id="phone_number"
                          country={'gb'}
                          placeholder={configJSON.phoneNumberPlaceholder}
                          disabled={!isEdit}
                          value={values.legal_representative.full_phone_number}
                          onChange={(value) =>
                              setFieldValue("legal_representative.full_phone_number", value)
                          }
                          inputStyle={{
                            height: '48px',
                            width: '100%',
                            border: '1px solid #e0e0e0',
                            padding: '10px 45px',
                              borderRadius: '8px',
                          }}
                          buttonStyle={{
                            padding: '2px',
                            borderTopLeftRadius: '8px',
                            borderBottomLeftRadius: '8px',
                            border: '1px solid #e0e0e0',
                          }}
                          containerStyle={{
                            width: '100%',
                            height: '48px',
                            margin: '5px 0',
                          }}
                          inputProps={{
                            required: true,
                            name: 'phone',
                            autoFocus: true,
                          }}
                          />
                          {touched.legal_representative?.full_phone_number && errors.legal_representative?.full_phone_number &&
                          <FormHelperText error>
                              {errors.legal_representative?.full_phone_number}
                          </FormHelperText>
                          }
                      </Box>
                  </Grid>
              </Grid>
          </Box>
    )};

    renderKinDetails = (
      values: FormikValues, 
      errors: FormikErrors<ILegalInformations>, 
      touched: FormikTouched<ILegalInformations>, 
      setFieldValue: {
        (field: string,
          value: any,
          shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
      }) => {
        const { isEdit
        } = this.state;

      return(
          <Box className={`${isEdit ? "formSectionBackground" : ""}`}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <Typography data-test-id="kinDetailsTitle" variant={this.state.isEdit ? "h3" : "h2"}>
                        {configJSON.kinDetailsTitle}
                      </Typography>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">{configJSON.fullNameFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                      <Field
                          className="inputField"
                          name="kinFullName"
                          data-test-id="kinFullName"
                          as={CustomInput}
                          size="small"
                          disabled={!isEdit}
                          placeholder={configJSON.kinFullNamePlaceholder}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue("kin_detail.full_name", e.target.value)
                          }
                          value={values.kin_detail.full_name}
                          error={touched.kin_detail?.full_name && errors.kin_detail?.full_name}
                          errorMsg={errors.kin_detail?.full_name}
                      />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">{configJSON.relationFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                      data-test-id="kinRelation"
                      className="inputField"
                      name="kinRelation"
                      size="small"
                      disabled={!isEdit}
                      as={CustomInput}
                      placeholder={configJSON.kinRelationFieldPlaceholder}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("kin_detail.relation", e.target.value)
                      }
                      value={values.kin_detail.relation}
                      error={touched.kin_detail?.relation && errors.kin_detail?.relation}
                      errorMsg={errors.kin_detail?.relation}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                  <FormLabel component="label">{configJSON.emailIDFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                      <Field
                          data-test-id="kinEmailId"
                          name="kinEmailId"
                          size="small"
                          className="inputField"
                          as={CustomInput}
                          disabled={!isEdit}
                          placeholder={configJSON.emailIDPlaceholder}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue("kin_detail.email", e.target.value)
                          }
                          value={values.kin_detail.email}
                          error={touched.kin_detail?.email && errors.kin_detail?.email}
                          errorMsg={errors.kin_detail?.email}
                      />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">
                        {configJSON.phoneNumberFieldLabel}<span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Box>
                          <PhoneInput
                          data-test-id="kinPhoneNumber"
                          country={'gb'}
                          placeholder={configJSON.phoneNumberPlaceholder}
                          value={values.kin_detail.full_phone_number}
                          disabled={!isEdit}
                          onChange={(value) =>
                              setFieldValue("kin_detail.full_phone_number", value)
                          }
                          inputStyle={{
                            height: '48px',
                            border: '1px solid #e0e0e0',
                            width: '100%',
                            padding: '10px 45px',
                              borderRadius: '8px',
                          }}
                          buttonStyle={{
                            padding: '2px',
                            borderBottomLeftRadius: '8px',
                            borderTopLeftRadius: '8px',
                            border: '1px solid #e0e0e0',
                          }}
                          containerStyle={{
                            height: '48px',
                            width: '100%',
                            margin: '5px 0',
                          }}
                          inputProps={{
                            required: true,
                            autoFocus: true,
                            name: 'phone',
                          }}
                          />
                          {touched.kin_detail?.full_phone_number && errors.kin_detail?.full_phone_number &&
                          <FormHelperText error>
                              {errors.kin_detail?.full_phone_number}
                          </FormHelperText>
                          }
                      </Box>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.dateOfBirthLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="kinDOB"
                      name="kinDOB"
                      className="inputField"
                      size="small"
                      as={CustomDatePicker}
                      disabled={!isEdit}
                      placeholder={configJSON.kinDateOfBirthPlaceholder}
                      ageRestriction={18}
                      endIcon={
                        this.calenderIcon
                      }
                      onChange={(date: Date) =>
                        setFieldValue("kin_detail.date_of_birth", date.toLocaleDateString("en-GB"))
                      }
                      value={values.kin_detail.date_of_birth}
                      error={touched.kin_detail?.date_of_birth && errors.kin_detail?.date_of_birth}
                      errorMsg={errors.kin_detail?.date_of_birth}
                    />
                  </Grid>
                  <Grid item lg={9} md={12} sm={6}>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.addressLine1Label}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      data-test-id="kinAddressLine1"
                      name="kinAddressLine1"
                      size="small"
                      className="inputField"
                      startIcon={styledMyLocationTwoToneIcon}
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder={configJSON.kinAddressLine1Placeholder}
                      value={values.kin_detail.address_line_1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("kin_detail.address_line_1", e.target.value)
                      }
                      error={touched.kin_detail?.address_line_1 && errors.kin_detail?.address_line_1}
                      errorMsg={errors.kin_detail?.address_line_1}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.addressLine2Label}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      name="kinAddressLine2"
                      data-test-id="kinAddressLine2"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder={configJSON.kinAddressLine2Placeholder}
                      value={values.kin_detail.address_line_2}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("kin_detail.address_line_2", e.target.value)
                      }
                      error={touched.kin_detail?.address_line_2 && errors.kin_detail?.address_line_2}
                      errorMsg={errors.kin_detail?.address_line_2}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.landmarkLabel}
                    </FormLabel>
                    <Field
                      name="kinLandmark"
                      data-test-id="kinLandmark"
                      className="inputField"
                      size="small"
                      disabled={!isEdit}
                      as={CustomInput}
                      placeholder={configJSON.kinLandmarkPlaceholder}
                      value={values.kin_detail.landmark}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("kin_detail.landmark", e.target.value)
                      }
                      error={touched.kin_detail?.landmark && errors.kin_detail?.landmark}
                      errorMsg={errors.kin_detail?.landmark}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.townCityLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      name="kinTownCity"
                      data-test-id="kinTownCity"
                      className="inputField"
                      size="small"
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder={configJSON.kinTownCityPlaceholder}
                      value={values.kin_detail.city}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("kin_detail.city", e.target.value)
                      }
                      error={touched.kin_detail?.city && errors.kin_detail?.city}
                      errorMsg={errors.kin_detail?.city}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.countryLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      className="selectField"
                      data-test-id="kinCountry"
                      name="kinCountry"
                      as={CustomSelect}
                      placeholder={configJSON.kinCountryPlaceholder}
                      options={countries}
                      disabled={!isEdit}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue("kin_detail.country", e.target.value)
                      }}
                      value={values.kin_detail.country}
                      errorMsg={errors.kin_detail?.country}
                      error={touched.kin_detail?.country && errors.kin_detail?.country}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.postCodeLabel}<span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Field
                      name="kinPostCode"
                      data-test-id="kinPostCode"
                      className="inputField"
                      size="small"
                      disabled={!isEdit}
                      as={CustomInput}
                      placeholder={configJSON.kinPostCodePlaceholder}
                      value={values.kin_detail.post_code}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("kin_detail.post_code", e.target.value)
                      }
                      error={touched.kin_detail?.post_code && errors.kin_detail?.post_code}
                      errorMsg={errors.kin_detail?.post_code}
                    />
                  </Grid>
              </Grid>
          </Box>
    )};

    renderLocationOfWillDetails = (
      values: FormikValues, 
      errors: FormikErrors<ILegalInformations>, 
      touched: FormikTouched<ILegalInformations>, 
      setFieldValue: {
        (field: string, value: any, shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
      }) => {
        const { isEdit } = this.state;
      return(
          <Box className={`${isEdit ? "formSectionBackground" : ""}`}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <Typography data-test-id="locationOfWillFormTitle" variant={this.state.isEdit ? "h3" : "h2"}>
                        {configJSON.locationOfWillTitle}
                      </Typography>
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">{configJSON.nameOfPersonHoldingWillLabel}</FormLabel>
                      <Field
                          name="will_name"
                          data-test-id="willName"
                          className="inputField"
                          size="small"
                          disabled={!isEdit}
                          as={CustomInput}
                          placeholder={configJSON.nameOfPersonHoldingWillPlaceholder}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue("will.full_name", e.target.value)
                          }
                          value={values.will.full_name}
                      />
                  </Grid>
                  <Grid item lg={8} md={8} sm={6}>
                      <FormLabel component="label">{configJSON.additionalInformationLabel}</FormLabel>
                      <Field
                          name="additionalInfo"
                          data-test-id="additionalInfo"
                          size="small"
                          as={CustomInput}
                          disabled={!isEdit}
                          placeholder={configJSON.additionalInformationPlaceholder}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue("will.about", e.target.value)
                          }
                          value={values.will.about}
                      />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.addressLine1Label}
                    </FormLabel>
                    <Field
                      name="willAddressLine1"
                      data-test-id="willAddressLine1"
                      className="inputField"
                      size="small"
                      startIcon={styledMyLocationTwoToneIcon}
                      as={CustomInput}
                      disabled={!isEdit}
                      placeholder={configJSON.willAddressLine1Placeholder}
                      value={values.will.address_line_1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("will.address_line_1", e.target.value)
                      }
                      error={touched.will?.address_line_1 && errors.will?.address_line_1}
                      errorMsg={errors.will?.address_line_1}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.addressLine2Label}
                    </FormLabel>
                    <Field
                      name="willAddressLine2"
                      data-test-id="willAddressLine2"
                      className="inputField"
                      size="small"
                      disabled={!isEdit}
                      as={CustomInput}
                      placeholder={configJSON.willAddressLine2Placeholder}
                      value={values.will.address_line_2}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("will.address_line_2", e.target.value)
                      }
                      error={touched.will?.address_line_2 && errors.will?.address_line_2}
                      errorMsg={errors.will?.address_line_2}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.landmarkLabel}
                    </FormLabel>
                    <Field
                      name="willLandmark"
                      data-test-id="willLandmark"
                      className="inputField"
                      disabled={!isEdit}
                      size="small"
                      as={CustomInput}
                      placeholder={configJSON.willLandmarkPlaceholder}
                      value={values.will.landmark}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("will.landmark", e.target.value)
                      }
                      error={touched.will?.landmark && errors.will?.landmark}
                      errorMsg={errors.will?.landmark}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.townCityLabel}
                    </FormLabel>
                    <Field
                      name="willTownCity"
                      data-test-id="willTownCity"
                      className="inputField"
                      disabled={!isEdit}
                      size="small"
                      as={CustomInput}
                      placeholder={configJSON.willTownCityPlaceholder}
                      value={values.will.city}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("will.city", e.target.value)
                      }
                      error={touched.will?.city && errors.will?.city}
                      errorMsg={errors.will?.city}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.countryLabel}
                    </FormLabel>
                    <Field
                      className="selectField"
                      data-test-id="willCountry"
                      name="willCountry"
                      as={CustomSelect}
                      disabled={!isEdit}
                      placeholder={configJSON.willCountryPlaceholder}
                      options={countries}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue("will.country", e.target.value)
                      }}
                      value={values.will.country}
                      error={touched.will?.country && errors.will?.country}
                      errorMsg={errors.will?.country}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6}>
                    <FormLabel component="label">
                      {configJSON.postCodeLabel}
                    </FormLabel>
                    <Field
                      data-test-id="willPostCode"
                      className="inputField"
                      name="willPostCode"
                      size="small"
                      disabled={!isEdit}
                      as={CustomInput}
                      placeholder={configJSON.willPostCodePlaceholder}
                      value={values.will.post_code}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("will.post_code", e.target.value)
                      }
                      error={touched.will?.post_code && errors.will?.post_code}
                      errorMsg={errors.will?.post_code}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MediaUpload
                      data-test-id="mediaUpload"
                      files={this.state.files}
                      onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCreateFileUpload(event, setFieldValue)}
                      readonly={!isEdit}
                      title={!isEdit ? "Media" : ""}
                    />
                    { !isEdit && this.state.files.length === 0 && (
                      <Box>
                        <Typography variant="subtitle1" className="dataNotAvailable">
                          {configJSON.NoMediaFound}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
              </Grid>
          </Box>
    )};

    renderPolicyDetails = (
      values: FormikValues,
      setFieldValue: {
        (field: string, value: any,
         shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
      }) => {
        const { isEdit } = this.state;
      return(
        <FieldArray name="policy" data-test-id="fieldArray">
          {({ remove, push }) => (
            values.policy.map((policy: IPolicyDetails, index: number) => {
              return(
                <Box mb={8} className={`${isEdit ? "formSectionBackground" : ""}`}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="policyDetailsHeaderWrapper">
                      <Typography data-test-id="policyDetailsFormTitle" variant={this.state.isEdit ? "h3" : "h2"}>
                        {configJSON.policyDetailsFormTitle}
                      </Typography>
                      {(index === 0 && isEdit) && (
                      <Button 
                        className="addMoreBtn"
                        data-test-id = "addMorePolicyBtn"
                        onClick={() => push({
                          policy_number: "",
                          provider: "",
                          agent_name: "",
                          plan_name: "",
                          policy_term: "",
                          payment_frequency: "",
                          start_date: "",
                          maturity_date: "",
                          sum_assured: "",
                        })}
                      >
                        <AddCircleOutlineIcon className="circleOutlineIcon" />
                        {configJSON.addMorePolicyBtnText}
                      </Button>
                      )}
                      {(index !== 0 && isEdit) && (
                      <Button 
                        data-test-id="removePolicyBtn"
                        className="addMoreBtn"
                        onClick={() => remove(index)}>
                        <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                        {configJSON.removePolicyBtnText}
                      </Button>)}
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">{configJSON.policyNumberLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                      <Field
                        name="policyNumber"
                        data-test-id="policyNumber"
                        className="inputField"
                        disabled={!isEdit}
                        size="small"
                        as={CustomInput}
                        placeholder={configJSON.policyNumberPlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue(`policy.${index}.policy_number`, e.target.value)
                        }
                        value={policy.policy_number}
                      />
                      <ErrorMessage name={`policy.${index}.policy_number`} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">{configJSON.policyProviderLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                      <Field
                        name="policyProvider"
                        data-test-id="policyProvider"
                        className="inputField"
                        size="small"
                        disabled={!isEdit}
                        as={CustomInput}
                        placeholder={configJSON.policyProviderPlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue(`policy.${index}.provider`, e.target.value)
                        }
                        value={policy.provider}
                      />
                      <ErrorMessage name={`policy.${index}.provider`} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">{configJSON.agentNameLabel}</FormLabel>
                      <Field
                        data-test-id="agentName"
                        name="agentName"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        disabled={!isEdit}
                        placeholder={configJSON.agentNamePlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue(`policy.${index}.agent_name`, e.target.value)
                        }
                        value={policy.agent_name}
                      />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">{configJSON.planNameLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                      <Field
                        name="planName"
                        data-test-id="planName"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        disabled={!isEdit}
                        placeholder={configJSON.planNamePlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue(`policy.${index}.plan_name`, e.target.value)
                        }
                        value={policy.plan_name}
                      />
                      <ErrorMessage name={`policy.${index}.plan_name`} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">{configJSON.policyTermLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                      <Field
                        name="policyTerm"
                        data-test-id="policyTerm"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        disabled={!isEdit}
                        placeholder={configJSON.policyTermPlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue(`policy.${index}.policy_term`, e.target.value)
                        }
                        value={policy.policy_term}
                      />
                      <ErrorMessage name={`policy.${index}.policy_term`} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">
                        {configJSON.paymentFrequencyLabel}<span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Field
                        className="selectField"
                        data-test-id="paymentFrequency"
                        name="paymentFrequency"
                        as={CustomSelect}
                        disabled={!isEdit}
                        placeholder={configJSON.paymentFrequencyPlaceholder}
                        options={paymentFrequency}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(`policy.${index}.payment_frequency`, e.target.value)
                        }}
                        value={policy.payment_frequency}
                      />
                      <ErrorMessage name={`policy.${index}.payment_frequency`} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">{configJSON.startDateLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                      <Field
                        name="policyStartDate"
                        data-test-id="policyStartDate"
                        className="inputField"
                        size="small"
                        as={CustomDatePicker}
                        disabled={!isEdit}
                        placeholder={configJSON.startDatePlaceholder}
                        endIcon={
                          this.calenderIcon
                        }
                        onChange={(date: Date) => {
                          setFieldValue(`policy.${index}.start_date`, date.toLocaleDateString("en-GB"))
                        }
                        }
                        value={policy.start_date}
                      />
                      <ErrorMessage name={`policy.${index}.start_date`} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">{configJSON.maturityDateLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                      <Field
                        name="policyMaturityDate"
                        data-test-id="policyMaturityDate"
                        className="inputField"
                        size="small"
                        as={CustomDatePicker}
                        disabled={!isEdit}
                        placeholder={configJSON.maturityDatePlaceholder}
                        minDate= {Date.now()}
                        endIcon={
                          this.calenderIcon
                        }
                        onChange={(date: Date) => {
                          setFieldValue(`policy.${index}.maturity_date`, date.toLocaleDateString("en-GB"))
                        }
                        }
                        value={policy.maturity_date}
                      />
                      <ErrorMessage name={`policy.${index}.maturity_date`} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6}>
                      <FormLabel component="label">
                        {configJSON.sumAssuredLabel}<span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Field
                        name="sumAssured"
                        data-test-id="sumAssured"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        disabled={!isEdit}
                        type="number"
                        placeholder={configJSON.sumAssuredPlaceholder}
                        value={policy.sum_assured}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue(`policy.${index}.sum_assured`, e.target.value)
                        }
                      />
                      <ErrorMessage name={`policy.${index}.sum_assured`} />
                    </Grid>
                  </Grid>
                </Box>
              );
            })
          )}
        </FieldArray>
    )};
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { 
          legalData,
          isEdit,
          delegateInCharge,
          openDialog,
          steps
        } = this.state;
        return (
          <>
            <ThemeProvider theme={theme}>
                <Wrapper>
                  <WebHeader navigation={this.props.navigation} />
                    <Box className="container">
                      <Container>
                        <Box>
                          <BreadcrumbStepper steps={steps} onStepClick={(path) => this.handleNavigation(path)} />
                        </Box>
                        <Box className="legalDataWrapper">
                          <Box className="pageTitle">
                            <Typography data-test-id="legalDataTitle" variant="h1" className="pageTitleText">
                              {configJSON.legalDataTitle}
                            </Typography>
                          </Box>
                          <Box className="formWrapper">
                            <Formik
                              initialValues={legalData}
                              validationSchema={this.validationSchema(isEdit)}
                              onSubmit={(values) => {
                                this.handleLegalDataFormSubmit(values)
                              }}
                              enableReinitialize={true}
                              data-test-id="legalDataForm"
                            >
                              {({ values, errors, touched, setFieldValue }) => (
                                <Form>
                                  <Box className="formWrapper">
                                    <Box>
                                      <Grid container spacing={2}>
                                        <Grid item xs={3} md={3} sm={6}>
                                          <Box className="formSubtitle">
                                            <Typography variant="h2">
                                              {configJSON.delegateInChargeText}
                                            </Typography>
                                          </Box>
                                          <FormLabel component="label">
                                            {configJSON.delegateInChargeText}<span style={{ color: "red" }}>*</span>
                                          </FormLabel>
                                          <Field
                                            data-test-id="delegateInCharge"
                                            name="delegateInCharge"
                                            className="selectField"
                                            as={CustomSelect}
                                            placeholder="Select Delegate"
                                            options={delegateInCharge}
                                            setValue={true}
                                            disabled={!isEdit}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                              setFieldValue("legal_information.delegate_id", e.target.value)
                                            }
                                            value={values.legal_information.delegate_id}
                                            error={
                                              touched.legal_information?.delegate_id &&
                                              errors.legal_information?.delegate_id
                                            }
                                            errorMsg={errors.legal_information?.delegate_id}
                                          />
                                        </Grid>
                                        <Grid item xs={12}>
                                          { isEdit && (
                                            <Box>
                                              <FormLabel component="label" className="formRadioLabel">
                                                {configJSON.haveLegalRepresentativeLabel}
                                              </FormLabel>
                                              <RadioGroup
                                                data-test-id="haveLegalRepresentative"
                                                name="haveLegalRepresentative"
                                                aria-label="haveLegalRepresentative"
                                                value={values.legal_information.have_legal_representative}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                  setFieldValue("legal_information.have_legal_representative", e.target.value);
                                                }
                                                }
                                                className="radioGroup"
                                              >
                                                <FormControlLabel
                                                  control={<StyledRadio />}
                                                  label="Yes"
                                                  value="1"
                                                  className="radioLabel"
                                                />
                                                <FormControlLabel
                                                  control={<StyledRadio />}
                                                  label="No"
                                                  value="0"
                                                  className="radioLabel"
                                                />
                                              </RadioGroup>
                                            </Box>
                                          )}
                                          { !isEdit && values.legal_information.have_legal_representative === "0" && (
                                            <Box>
                                              <Box>
                                                <Typography variant="h2">
                                                  {configJSON.legalRepresentativeTitle}
                                                </Typography>
                                                <Typography variant="subtitle1" className="dataNotAvailable">
                                                  {configJSON.dataNotAvailable}
                                                </Typography>
                                              </Box>
                                              <Box>
                                                <Typography variant="h2">
                                                  {configJSON.kinDetailsTitle}
                                                </Typography>
                                                <Typography variant="subtitle1" className="dataNotAvailable">
                                                  {configJSON.dataNotAvailable}
                                                </Typography>
                                              </Box>
                                            </Box>
                                          )}
                                          { values.legal_information.have_legal_representative === "1" && (
                                            this.renderLegalRepresentative(values, errors, touched, setFieldValue)
                                          )}
                                          { values.legal_information.have_legal_representative === "1" && (
                                            this.renderKinDetails(values, errors, touched, setFieldValue)
                                          )}
                                        </Grid>
                                        <Grid item xs={12}>
                                          { isEdit && (
                                            <Box>
                                              <FormLabel component="label" className="formRadioLabel">
                                                {configJSON.haveWillLabel}
                                              </FormLabel>
                                              <RadioGroup
                                                data-test-id="haveWill"
                                                name="haveWill"
                                                aria-label="haveWill"
                                                value={values.legal_information.have_will}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                  setFieldValue("legal_information.have_will", e.target.value);
                                                }
                                                }
                                                className="radioGroup"
                                              >
                                                <FormControlLabel
                                                  value="1"
                                                  control={<StyledRadio />}
                                                  label="Yes"
                                                  className="radioLabel"
                                                />
                                                <FormControlLabel
                                                  value="0"
                                                  control={<StyledRadio />}
                                                  label="No"
                                                  className="radioLabel"
                                                />
                                              </RadioGroup>
                                            </Box>
                                          )}
                                          { !isEdit && values.legal_information.have_will === "0" && (
                                            <Box>
                                                <Typography variant="h2">
                                                  {configJSON.locationOfWillTitle}
                                                </Typography>
                                                <Typography variant="subtitle1" className="dataNotAvailable">
                                                  {configJSON.dataNotAvailable}
                                                </Typography>
                                            </Box>
                                          )}
                                          { values.legal_information.have_will === "1" && (
                                            this.renderLocationOfWillDetails(values, errors, touched, setFieldValue) 
                                          )}
                                        </Grid>
                                        <Grid item xs={12}>
                                          { isEdit && (
                                            <Box>
                                              <FormLabel component="label" className="formRadioLabel">
                                                {configJSON.haveLifeInsuranceLabel}
                                              </FormLabel>
                                              <RadioGroup
                                                data-test-id="haveLifeInsurance"
                                                name="haveLifeInsurance"
                                                aria-label="haveLifeInsurance"
                                                value={values.legal_information.have_life_insurance}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                  setFieldValue("legal_information.have_life_insurance", e.target.value);
                                                }
                                                }
                                                className="radioGroup"
                                              >
                                                <FormControlLabel
                                                  control={<StyledRadio />}
                                                  value="1"
                                                  label="Yes"
                                                  className="radioLabel"
                                                />
                                                <FormControlLabel
                                                  control={<StyledRadio />}
                                                  value="0"
                                                  label="No"
                                                  className="radioLabel"
                                                />
                                              </RadioGroup>
                                            </Box>
                                          )}
                                          { !isEdit && values.legal_information.have_life_insurance === "0" && (
                                            <Box mb={6}>
                                                <Typography variant="h2">
                                                  {configJSON.policyDetailsFormTitle}
                                                </Typography>
                                                <Typography variant="subtitle1" className="dataNotAvailable">
                                                  {configJSON.dataNotAvailable}
                                                </Typography>
                                            </Box>
                                          )}
                                          { values.legal_information.have_life_insurance === "1" && (
                                            this.renderPolicyDetails(values, setFieldValue) 
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box className="btnWrapper">
                                      <Button
                                        data-test-id="backBtn"
                                        variant="outlined"
                                        className="btnSize"
                                        onClick={() => this.handleNavigation("HomePage")}
                                      >
                                        {configJSON.backBtnText}
                                      </Button>
                                      <Button
                                        data-test-id="editDetailsBtn"
                                        variant="contained"
                                        className="btnSize"
                                        type="submit"
                                      >
                                        {isEdit ? configJSON.saveChangesBtnText : configJSON.editDetailsBtnText}
                                      </Button>
                                    </Box>
                                  </Box>
                                </Form>
                              )}
                            </Formik>
                          </Box>
                        </Box>
                      </Container>
                    </Box>
                    <Box mt={2}>
                        <WebFooter data-test-id="footer" handleNavigation={this.handleNavigation} />
                    </Box>
                </Wrapper>
            </ThemeProvider>
            <GenericModal open={openDialog} data-test-id="information-added-modal" onClose={this.handleCloseDialog}>
            <ThemeProvider theme={theme}>
                <DialogWrapper>
                  <Box className="dialogContentWrapper">
                    <Box className="dialogDescriptionWrapper">
                        <img alt="success" src={rightTickImg} style={{ width: "56px", height: "56px", margin: "0px auto" }} />
                        <Typography align="center" variant="body2">
                          {configJSON.dialogMessageText}
                        </Typography>
                    </Box>
                  </Box>
                </DialogWrapper>
            </ThemeProvider>
      </GenericModal>
          </>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
    alignItems: "center",
    padding: "56px 72px",
  },
  "& .pageTitle": {
    display: "flex",
    justifyContent: "space-between",
    margin: "32px 0px",
  },
  "& .formSubtitle": {
    marginBottom: "12px",
  },
  "& .formWrapper": {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  "& .selectField": {
    display: "block",
    borderRadius: "8px",
    width: "100%",
    height: "48px",
    margin: "5px 0px",
    minWidth: "260px",
  },
  "& .inputField": {
    height: "48px",
    width: "100%",
    borderRadius: "8px",
    minWidth: "260px",
  },
  "& .selectFieldInput":{
    margin: "5px 0px"
  },
  "& .radioGroup": {
    display: 'flex',
    marginLeft: "16px",
    flexDirection: 'row',
    gap: "35px",
  },
  "& .radioLabel": {
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "22px",
    color: '#1E293B',
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#9176C4',
    },
  },
  "& .formSectionBackground": {
    padding: "24px",
    borderRadius: "8px",
    backgroundColor: "rgba(206, 195, 229, 0.1)",
  },
  "& .haveSpecialDayWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .haveSpecialDayLabel": {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "18px",
    color: "#1E293B", 
    lineHeight: "22px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  "& .calenderIconStyle": {
    cursor: "grab",
  },
  "& .btnWrapper": {
    justifyContent: "flex-end",
    display: "flex",
    gap: "8px",
  },
  "& .btnSize": {
    height: "48px",
    width: "254px",
  },
  "& .uploadBox": {
    border: "2px dashed #d1c4e9",
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    backgroundColor: "#fafafa",
    marginBottom: "16px",
    gap: "16px",
},

"& .fileList": {
    listStyle: "none",
    padding: 0,
    marginTop: 20
},

"& .fileItem": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: "8px",
    flex: 1,
},

"& .fileIcon": {
    width: "40px",
    height: "40px",
    marginRight: "16px",
},

"& .title": {
    fontFamily: "Lato",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 700,
    color: "#0F172A",
},

"& .uploadInfo": {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 500,
    color: "#94A3B8",
    marginBottom: "8px",
},

"& .chooseFileBtn": {
    width: "158px",
    height: "44px",
    color: "#FFFFFF",
    backgroundColor: "#A190C2",
    textTransform: "none",
    fontFamily: "Lato",
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: "4px",
},

"& .uploadMediaIcon": {
    width: "44px",
    height: "44px",
},

"& .fileItemWrapper": {
    display: "flex",
    gap: "24px",
},

"& .childrenWrapper": {
    display: "flex",
    gap: "16px",
},
  "& .addMoreBtn": {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    color: "#9176C4",
    lineHeight: "22px",
    display: "flex",
    textTransform: "none",
    alignItems: "center",
    '&:hover': {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },
  "& .policyDetailsHeaderWrapper": {
    justifyContent: "space-between",
    display: "flex",
  },
  "& .circleOutlineIcon": {
    fontSize: "16px",
    marginRight: "8px",
  },
  "& .dataNotAvailable": {
    margin: "24px 0px",
  },
  //PhoneInput CSS override
  "& .react-tel-input .flag-dropdown.open .selected-flag": {
    backgroundColor: "unset",
  },
  "& .react-tel-input .selected-flag:hover": {
    backgroundColor: "unset",
  },
  [theme.breakpoints.down(740)]: {
    "& .container": {
        padding: "28px 16px",
    },
  },

  // styles for medical data
  "& .checkboxWrapper": {
    display: 'flex',
    alignItems: 'center',
  },
}));
// Customizable Area End