import React, { Component, ChangeEvent, ReactNode, DragEvent } from 'react';
import { 
  Button, 
  Typography, 
  IconButton, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Avatar, 
  ListItemSecondaryAction, 
  createTheme,
  styled,
  Box,
} from '@material-ui/core';
import { InsertDriveFile as InsertDriveFileIcon } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { uploadMediaIcon } from '../../blocks/customform/src/assets';
import { IMedia } from '../../blocks/customform/src/LegalInformationController.web';
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import { toast } from 'react-toastify';

const theme = createTheme({
  overrides: {
    MuiTypography: {
      body2: {
        fontFamily: "Lato",
        fontSize: "16px !important",
        lineHeight: "22px",
        fontWeight: 400,
      },
    },
    MuiListItemText: {
      root: {
        fontFamily: "Lato",
        fontSize: "16px !important",
        lineHeight: "22px",
        fontWeight: 400,
      },
      primary: {
        color: "#0F172A !important",
      },
      secondary: {
        color: "#64748B",
      },
    },
  },
});

const Wrapper = styled("div")({
  marginTop: "16px",

  "& .uploadBox": {
    border: "2px dashed #d1c4e9",
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    backgroundColor: "#fafafa",
    marginBottom: "16px",
    gap: "16px",
  },

  "& .fileList": {
    listStyle: "none",
    padding: 0,
  },

  "& .fileItem": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: "8px",
    flex: 1,
  },

  "& .fileIcon": {
    width: "40px",
    height: "40px",
    marginRight: "16px",
  },

  "& .title": {
    fontFamily: "Lato",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 700,
    color: "#0F172A",
  },

  "& .uploadInfo": {
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 500,
    color: "#94A3B8",
    marginBottom: "8px",
  },

  "& .chooseFileBtn": {
    width: "158px",
    height: "44px",
    color: "#FFFFFF",
    backgroundColor: "#A190C2",
    textTransform: "none",
    fontFamily: "Lato",
    fontSize: '16px',
    fontWeight: 600,
    borderRadius: "4px",
  },

  "& .uploadMediaIcon": {
    width: "44px",
    height: "44px",
  },

  "& .fileItemWrapper": {
    display: "flex",
    gap: "24px",
  },

  "& .childrenWrapper": {
    display: "flex",
    gap: "16px",
  },

  "& [class*=MuiTypography-body2]": {
    body2: {
      fontFamily: "Lato",
      fontSize: "16px !important",
      lineHeight: "22px",
      fontWeight: 400,
    },
  },

  "& .uploadBox.dragging": {
    borderColor: "#A190C2",
    backgroundColor: "#f5f5f5",
  },
});
interface MediaUploadProps {
  files: (File | IMedia)[];
  onUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  onRemove?: (index: number) => void;
  maxFiles?: number;
  children?: ReactNode;
  title?: string;
  readonly?: boolean; 
}

interface MediaUploadState {
  isDragging: boolean;
}

interface MediaUploadState {
  isDragging: boolean;
}

class MediaUpload extends Component<MediaUploadProps> {

  state: MediaUploadState = {
    isDragging: false,
  };

  isIMedia(file: File | IMedia): file is IMedia {
    return (file as IMedia).content_type !== undefined;
  };

  calenderIcon = () => {
    return (
      <div className="calenderIcon">
        <svg fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.5 9.08984H20.5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.9955 13.6992H12.0045" stroke="#A190C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 13.6992H8.30329" stroke="#A190C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 16.6992H8.30329" stroke="#A190C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </div>
    );
  };

  handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { files, onUpload, maxFiles } = this.props;
    const uploadedFiles = event.target.files;

    // Check if the total number of files exceeds the maxFiles limit
    if (uploadedFiles && maxFiles && files.length + uploadedFiles.length > maxFiles && !(files[0] && 'content_type' in files[0])) {
      toast.error(`You can upload a maximum of ${maxFiles} files.`);
      return;
    }
    if(uploadedFiles){
      let uploadFileArray = Array.from(uploadedFiles)
      this.checkUploadFileSize([...files, ...uploadFileArray]) && onUpload(event); // Call the existing onUpload handler if the limit is not exceeded
    }
  };

  checkUploadFileSize = (files: (File | IMedia)[]) => {
    let totalSize = 0;
    const mbBits = 1024 * 1024; 
    const maxSize = 15 * mbBits;
    let isValid = true;
  
    for (let file of files) {
      const fileSize = this.isIMedia(file) ? file.file_size : file.size;
      totalSize += fileSize;
  
      if (fileSize > maxSize) {
        isValid = false;
        toast.error("As per your plan, you can only upload files up to 15 MB.");
        break;
      }
    }
  
    if (isValid && totalSize > maxSize) {
      isValid = false;
      toast.error("As per your plan, the total size of files cannot exceed 15 MB.");
    }
  
    return isValid;
  };
  

  handleDragOver = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    this.setState({ isDragging: true });
  };

  handleDragLeave = () => {
    this.setState({ isDragging: false });
  };

  handleDrop = (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    this.setState({ isDragging: false });

    const { files, onUpload, maxFiles } = this.props;
    const droppedFiles = event.dataTransfer.files;

    // Check if the total number of files exceeds the maxFiles limit
    if (droppedFiles && maxFiles && files.length + droppedFiles.length > maxFiles) {
      alert(`You can upload a maximum of ${maxFiles} files.`);
      return;
    }

    // Create a synthetic event to pass to the onUpload handler
    const syntheticEvent = {
      target: { files: droppedFiles },
    } as unknown as ChangeEvent<HTMLInputElement>;
      let uploadFileArray = Array.from(droppedFiles)
      this.checkUploadFileSize([...files, ...uploadFileArray]) && onUpload(syntheticEvent);
  };

  renderFileIcon = (file: File | IMedia) => {
    if ('type' in file && file.type.includes('image')) {
      return <Avatar src={URL.createObjectURL(file)} className="fileIcon" />;
    } else if (this.isIMedia(file) && file.content_type.includes('image')) {
      return <Avatar src={file.url} className="fileIcon" />;
    } else if (
      ('type' in file && file.type.includes('pdf')) ||
      (this.isIMedia(file) && file.content_type.includes('pdf'))
    ) {
      return (
        <Avatar className="fileIcon">
          <InsertDriveFileIcon />
        </Avatar>
      );
    } else {
      return (
        <Avatar className="fileIcon">
          <InsertDriveFileIcon />
        </Avatar>
      );
    }
  };

  render() {
    const { files, onUpload, onRemove, children, title, readonly } = this.props;
    const { isDragging } = this.state;

    const styledMyLocationTwoToneIcon = styled(MyLocationTwoToneIcon)({
      fill: "#A190C2",
  });

    return (
      <Wrapper>
        <Typography style={{color:title ? "#9176C4":"#0F172A"}}  className="title">{title ? title : "Upload Media"}</Typography>
        { !readonly && (
          <>
            <Typography className="uploadInfo">
            PDF, Doc, PNG, JPEG and MP4 with max size of 15MB are allowed
            </Typography>
            <label
              className={`uploadBox ${isDragging ? 'dragging' : ''}`}
              onDragOver={this.handleDragOver}
              onDragLeave={this.handleDragLeave}
              onDrop={this.handleDrop}
            >
              <input
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={this.handleUpload}
              />
              <Button variant="contained" component="span" className="chooseFileBtn">
                Choose File
              </Button>
              <img alt="icon" className="uploadMediaIcon" src={uploadMediaIcon} />
              <Typography variant="body2">Or drag & drop here</Typography>
            </label>
          </>
        )}
        <div>
          <List className="fileList">
            {files.map((file, index) => (
              <>
                <Box className="fileItemWrapper">
                  <ListItem key={index} className="fileItem">
                    <ListItemAvatar>{this.renderFileIcon(file)}</ListItemAvatar>
                    <ListItemText
                      primary={this.isIMedia(file) ? file.file_name : file.name}
                      secondary={
                        this.isIMedia(file)
                          ? `${(file.file_size / 1024).toFixed(2)} KB`
                          : `${(file.size / 1024).toFixed(2)} KB`
                      }
                    />
                    {onRemove && (
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => onRemove(index)}>
                          <MoreVertIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </Box>
              </>
            ))}
          </List>
          <Box className="childrenWrapper">
            {(files.length > 0 && children) ? (
              children
            ) : (
              <></> // Fallback content when children are not provided
            )}
          </Box>
        </div>
      </Wrapper>
    );
  }
}

export default MediaUpload;
