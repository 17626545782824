import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { 
    ICremationDetailAttributes, 
    IEulogyApartDeliverAttributes, 
    IFuneralDetailAttributes, 
    IFuneralSongAttributes, 
    IWakeLocationAttributes,
} from "../../../blocks/customform/src/FuneralBasicsController.web";
import { ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import { IMedia } from "./InvestmentsAndFundsEditController.web";
import { toast } from "react-toastify";

export interface IGetFuneralBasicsInfoAPIResponse {
    id: string;
    type: "funeral_infos";
    attributes: {
        id: number;
        is_include_final_pdf: boolean;
        body_handled_after_passing: string;
        cremation_detail: {
            id: number;
            have_diamon_ect: boolean;
            decision: string;
        };
        decision: string;
        have_funeral_place: string;
        funeral_detail: {
            id: number;
            have_location_for_funeral: boolean;
            address_line_1: string;
            address_line_2: string;
            landmark: string;
            city: string;
            post_code: string;
            country: string;
        };
        have_location_for_wake: string;
        wake_location: {
            id: number;
            place_name: string;
            address_line_1: string;
            address_line_2: string;
            landmark: string;
            city: string;
            post_code: string;
            country: string;
        };
        have_stone_spot: string;
        proof_of_purchase: IMedia[];
        have_any_song: string;
        funeral_song: {
            id: number;
            song_1: string;
            song_2: string;
            song_3: string;
        };
        carry_people_name: {
            name: string;
        }[];
        have_add_two_close_friend: string;
        eulogy_apart_deliver: {
            id: number;
            delegate1: string;
            delegate2: string;
            name_p1: string;
            relationship_p1: string;
            name_p2: string;
            relationship_p2: string;
        };
        have_specific_request: string;
        special_request: string;
        account_id: number;
        delegate_id: number;
        delegate_charge: {
            name: string;
        };
    };
    meta: {
        message: string;
    };
};

export interface IFuneralBasics {
    is_include_final_pdf: boolean;
    delegate_id: string;
    body_handled_after_passing: "Buried" | "Cremated";
    decision: string;
    have_funeral_place: string;
    have_location_for_wake: string;
    have_stone_spot: string;
    have_any_song: string;
    carry_people_name: string[];
    have_add_two_close_friend: string;
    have_specific_request: string;
    special_request: string;
    funeral_detail_attributes: IFuneralDetailAttributes;
    cremation_detail_attributes: ICremationDetailAttributes;
    wake_location_attributes: IWakeLocationAttributes;
    funeral_song_attributes: IFuneralSongAttributes;
    eulogy_apart_deliver_attributes: IEulogyApartDeliverAttributes;
    files: (File | IMedia)[],
  };
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void;
  handleSuccess: () => void;
  handleNextBtnClick: (path: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
funeralBasicInfo: IFuneralBasics,
delegateInCharge: ISelectOptions[],
files: (File | IMedia)[],
isLoading: boolean,
isEdit: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FuneralBasicsEditController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      funeralBasicInfo: {
        is_include_final_pdf: false,
        delegate_id: "",
        body_handled_after_passing: "Buried",
        decision: "",
        have_funeral_place: "0",
        have_location_for_wake: "0",
        have_stone_spot:"0",
        have_any_song: "0",
        carry_people_name: ["", "", ""],
        have_add_two_close_friend: "0",
        have_specific_request: "0",
        special_request: "",
        files: [],
        funeral_detail_attributes: {
          have_location_for_funeral: "0",
          address_line_1: "",
          address_line_2: "",
          landmark: "",
          city: "",
          country: "",
          post_code: "",
        },
        cremation_detail_attributes: {
          have_diamon_ect: "0",
          decision: "",
        },
        wake_location_attributes: {
          place_name: "",
          address_line_1: "",
          address_line_2: "",
          landmark: "",
          city: "",
          country: "",
          post_code: "",
        },
        funeral_song_attributes: {
          song_1: "",
          song_2: "",
          song_3: "",
        },
        eulogy_apart_deliver_attributes: {
          delegate1: "",
          delegate2: "",
          name_p1: "",
          relationship_p1: "",
          name_p2: "",
          relationship_p2: "",
        },
      },
      delegateInCharge: [],
      files: [],
      isLoading: false,
      isEdit: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegatesApiId: string = "";
  callGetFuneralBasicsDataApiId: string = "";
  callEditFuneralBasicsDataApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
    this.getFuneralBasicsData();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callGetDelegatesApiId]: this.handleGetDelegatesApiResponse,
        [this.callGetFuneralBasicsDataApiId]: this.handleGetFuneralBasicsDataApiResponse,
        [this.callEditFuneralBasicsDataApiId]: this.handleEditFuneralBasicsDataApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { 
      errors: possibleErrors 
    } = responseJSON;

    if (possibleErrors) {
      return true;
    }
    return false;
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegatesApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };

  getFuneralBasicsData = async () => {
    const token = await getStorageData("token");

    this.callGetFuneralBasicsDataApiId = sendAPIRequest(
        configJSON.getFuneralBasicsDataApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegatesApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
     this.props.handleError("something went wrong!"); 
     return; 
    }
 
     const response = responseJSON as {
       meta?: { message: string };
       data?: {
         id: string,
         type: string,
         attributes: IDelegateMemberAPIResponse,
       }[];
     };
 
     if (response.data) {
       let delegateInCharge: ISelectOptions[] = [];

       response.data.forEach((member) => {
         delegateInCharge.push({
           value: member.attributes.id.toString(),
           name: member.attributes.first_name + " " + member.attributes.last_name,
         });
       });
 
       this.setState((prevState) => ({
        delegateInCharge,
        funeralBasicInfo: {
          ...prevState.funeralBasicInfo,
          eulogy_apart_deliver_attributes: {
            ...prevState.funeralBasicInfo.eulogy_apart_deliver_attributes, 
            delegate1: delegateInCharge[0]?.name || "", 
            delegate2: delegateInCharge[1]?.name || "",
          },
        },
      }));
      
     }
   };

  getYesNoFlag = (value: string) => {
    return value === "Yes" ? "1" : "0";
  };

  handleGetFuneralBasicsDataApiResponse = (responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
        meta?: { message: string };
        data?: IGetFuneralBasicsInfoAPIResponse;
      };

      if (response.data) {
        const attributes = response.data.attributes;

        if(attributes.proof_of_purchase){
            this.setState({ files: attributes.proof_of_purchase.map((mediaFile) => {
              return({
                file_id: mediaFile.file_id,
                file_name: mediaFile.file_name,
                content_type: mediaFile.content_type,
                file_size: mediaFile.file_size,
                url: mediaFile.url,
              })
            }),
          })
          }

        let funeralBasicInfo = {
            is_include_final_pdf: attributes.is_include_final_pdf,
            delegate_id: attributes.delegate_id.toString(),
            body_handled_after_passing: attributes.body_handled_after_passing,
            decision: attributes.decision,
            have_funeral_place: this.getYesNoFlag(attributes.have_funeral_place),
            have_location_for_wake: this.getYesNoFlag(attributes.have_location_for_wake),
            have_stone_spot: this.getYesNoFlag(attributes.have_stone_spot),
            have_any_song: this.getYesNoFlag(attributes.have_any_song),
            carry_people_name: attributes.carry_people_name.map((person) =>  { return person.name }),
            have_add_two_close_friend: this.getYesNoFlag(attributes.have_add_two_close_friend),
            have_specific_request: this.getYesNoFlag(attributes.have_specific_request),
            special_request: attributes.special_request,
            funeral_detail_attributes: {
                have_location_for_funeral: attributes.funeral_detail.have_location_for_funeral ? "1" : "0",
                address_line_1: attributes.funeral_detail.address_line_1,
                address_line_2: attributes.funeral_detail.address_line_2,
                landmark: attributes.funeral_detail.landmark,
                city: attributes.funeral_detail.city,
                country: attributes.funeral_detail.country,
                post_code: attributes.funeral_detail.post_code,
            },
            cremation_detail_attributes: {
                have_diamon_ect: attributes.cremation_detail.have_diamon_ect ? "1" : "0",
                decision: attributes.cremation_detail.decision,
            },
            wake_location_attributes: {
                place_name: attributes.wake_location.place_name,
                address_line_1: attributes.wake_location.address_line_1,
                address_line_2: attributes.wake_location.address_line_2,
                landmark: attributes.wake_location.landmark,
                city: attributes.wake_location.city,
                country: attributes.wake_location.country,
                post_code: attributes.wake_location.post_code,
            },
            funeral_song_attributes: {
                song_1: attributes.funeral_song.song_1,
                song_2: attributes.funeral_song.song_2,
                song_3: attributes.funeral_song.song_3,
            },
            eulogy_apart_deliver_attributes: {
                delegate1: attributes.eulogy_apart_deliver.delegate1,
                delegate2: attributes.eulogy_apart_deliver.delegate2,
                name_p1: attributes.eulogy_apart_deliver.name_p1,
                relationship_p1: attributes.eulogy_apart_deliver.relationship_p1,
                name_p2: attributes.eulogy_apart_deliver.name_p2,
                relationship_p2: attributes.eulogy_apart_deliver.relationship_p2,
            },
            files: attributes.proof_of_purchase?.map((mediaFile) => {
                return({
                  file_id: mediaFile.file_id,
                  file_name: mediaFile.file_name,
                  content_type: mediaFile.content_type,
                  file_size: mediaFile.file_size,
                  url: mediaFile.url,
                })
              }) || [], 
        } as IFuneralBasics;
        this.setState({ funeralBasicInfo });
      }
  };

  handleEditFuneralBasicsDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      this.props.handleError("something went wrong!");
      return; 
    }

    this.setState({isLoading: false, isEdit: false });
    if (responseJSON.data) {
      this.props.handleSuccess();
    }
  };

  toggleEditMode = () => {
    this.setState({
      isEdit: true,
    });
  };

  handleFuneralBasicsInformationsFormSubmit = async (values: IFuneralBasics) => {
    if(!this.state.isEdit){
      this.toggleEditMode();
      return;
    }

      const token = await getStorageData("token");
      const formData = new FormData();

      this.setState({ isLoading: true });

      // Append main form values
      Object.entries(values).forEach(([keyName, value]) => {
        if (keyName === 'files') {
          this.appendFiles(values.files, this.state.files, formData);
        } else {
          this.appendOtherValues(keyName, value, formData);
        }
      });

      // Append conditional attributes
      this.appendConditionalAttributes(values, formData);


      this.callEditFuneralBasicsDataApiId = sendAPIRequest(
        configJSON.getFuneralBasicsDataApiEndPoint,
        {
          method: configJSON.postApiRequest,
          headers: {
            token,
          },
          body: formData,
        }
      );
  };

  // Helper to append conditional attributes based on values
  appendConditionalAttributes = (values: IFuneralBasics, formData: FormData) => {
    const attributes = [
      { condition: values.have_funeral_place === "1", data: values.funeral_detail_attributes, name: 'funeral_detail_attributes' },
      { condition: values.body_handled_after_passing === "Cremated", data: values.cremation_detail_attributes, name: 'cremation_detail_attributes' },
      { condition: values.have_location_for_wake === "1", data: values.wake_location_attributes, name: 'wake_location_attributes' },
      { condition: values.have_any_song === "1", data: values.funeral_song_attributes, name: 'funeral_song_attributes' },
      { condition: values.have_add_two_close_friend === "1", data: values.eulogy_apart_deliver_attributes, name: 'eulogy_apart_deliver_attributes' },
    ];

    attributes.forEach(({ condition, data, name }) => {
      if (condition) {
        this.appendAttributes(data, name, formData);
      }
    });
  };

  appendAttributes = (
    attributes: IFuneralDetailAttributes | IWakeLocationAttributes | ICremationDetailAttributes | IFuneralSongAttributes | IEulogyApartDeliverAttributes, 
    attributeName: string, 
    formData: FormData
  ) => {
    Object.entries(attributes).forEach(([keyName, value]) => {
      if (value) {
        formData.append(`funeral[${attributeName}][${keyName}]`, value as string);
      }
    });
  };

  // Convert file (either an existing IMedia or new File) to Blob
  convertToBlob = async (file: IMedia | File): Promise<Blob> => {
    if ('content_type' in file) {
      return await urlToFile(file.url, file.file_name);
    } else {
      return file as Blob;
    }
  };
  
  appendFiles = (files: (File | IMedia)[], stateFiles: (File | IMedia)[], formData: FormData) => {
    if (stateFiles.length > 0 && files && files.length > 0) {
      files.forEach(async(file: File | IMedia) => {
        const blobFile = await this.convertToBlob(file);
        formData.append(`funeral[files][]`, blobFile);
      });
    }
  };
  
  appendOtherValues = (keyName: string, value: any, formData: FormData) => {
    if (keyName === "carry_people_name") {
      formData.append(`funeral[${keyName}][]`, value as string);
    } else if (!["funeral_detail_attributes", "cremation_detail_attributes", "wake_location_attributes", "funeral_song_attributes", "eulogy_apart_deliver_attributes"].includes(keyName)) {
      formData.append(`funeral[${keyName}]`, value as string);
    }
  };

  validationSchema = (isEdit: boolean) => {
    if (isEdit) {
      return Yup.object().shape({
        delegate_id: Yup.string().nullable().required(configJSON.delegateInchargeMsg),
        funeral_detail_attributes: Yup.object().when(['have_funeral_place'], {
          is: (have_funeral_place) => have_funeral_place === "1",
          then: Yup.object().shape({
            address_line_1: Yup.string().nullable().required("Please enter address line 1"),
            address_line_2: Yup.string().nullable().required("Please enter address line 2"),
            city: Yup.string().nullable().required("Please enter city"),
            country: Yup.string().nullable().required("Please select country"),
            post_code: Yup.string().nullable().required("Please enter post code"),
          }),
          otherwise: Yup.object().nullable(),
        }),
        wake_location_attributes: Yup.object().when(['have_location_for_wake'], {
          is: (have_location_for_wake) => have_location_for_wake === "1",
          then: Yup.object().shape({
            address_line_1: Yup.string().nullable().required("Please enter address line 1"),
            address_line_2: Yup.string().nullable().required("Please enter address line 2"),
            city: Yup.string().nullable().required("Please enter city"),
            country: Yup.string().nullable().required("Please select country"),
            post_code: Yup.string().nullable().required("Please enter post code"),
          }),
          otherwise: Yup.object().nullable(),
        }),
      });
    }
    else {
      return Yup.object().nullable();
    }
  };

  handleFileUploadEdit = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
  ) => {
    if (this.state.files.length > 0 && 'content_type' in this.state.files[0]) {
      this.setState({ files: [] });
    }

    if (event.target.files) {
      const newFilesEditFuneral = Array.from(event.target.files);
      this.setState((prevState) => ({
        files: [...prevState.files, ...newFilesEditFuneral],
      }), () => {
        // Set the field value after the state has been updated
        setFieldValue('files', [...this.state.files]);
      });
    }
  };
  
  // Customizable Area End
}