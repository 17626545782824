import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { IGetMedicalInfoAPIResponse, IMedicalInformations } from "../../customform/src/MedicalInformationController.web";
import * as Yup from "yup";
import { IMedia, ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import { IAttributes } from "../../../blocks/customform/src/AdditionalInfoSidebarController.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { IStep } from "./LegalDataEditController.web";
import { toast } from "react-toastify";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEdit: boolean;
  medicalData: IMedicalInformations;
  files: (IMedia | File)[];
  delegateInCharge: ISelectOptions[];
  openDialog: boolean;
  subscriptionData: IAttributes | null;
  steps: IStep[];
  savedOrgansList: string[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MedicalDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        isEdit: false,
        medicalData: {
            medical_information: {
                delegate_id: "",
                include_in_final_pdf: false,
                have_medical_condition: "0",
                have_registered_organ_donation: "0",
                have_you_give_blood: "0",
                have_you_know_blood_type: "0",
            },
            medical_condition: [{
                condition_type: "",
                condition_name: "",
                level: "",
                description: "",
            }],
            organ_donation: {
              id_number: "",
              organs: [],
              organ_name: [""],
            },
            blood_type: {
              blood_group: "",
              files: [],
            },
        },
        files: [],
        delegateInCharge: [],
        openDialog: false,
        subscriptionData: null,
        steps: [
          { label: 'Home', path: 'HomePage' },
          { label: 'My Data', path: 'HomePage' },
          { label: 'Medical Data', path: 'MedicalData' },
        ],
        savedOrgansList: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callEditMedicalDataApiId: string = "";
  callGetMedicalDataApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
    this.getMedicalData();

    const subscriptionData: IAttributes = await getStorageData("active_subscription",true);
    this.setState({ subscriptionData });
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersApiResponse,
      [this.callGetMedicalDataApiId]: this.handleGetMedicalDataApiResponse,
      [this.callEditMedicalDataApiId]: this.handleEditMedicalDataApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      let key = Object.keys(possibleErrors[0])[0];
      toast.error(possibleErrors[0][key]);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: { token },
        }
      );
  };

  getMedicalData = async() => {
    const token = await getStorageData("token");

    this.callGetMedicalDataApiId = sendAPIRequest(
        configJSON.getMedicalDataApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegateMembersApiResponse = (responseJSON: Record<string, unknown>) => {
   if (this.handleErrorResponse(responseJSON)) { return; }

    const response = responseJSON as {
      meta?: { 
        message: string 
      };
      data?: {
        id: string,
        type: string,
        attributes: IDelegateMemberAPIResponse,
      }[];
    };

    if (response.data) {
      let delegateInCharge: ISelectOptions[] = [];

      response.data.forEach((member) => {
        delegateInCharge.push({ value: member.attributes.id.toString(),
          name: member.attributes.first_name + " " + member.attributes.last_name,
        });
      });

      this.setState({ 
        delegateInCharge 
      });
    }
  };

  convertYesNoToBinary = (attribute: string, trueCondition = "Yes") => {
    return attribute === trueCondition ? "1" : "0";
  }

  handleGetMedicalDataApiResponse = (
    responseJSON: Record<string, unknown>
  ) => {

    const response = responseJSON as {
      meta?: { message: string };
      data?: IGetMedicalInfoAPIResponse;
    };

    if (response.data) {
      const attributes = response.data.attributes;

      if(attributes.media){
        this.setState({ files: attributes.media.map((mediaFile: IMedia) => {
          return({
            file_id: mediaFile.file_id,
            file_name: mediaFile.file_name,
            content_type: mediaFile.content_type,
            file_size: mediaFile.file_size,
            url: mediaFile.url,
          })
        }),
      })
      }
      let medicalData = {
      medical_information: {
          delegate_id: attributes.delegate_id.toString(),
          include_in_final_pdf: attributes.include_in_final_pdf,
          have_medical_condition: this.convertYesNoToBinary(attributes.have_medical_condition),
          have_registered_organ_donation: this.convertYesNoToBinary(attributes.have_registered_organ_donation, "No"),
          have_you_give_blood: this.convertYesNoToBinary(attributes.have_you_give_blood),
          have_you_know_blood_type: this.convertYesNoToBinary(attributes.have_you_know_blood_type),
        },
        organ_donation: {
          id_number: attributes.organ_donation?.id_number,
          organs: attributes.organ_donation.organs?.flatMap(organ => organ.split(',').map(name => name.trim())) || [],
          organ_name: attributes.organ_donation.organ_name ? [ ...attributes.organ_donation.organ_name] : [""],
        },
        blood_type: {
          blood_group: attributes.blood_type.blood_group,
          files: attributes.media?.map((mediaFile) => {
            return({
              file_id: mediaFile.file_id,
              file_name: mediaFile.file_name,
              content_type: mediaFile.content_type,
              file_size: mediaFile.file_size,
              url: mediaFile.url,
            })
          }),
        },
        medical_condition: attributes.medical_conditions.length > 0 ? attributes.medical_conditions?.map(
          (medicalCondition) => {
            return(
              {
                condition_type: medicalCondition.condition_type,
                condition_name: medicalCondition.condition_name,
                level: medicalCondition.level,
                description: medicalCondition.description,
                }
            )
          }
        ) : [{
          condition_type: "",
          condition_name: "",
          level: "",
          description: "",
      }],
      } as IMedicalInformations;

      let savedOrgansList = [ ...medicalData.organ_donation.organs, ...medicalData.organ_donation.organ_name]
          .flatMap(item => item.split(',').map(str => str.trim()))  
          .filter(item => item.toLowerCase() !== "other");
      this.setState({ 
        medicalData,
        savedOrgansList,
      });
    }
  };

  handleEditMedicalDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) { 
      return; 
    }

    const response = responseJSON as {
      meta?: { message: string };
      data?: IGetMedicalInfoAPIResponse;
    };

    if (response.data) {
      this.setState((prevState) => ({
        openDialog: true,
        isEdit: false,
        steps: prevState.steps.filter(
          (step) => !(step.label === 'Edit Details' && step.path === 'MedicalData')
        ),
      }));
    }
  };

  handleMedicalDataFormSubmit = async (values: IMedicalInformations) => {
      const token = await getStorageData("token");
      const formData = new FormData();

      this.appendFormData(formData, 'medical_information', values.medical_information);

      if (values.medical_information.have_medical_condition === "1") {
        this.appendMedicalConditionData(formData, values.medical_condition);
      }
      if (values.medical_information.have_registered_organ_donation === "1") {
        Object.entries(values.organ_donation).forEach(([keyName, value]) => {
          if (value && keyName === "id_number") {
            formData.append(`organ_donation[${keyName}]`, value);
          }
          else {
            formData.append(`organ_donation[${keyName}][]`, value);
          }
        });
      }

      if (values.medical_information.have_you_know_blood_type === "1") {
        this.appendBloodTypeData(formData, values.blood_type);
      }

      this.callEditMedicalDataApiId = sendAPIRequest(
        configJSON.getMedicalDataApiEndPoint,
        {
          method: configJSON.postApiRequest,
          headers: {
            token,
          },
          body: formData,
        }
      );
  };

  // Append form data for legal information, representative, or kin detail
  appendFormData = (formData: FormData, section: string, data: any) => {
    Object.entries(data).forEach(([keyName, value]) => {
      if (value) {
        formData.append(`${section}[${keyName}]`, value as string);
      }
    });
  };

  // Handle appending of 'blood type' data, including file handling
  appendBloodTypeData = async (formData: FormData, blood_type: any) => {
    Object.entries(blood_type).forEach(([keyName, value]) => {
      if (value && keyName !== 'files') {
        formData.append(`blood_type[${keyName}]`, value as string);
      }
    });

    if (blood_type.files && blood_type.files.length > 0 && this.state.files.length > 0) {
      for (const file of this.state.files) {
        const blobFile = await this.convertToBlob(file);
        formData.append(`blood_type[files][]`, blobFile);
      }
    }
  };

  // Append medical condition data
  appendMedicalConditionData = (formData: FormData, medicalConditions: any[]) => {
    medicalConditions.forEach((medicalCondition, index) => {
      Object.entries(medicalCondition).forEach(([keyName, value]) => {
        if (value) {
          formData.append(`medical_condition[][${keyName}]`, value as string);
        }
      });
    });
  };

  // Convert file (either an existing IMedia or new File) to Blob
  convertToBlob = async (file: IMedia | File): Promise<Blob> => {
    if ('content_type' in file) {
      return await urlToFile(file.url, file.file_name);
    } else {
      return file as Blob;
    }
  };

  validationSchema = (isEdit: boolean) => {
    if(isEdit) {
      return Yup.object().shape({
        medical_information: Yup.object().shape({
          delegate_id: Yup.string().nullable().required(configJSON.delegateInchargeMsg),
        }),
        medical_condition:  Yup.array().when(['medical_information.have_medical_condition'], {
          is: (have_medical_condition) => have_medical_condition === "1",
          then: Yup.array().of(Yup.object().shape({
            condition_type: Yup.string().nullable().required("Please select condition type"),
            condition_name: Yup.string().nullable().required("Please enter condition name"),
          })),
          otherwise: Yup.array().of(Yup.object().nullable()),
        }),
        organ_donation: Yup.object().when(['medical_information.have_registered_organ_donation'], {
          is: (have_registered_organ_donation) => have_registered_organ_donation === "1",
          then: Yup.object().shape({
            id_number: Yup.string().nullable().required("Please enter id number"),
            organs: Yup.array().nullable(),
            organ_name: Yup.array()
              .of(Yup.string().nullable()).when('organs', {
                is: (organs: string[]) => organs.includes('Other'),
                then: Yup.array().of(Yup.string().required('Organ name is required')),
                otherwise: Yup.array().of(Yup.string().nullable()),
              }),
          }),
          otherwise: Yup.object().nullable(),
        }),
        blood_type: Yup.object().when(['medical_information.have_you_know_blood_type'], {
          is: (have_you_know_blood_type) => have_you_know_blood_type === "1",
          then: Yup.object().shape({
            blood_group: Yup.string().nullable().required("Please select blood type"),
          }),
          otherwise: Yup.object().nullable(),
        }),
      });
    }
  };

  handleFileUploadMedical = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string, value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    }) => {
      if (this.state.files.length > 0 && 'content_type' in this.state.files[0]) {
        this.setState({ 
          files: [] 
        });
      }

      if (event.target.files) {
        const newFiles = Array.from(event.target.files);
        
        this.setState((prevState) => ({
          files: [...prevState.files, ...newFiles],
        }), () => {
          // Set the field value after the state has been updated
          setFieldValue('blood_type.files', [...this.state.files]);
        });
      }
  };
  

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleEditBtnClick = (event: any) => {
    event.preventDefault();
    this.setState((prevState) => ({
      isEdit: true,
      steps: [...prevState.steps, { label: 'Edit Details', path: 'MedicalData' }],
    }));
  }

  getOrganDonationStatus = (status: string) => {
    switch (status) {
      case "0":
        return "No";
      case "1":
        return "Yes";
      case "2":
        return "Sometimes";
      default:
        return "";
    }
  }

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
