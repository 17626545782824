import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { IAttributes } from "./AdditionalInfoSidebarController.web";
import { toast } from "react-toastify";
import CustomLocation from "../../../components/src/CustomLocation.web";
export interface ILegalRepresentative {
  full_name: string;
  email: string;
  full_phone_number: string;
};

export interface IKinDetail {
  full_name: string;
  relation: string;
  date_of_birth: string;
  email: string;
  full_phone_number: string;
  address_line_1: string;
  address_line_2: string;
  landmark: string;
  city: string;
  country: string;
  post_code: string;
};

export interface ILocationOfWill {
  full_name: string;
  about: string;
  address_line_1: string;
  address_line_2: string;
  landmark: string;
  city: string;
  country: string;
  post_code: string;
  files: string[] | ArrayBuffer[] | File[] | IMedia[] | null | undefined;
}

export interface IPolicyDetails {
  id: number;
  policy_number: string;
  provider: string;
  agent_name: string;
  plan_name: string;
  policy_term: string;
  payment_frequency: string;
  start_date: string;
  maturity_date: string;
  sum_assured: string;
}

export interface ILegalInformationInitial {
  delegate_id: string;
  have_legal_representative: string;
  have_will: string;
  have_life_insurance: string;
}

export interface ILegalInformations {
    legal_information: ILegalInformationInitial;
    legal_representative: ILegalRepresentative;
    kin_detail: IKinDetail;
    will: ILocationOfWill;
    policy: IPolicyDetails[];
};

export interface ILegalInformationAPIResponse {
  id: string,
  type: string,
  attributes: {
      id: number;
      delegate_id: number;
      delegate_charge: {
        name: string;
      };
      have_legal_representative: string;
      legal_representative: {
        id: number | null;
        full_name: string | null;
        email: string | null;
        full_phone_number: string | null;
      };
      kin_detail: {
        id: number | null;
        full_name: string | null;
        relationship: string | null;
        date_of_birth: string | null;
        email: string | null;
        full_phone_number: string | null;
        address_line_1: string | null;
        address_line_2: string | null;
        landmark: string | null;
        city: string | null;
        post_code: string | null;
        country: string | null;
      };
      have_will: string;
      will_location: {
        id: number | null;
        full_name: string | null;
        about: string | null;
        address_line_1: string | null;
        address_line_2: string | null;
        landmark: string | null;
        city: string | null;
        post_code: string | null;
        country: string | null;
        files: [] | null;
      };
      have_life_insurance: string;
      policy_details: {
        id: number;
        policy_number: string;
        provider: string;
        agent_name: string;
        plan_name: string;
        payment_frequency: string;
        start_date: string;
        maturity_date: string;
        sum_assured: number;
        policy_term: string;
      }[];
      account_id: number;
      media: IMedia[]; 
    };
}

export interface IMedia {
  file_id: number;
  file_name: string;
  content_type: string;
  file_size: number;
  url: string;
}

export interface ISelectOptions {
  value: string,
  name: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  legalInformations: ILegalInformations;
  have_legal_representative: boolean;
  have_will: boolean;
  have_life_insurance: boolean;
  files: (IMedia | File)[];
  openDialog: "infoAdded" | "infoNotAdded" | null;
  delegateInCharge: ISelectOptions[];
  subscriptionData: IAttributes | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LegalInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        legalInformations: {
            legal_information: {
              delegate_id: "",
              have_legal_representative: "0",
              have_will: "0",
              have_life_insurance: "0",
            },
            legal_representative: {
              full_name: "",
              email: "",
              full_phone_number: "",
            },
            kin_detail: {
              full_name: "",
              relation: "",
              date_of_birth: "",
              email: "",
              full_phone_number: "",
              address_line_1: "",
              address_line_2: "",
              landmark: "",
              city: "",
              country: "",
              post_code: "",
            },
            will: {
              full_name: "",
              about: "",
              address_line_1: "",
              address_line_2: "",
              landmark: "",
              city: "",
              country: "",
              post_code: "",
              files: null,
            },
            policy: [{
              id: 0,
              policy_number: "",
              provider: "",
              agent_name: "",
              plan_name: "",
              policy_term: "",
              payment_frequency: "",
              start_date: "",
              maturity_date: "",
              sum_assured: "",
            }],
        },
        have_legal_representative: false,
        have_will: false,
        have_life_insurance: false,
        files: [],
        openDialog: null,
        delegateInCharge: [],
        subscriptionData: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callGetLegalInfoApiId: string = "";
  callUpsertLegalInfoApiId: string = "";
  legalRepresentRef: React.RefObject<CustomLocation> = React.createRef()
  locationWillRef :React.RefObject<CustomLocation>  = React.createRef()

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
    this.getLegalInformation();

    const subscriptionData: IAttributes = await getStorageData("active_subscription");
    this.setState({ subscriptionData });
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersApiResponse,
      [this.callGetLegalInfoApiId]: this.handleGetLegalInfoApiResponse,
      [this.callUpsertLegalInfoApiId]: this.handleUpsertLegalInfoApiResponse,

    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      let key = Object.keys(possibleErrors[0])[0];
      toast.error(possibleErrors[0][key]);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  }

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  getLegalInformation = async() => {
    const token = await getStorageData("token");

    this.callGetLegalInfoApiId = sendAPIRequest(
        configJSON.getLegalInformationApiEndPoint,
        {
          method: configJSON.fetchFormDataMethod,
          headers: {
            token,
          },
        }
      );
  };

  handleLocationAdded = (values:ILegalInformationInitial)=>{
    let isVaild = true
    if(values.have_legal_representative){
      if(this.legalRepresentRef.current && !this.legalRepresentRef.current.checkIsVaild()){
        isVaild =  false
      }
    }
    if(values.have_will){
      if(this.locationWillRef.current && !this.locationWillRef.current.checkIsVaild()){
        isVaild =  false
      }
    }
    return isVaild
  }
  handleLegalInformationsFormSubmit = async (values: ILegalInformations) => {
    const token = await getStorageData("token");
    const formData = new FormData();

    if(!this.handleLocationAdded(values.legal_information)){
      return false
    }

    Object.entries(values.legal_information).forEach(([keyName, value]) => {
      if (value) {
          formData.append(`legal_information[${keyName}]`, value);
      }
    });

    Object.entries(values.legal_representative).forEach(([keyName, value]) => {
      if (value) {
          formData.append(`legal_representative[${keyName}]`, value);
      }
    });

    Object.entries(values.kin_detail).forEach(([keyName, value]) => {
      if (value) {
          formData.append(`kin_detail[${keyName}]`, value);
      }
    });

    Object.entries(values.will).forEach(([keyName, value]) => {
      if (value) {
        if(keyName !== 'files'){
          formData.append(`will[${keyName}]`, value);
        }
      }
    });

    Object.entries(values.will).forEach(([keyName, value]) => {
      if (value) {
        if(keyName === 'files' && values.will.files && values.will.files.length > 0){
          if(this.state.files.length > 0 && !('content_type' in this.state.files[0])){
            values.will.files.forEach((file: string | IMedia | File | ArrayBuffer) => (
              formData.append(`will[${keyName}][]`, file as Blob)
            ))
        }
        }
      }
    });

    values.policy.forEach((policy, index) => {
      Object.entries(policy).forEach(([keyName, value]) => {
        if (value) {
          formData.append(`policy[][${keyName}]`, value as string);
        }
      });
    });

    this.callUpsertLegalInfoApiId = sendAPIRequest(
        configJSON.getLegalInformationApiEndPoint,
        {
          method: configJSON.formAPiMethod,
          headers: {
            token,
          },
          body: formData,
        }
      );
  };

  handleGetDelegateMembersApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as {
      meta?: { message: string };
      data?: {
        id: string,
        type: string,
        attributes: IDelegateMemberAPIResponse,
      }[];
    };

    if (response.data) {
      let delegateInCharge: ISelectOptions[] = [];

      response.data.forEach((member) => {
        delegateInCharge.push({
          value: member.attributes.id.toString(),
          name: member.attributes.first_name + " " + member.attributes.last_name,
        });
      });

      this.setState({ delegateInCharge });
    }
  };

  handleGetLegalInfoApiResponse = (
    responseJSON: Record<string, unknown>
  ) => {

    const response = responseJSON as {
      meta?: { message: string };
      data?: ILegalInformationAPIResponse;
    };

    if (response.data) {
      const attributes = response.data.attributes;
      if(attributes.have_legal_representative === "Yes"){
        this.setState({ have_legal_representative: true });
      }
      if(attributes.have_will === "Yes"){
        this.setState({ have_will: true });
      }
      if(attributes.have_life_insurance === "Yes"){
        this.setState({ have_life_insurance: true });
      }
      if(attributes.media){
        this.setState({ files: attributes.media.map((mediaFile) => {
          return({
            file_id: mediaFile.file_id,
            file_name: mediaFile.file_name,
            content_type: mediaFile.content_type,
            file_size: mediaFile.file_size,
            url: mediaFile.url,
          })
        }),
      })
      }
      let legalInformations = {
        legal_information: {
          delegate_id: attributes.delegate_id.toString(),
          have_legal_representative: attributes.have_legal_representative === "Yes" ? "1" : "0",
          have_will: attributes.have_will === "Yes" ? "1" : "0",
          have_life_insurance: attributes.have_life_insurance === "Yes" ? "1" : "0",
        },
        legal_representative: {
          full_name: attributes.legal_representative.full_name,
          email: attributes.legal_representative.email,
          full_phone_number: attributes.legal_representative.full_phone_number,
        },
        kin_detail: {
          full_name: attributes.kin_detail.full_name,
          relation: attributes.kin_detail.relationship,
          date_of_birth: attributes.kin_detail.date_of_birth,
          email: attributes.kin_detail.email,
          full_phone_number: attributes.kin_detail.full_phone_number,
          address_line_1: attributes.kin_detail.address_line_1,
          address_line_2: attributes.kin_detail.address_line_2,
          landmark: attributes.kin_detail.landmark,
          city: attributes.kin_detail.city,
          country: attributes.kin_detail.country,
          post_code: attributes.kin_detail.post_code,
        },
        will: {
          full_name: attributes.will_location.full_name,
          about: attributes.will_location.about,
          address_line_1: attributes.will_location.address_line_1,
          address_line_2: attributes.will_location.address_line_2,
          landmark: attributes.will_location.landmark,
          city: attributes.will_location.city,
          country: attributes.will_location.country,
          post_code: attributes.will_location.post_code,
          files: attributes.media.map((mediaFile) => {
            return({
              file_id: mediaFile.file_id,
              file_name: mediaFile.file_name,
              content_type: mediaFile.content_type,
              file_size: mediaFile.file_size,
              url: mediaFile.url,
            })
          }),
        },
        policy: attributes.policy_details.map(
          (policy) => {
            return(
              {
                policy_number: policy.policy_number,
                provider: policy.provider,
                agent_name: policy.agent_name,
                plan_name: policy.plan_name,
                policy_term: policy.policy_term,
                payment_frequency: policy.payment_frequency,
                start_date: policy.start_date,
                maturity_date: policy.maturity_date,
                sum_assured: policy.sum_assured.toString(),
                }
            )
          }
        ),
      } as ILegalInformations;
      this.setState({ legalInformations });
    }
  };

  handleUpsertLegalInfoApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    
    const response = responseJSON as {
      meta?: { message: string };
      data?: ILegalInformationAPIResponse;
    };

    if (response.data) {
      this.setState({ openDialog: "infoAdded"});
    }
  };


  validationSchema = Yup.object().shape({
    legal_information: Yup.object().shape({
      delegate_id: Yup.string().nullable().required(configJSON.delegateInchargeMsg),
    }),
    legal_representative:  Yup.object().when(['legal_information.have_legal_representative'], {
      is: (have_legal_representative) => have_legal_representative === "1",
      then: Yup.object().shape({
        full_name: Yup.string().nullable().required(configJSON.fullNameMsg),
        email: Yup.string().nullable().email(configJSON.validEmailMsg).required(configJSON.emailMsg),
        full_phone_number: Yup.string().nullable().required(configJSON.phoneNumberMsg),
      }),
      otherwise: Yup.object().nullable(),
    }),
    kin_detail: Yup.object().when(['legal_information.have_legal_representative'], {
      is: (have_legal_representative) => have_legal_representative === "1",
      then: Yup.object().shape({
        full_name: Yup.string().nullable().required(configJSON.kinFullNameMsg),
        relation: Yup.string().nullable().required(configJSON.kinRelationMsg),
        email: Yup.string().nullable().email(configJSON.kinValidEmailMsg).required(configJSON.kinEmailMsg),
        full_phone_number: Yup.string().nullable().required(configJSON.phoneNumberMsg),
        date_of_birth: Yup.string().nullable().required(configJSON.kinDOBMsg),
        address_line_1: Yup.string().nullable().required(configJSON.kinAddressline1Msg),
        address_line_2: Yup.string().nullable().required(configJSON.kinAddressline2Msg),
        city: Yup.string().nullable().required(configJSON.kinCityMsg),
        country: Yup.string().nullable().required(configJSON.kinCountryMsg),
        post_code: Yup.string().nullable().required(configJSON.kinPostcodeMsg),
      }),
      otherwise: Yup.object().nullable(),
    }),
    will: Yup.object().when(['legal_information.have_will'], {
      is: (have_will) => have_will === "1",
      then: Yup.object().shape({
      }),
      otherwise: Yup.object().nullable(),
    }),
    policy: Yup.array().when(['legal_information.have_life_insurance'], {
      is: (have_life_insurance) => have_life_insurance === "1",
      then: Yup.array().of(Yup.object().shape({
        policy_number: Yup.string().nullable().required(configJSON.policyNumberMsg),
        provider: Yup.string().nullable().required(configJSON.policyProviderMsg),
        plan_name: Yup.string().nullable().required(configJSON.planNameMsg),
        policy_term:Yup.string().nullable().required(configJSON.policyTermMsg),
        payment_frequency: Yup.string().nullable().required(configJSON.paymentFrequencyMsg),
        start_date: Yup.string().nullable().required(configJSON.startDateOfPolicyMsg),
        maturity_date: Yup.string().nullable().required(configJSON.maturityDateOfPolicyMsg),
        sum_assured: Yup.number().typeError("Please enter number value only").nullable().required(configJSON.sumAssuredMsg),
      })),
      otherwise: Yup.array().of(Yup.object().nullable()),
    }),
  });

  calenderIcon = () => {
    return (
      <div className="calenderIconStyle">
        <svg fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.5 9.08984H20.5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.9955 13.6992H12.0045" stroke="#A190C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 13.6992H8.30329" stroke="#A190C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 16.6992H8.30329" stroke="#A190C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </div>
    );
  }

  handleFileUpload = async(event: ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    }) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      this.setState((prevState) => ({
        files: [...prevState.files, ...newFiles],
      }), () => {
        // Set the field value after the state has been updated
        setFieldValue('will.files', [...this.state.files]);
      });
    }
  };

  handleFileRemove = (index: number, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    }) => {
    this.setState((prevState) => {
      const updatedFiles = [...prevState.files];
      updatedFiles.splice(index, 1);
      return { files: updatedFiles };
    });
    setFieldValue('will.files', [ ...this.state.files ]);
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: null });
  };

  handleOpenSkipDialog = () => {
    this.setState({ openDialog: "infoNotAdded" });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}
