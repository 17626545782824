import {
    Box,
    Typography,
    ThemeProvider,
    createTheme,
    Grid,
    Dialog,
    Button,
} from "@material-ui/core";
import React from "react";
import RoomIcon from '@material-ui/icons/Room';
import CloseIcon from '@material-ui/icons/Close';
import GeoLocationSearch from "../../blocks/geotaggingforpictures/src/GeoLocationSearch.web";
import { getStorageData } from "../../framework/src/Utilities";
import { sendAPIRequest } from "./Utils";


const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                color: "white",
                backgroundColor: "#9176C4",
                borderRadius: 8,
                marginLeft: "15px",
                padding: '1px 20px',
                display: "flex",
                alignItems: "center",
                fontSize: "15px",
                fontWeight: 400
            },
            body1:{
                color: "#DC2626",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                fontWeight: 400
            }
        },
        MuiDialog: {
            paper: {
                maxWidth: '90% !important',
            }
        },
        MuiButton: {
            root: {
                textTransform: "none",
                fontSize: '16px',
                fontFamily: "Lato",
            },
            text: {
                lineHeight: "26px",
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
                textDecoration: "underline",
                color: "#9176C4"
            }
        }

    }
});

interface MapLocation {
    name: string,
    description: string,
    lng: string,
    lat: string
}

interface Props {
    navigation: any
}

interface S {
    isMap: boolean
    mapLocation: MapLocation
    isError: boolean
}

export default class CustomLocation extends React.Component<Props, S> {
    constructor(props: any) {
        super(props);
        this.state = {
            isMap: false,
            mapLocation: {
                name:"", 
                description: "", 
                lng:"", 
                lat: ""},
                isError: false
        }
    };

    handleMapClose = () => {
        this.setState({ isMap: false })
    }

    handleMapSuccess = (mapLocation: {
        name: string,
        description: string,
        lng: string,
        lat: string
    }) => {
        this.setState({ isMap: false, mapLocation , isError: false})
    }

    handleAddLocation = () => {
        if (this.isMapLocation()) {
            this.setState({ isMap: true })
        }
    }

    isMapLocation = () => {
        return this.state.mapLocation.name === ""
    }
    
      handleClearLocation = () => {
        this.setState({
          mapLocation: {
            name: "",
            description: "",
            lng: "",
            lat: ""
          }
        })
      }

      checkIsVaild=()=>{
        let isValid = this.state.mapLocation.name !== ""
        !isValid ? this.setState({isError: true}) : this.handleSubmit()
        return isValid
      }

      handleSubmit = async ()=>{
        const token = await getStorageData("token");
        let {name, description, lat, lng} = this.state.mapLocation
        const formData = new FormData();
        formData.append("map[location_name]", name);
        formData.append("map[description]", description);
        formData.append("map[latitude]", lat);
        formData.append("map[longitude]", lng);
          sendAPIRequest(
            "bx_block_geotaggingforpictures/maped_images",
            {
              method: "POST",
              headers: {
                token,
              },
              body: formData,
            }
          )
      }
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                    <Grid container>
                        <Button variant="text" disabled={!this.isMapLocation()} onClick={() => this.handleAddLocation()}>
                            <RoomIcon /> Add location to map <span style={{ color: "red", fontWeight: 400 }}>*</span>
                        </Button>
                        {!this.isMapLocation() && <Typography variant="h1" className="locationName">
                            {this.state.mapLocation.name} <CloseIcon style={{ marginLeft: "15px" }} onClick={() => this.handleClearLocation()} />
                        </Typography>}
                      { this.state.isError && <Grid item xs={12}>
                            <Typography variant="body1">Please add location on map</Typography>
                        </Grid>}
                    </Grid>
                <Dialog
                    fullWidth
                    open={this.state.isMap} onClose={this.handleMapClose}
                    style={{ borderRadius: 12 }}
                >
                    <Box padding="15px 20px 0px">
                        <Grid container justifyContent="flex-end">
                            <CloseIcon onClick={this.handleMapClose} />
                        </Grid>
                        <GeoLocationSearch navigation={this.props.navigation} id="delegateEditMember" isMinScreen={true} isForm={this.state.isMap} handleForm={this.handleMapSuccess} />
                    </Box>
                </Dialog>
            </ThemeProvider>
        );
    }
};

