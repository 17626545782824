import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import { IMedia } from "./InvestmentsAndFundsEditController.web";
import { toast } from "react-toastify";

export interface IFuneralHairBeauty {
  is_include_final_pdf: boolean;
  delegate_id: string;
  hair_style_type: string;
  hair_style_files: (File | FileInfo)[];
  require_makeup: string;
  makeup_type: string;
  makeup_files: (File | IMedia)[];
  require_nails: string;
  nails_type: string;
  nail_files: (File | IMedia)[];
  perfume_name: string;
}

export interface IGetFuneralHairBeautyAPIResponse {
      id: string;
      type: string;
      attributes: {
        id: number;
        is_include_final_pdf: boolean;
        delegate_id: number;
        hair_style_type: string;
        require_makeup: string;
        makeup_type: string;
        require_nails: string;
        nails_type: string;
        perfume_name: string;
        account_id: number;
        hair_style_files: FileInfo[];
        makeup_files: IMedia[];
        nail_files: IMedia[];
        delegate_charge: {
          name: string;
        };
      };
  }
  
  interface FileInfo {
    url: string;
  }

  type StateKeys = {
    hairBeautyInfo: IFuneralHairBeauty,
    isLoading: boolean,
    hairStyleFiles: (File | IMedia)[],
    makeupFiles: (File | IMedia)[],
    nailFiles: (File | IMedia)[],
    delegateInCharge: ISelectOptions[],
    isEdit: boolean,
};
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNextBtnClick: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  hairBeautyInfo: IFuneralHairBeauty,
  isLoading: boolean,
  hairStyleFiles: (File | IMedia)[],
  makeupFiles: (File | IMedia)[],
  nailFiles: (File | IMedia)[],
  delegateInCharge: ISelectOptions[],
  isEdit: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FuneralHairBeautyEditController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        hairBeautyInfo: {
            is_include_final_pdf: false,
            delegate_id: "",
            hair_style_type: "",
            hair_style_files: [],
            require_makeup: "0",
            makeup_type: "",
            makeup_files: [],
            require_nails: "0",
            nails_type: "",
            nail_files: [],
            perfume_name: "",
        },
        isLoading: false,
        hairStyleFiles: [],
        makeupFiles: [],
        nailFiles: [],
        delegateInCharge: [],
        isEdit: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersListApiId: string = "";
  callGetFuneralHairBeautyDataApiId: string = "";
  callEditfuneralHairBeautyDataApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
    this.getHairBeautyData();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callGetDelegateMembersListApiId]: this.handleGetDelegatesListApiResponse,
        [this.callGetFuneralHairBeautyDataApiId]: this.handleGetFuneralHairBeautyDataApiResponse,
        [this.callEditfuneralHairBeautyDataApiId]: this.handleEditFuneralHairBeautyDataApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersListApiId = sendAPIRequest(configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };

  getHairBeautyData = async () => {
    const token = await getStorageData("token");

    this.callGetFuneralHairBeautyDataApiId = sendAPIRequest(configJSON.getHairBeautyDataApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegatesListApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
     this.props.handleError("something went wrong!"); 
     return; 
   }
 
     const response = responseJSON as {
       data?: {
         id: string,
         type: string,
         attributes: IDelegateMemberAPIResponse,
       }[];
       meta?: { message: string };
     };
 
     if (response.data) {
       let delegateInCharge: ISelectOptions[] = [];
 
       response.data.forEach((member) => {
         delegateInCharge.push({ value: member.attributes.id.toString(),
           name: member.attributes.first_name + " " + member.attributes.last_name,
         });
       });
 
       this.setState({ delegateInCharge });
     }
  };

  // Convert file (either an existing IMedia or new File) to Blob
  convertToBlob = async (file: FileInfo | File): Promise<Blob> => {
    if ('url' in file) {
      return await urlToFile(file.url, "File");
    } else {
      return file as Blob;
    }
  };

  processFiles = async (
    files: { url: string }[],
    stateKey: keyof StateKeys,
    namePrefix: string) => {
    if (files.length > 0) {
      const blobFiles: IMedia[] = await Promise.all(
        files.map(async (file, index) => {
          const blobFile = await this.convertToBlob(file);
          return {
            file_id: index,
            file_name: `${namePrefix}_${index + 1}`,
            content_type: blobFile.type,
            file_size: blobFile.size,
            url: file.url,
          } as IMedia;
        })
      );

      // Set state with the correct key type
      this.setState((prevState) => ({
        ...prevState,
        [stateKey]: blobFiles,
      }));
    }
  }


   handleGetFuneralHairBeautyDataApiResponse = async(responseJSON: Record<string, unknown>) => {
    const response = responseJSON as {
        meta?: { message: string };
        data?: IGetFuneralHairBeautyAPIResponse;
      };

      if (response.data) {
        const attributes = response.data.attributes;

        await this.processFiles(attributes.hair_style_files, "hairStyleFiles", "hair_style");

        if(attributes.makeup_files){
          this.setState({ makeupFiles: attributes.makeup_files.map((mediaFile) => {
            return({
              file_id: mediaFile.file_id,
              file_name: mediaFile.file_name,
              content_type: mediaFile.content_type,
              file_size: mediaFile.file_size,
              url: mediaFile.url,
            })
          }),
        })
        }

        if(attributes.nail_files){
          this.setState({ nailFiles: attributes.nail_files.map((mediaFile) => {
            return({
              file_id: mediaFile.file_id,
              file_name: mediaFile.file_name,
              content_type: mediaFile.content_type,
              file_size: mediaFile.file_size,
              url: mediaFile.url,
            })
          }),
        })
        }

        let hairBeautyInfo = {
            is_include_final_pdf: attributes.is_include_final_pdf,
            delegate_id: attributes.delegate_id.toString(),
            hair_style_type: attributes.hair_style_type,
            hair_style_files: attributes.hair_style_files || [],
            require_makeup: attributes.require_makeup === "Yes" ? "1" : "0",
            makeup_type: attributes.makeup_type,
            makeup_files: attributes.makeup_files || [],
            require_nails: attributes.require_nails === "Yes" ? "1" : "0",
            nails_type: attributes.nails_type,
            nail_files: attributes.nail_files || [],
            perfume_name: attributes.perfume_name,
        };

        this.setState({ hairBeautyInfo });
      }
   };

   handleEditFuneralHairBeautyDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      this.props.handleError("something went wrong!");
      return; 
    }

    this.setState({isEdit: false});
    if (responseJSON.data) {
      this.props.handleSuccess();
    }
  };

    handleFuneralHairBeautyInfoFormSubmit = async (values: IFuneralHairBeauty) => {
        if (!this.state.isEdit) {
            this.toggleEditMode();
        }
        else {
            const token = await getStorageData("token");
            const formData = new FormData();

            this.setState({ isLoading: true });

            // Append main form values
            for (const [keyName, value] of Object.entries(values)) {
              if (keyName === 'hair_style_files') {
                await Promise.resolve(this.appendFiles(values.hair_style_files, this.state.hairStyleFiles, formData, keyName));
              } else if (keyName === 'makeup_files') {
                await Promise.resolve(this.appendFiles(values.makeup_files, this.state.makeupFiles, formData, keyName));
              } else if (keyName === 'nail_files') {
                await Promise.resolve(this.appendFiles(values.nail_files, this.state.nailFiles, formData, keyName));
              } else {
                  formData.append(`beauty[${keyName}]`, value as string);
              }
          }
          

            this.callEditfuneralHairBeautyDataApiId = sendAPIRequest(
                configJSON.getHairBeautyDataApiEndPoint,
                {
                    method: configJSON.postApiRequest,
                    headers: {
                        token,
                    },
                    body: formData,
                }
            );

        }
    };

  toggleEditMode = () => {
    this.setState({
      isEdit: true,
    });
  };

  appendFiles = (files: (File | FileInfo | IMedia)[], stateFiles: (File | IMedia)[], formData: FormData, keyName: string) => {
    if (stateFiles.length > 0 && files && files.length > 0) {
      files.forEach(async (file: (File | FileInfo)) => {
        const blobFile = await this.convertToBlob(file);
        formData.append(`beauty[${keyName}][]`, blobFile);
      });
    }
  };

    validationSchema = (isEdit: boolean) => {
        if (isEdit) {
            return Yup.object().shape({
                delegate_id: Yup.string().nullable().required(configJSON.delegateInchargeMsg),
            });
        }
        else {
            return Yup.object().nullable();
        }
    }

  resetFilesIfRequired = (attributeName: string) => {
    if (attributeName === "hair_style_files") {
      if (this.state.hairStyleFiles.length > 0 && 'content_type' in this.state.hairStyleFiles[0]) {
        this.setState({ hairStyleFiles: [] });
      }
    }
    else if (attributeName === "makeup_files") {
      if (this.state.makeupFiles.length > 0 && 'content_type' in this.state.makeupFiles[0]) {
        this.setState({ makeupFiles: [] });
      }
    }
    else if (attributeName === "nail_files") {
      if (this.state.nailFiles.length > 0 && 'content_type' in this.state.nailFiles[0]) {
        this.setState({ nailFiles: [] });
      }
    }
  };

  handleFileUpload = async(event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
    attributeName: string
  ) => {
    if (event.target.files) {
        const newFiles = Array.from(event.target.files);
        if(attributeName === "hair_style_files"){
            this.resetFilesIfRequired(attributeName);

            this.setState((prevState) => ({
                hairStyleFiles: [...prevState.hairStyleFiles, ...newFiles],
            }), () => {
                setFieldValue('hair_style_files', [...this.state.hairStyleFiles]);
            });
        }
        else if(attributeName === "makeup_files"){
            this.resetFilesIfRequired(attributeName);

            this.setState((prevState) => ({
                makeupFiles: [...prevState.makeupFiles, ...newFiles],
            }), () => {
               
                setFieldValue('makeup_files', [...this.state.makeupFiles]);
            });
        }
        else if(attributeName === "nail_files"){
            this.resetFilesIfRequired(attributeName);

            this.setState((prevState) => ({
                nailFiles: [...prevState.nailFiles, ...newFiles],
            }), () => {
                // Set the field value after the state has been updated
                setFieldValue('nail_files', [...this.state.nailFiles]);
            });
        }
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      return true;
    }
    return false;
  };
  // Customizable Area End
}
