import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { ILegalInformationAPIResponse, ILegalInformations, IMedia, ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import * as Yup from "yup";
import { getStorageData } from "../../../framework/src/Utilities";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { toast } from "react-toastify";

export interface IStep {
  label: string;
  path: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  legalData: ILegalInformations;
  isEdit: boolean;
  delegateInCharge: ISelectOptions[];
  files: (File | IMedia)[];
  openDialog: boolean;
  steps: IStep[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LegalDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      legalData: {
        legal_information: {
          delegate_id: "",
          have_legal_representative: "0",
          have_will: "0",
          have_life_insurance: "0",
        },
        legal_representative: {
          full_name: "",
          email: "",
          full_phone_number: "",
        },
        kin_detail: {
          full_name: "",
          relation: "",
          date_of_birth: "",
          email: "",
          full_phone_number: "",
          address_line_1: "",
          address_line_2: "",
          landmark: "",
          city: "",
          country: "",
          post_code: "",
        },
        will: {
          full_name: "",
          about: "",
          address_line_1: "",
          address_line_2: "",
          landmark: "",
          city: "",
          country: "",
          post_code: "",
          files: null,
        },
        policy: [{
          id: 0,
          policy_number: "",
          provider: "",
          agent_name: "",
          plan_name: "",
          policy_term: "",
          payment_frequency: "",
          start_date: "",
          maturity_date: "",
          sum_assured: "",
        }],
    },
    isEdit: false,
    delegateInCharge: [],
    files: [],
    openDialog: false,
    steps: [
      { label: 'Home', path: 'HomePage' },
      { label: 'My Data', path: 'HomePage' },
      { label: 'Legal Data', path: 'LegalData' },
    ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.apiSuccessCallBackControllerLegal(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callGetLegalDataApiId: string = "";
  callEditLegalDataApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembers();
    this.getLegalData();
  };

  apiSuccessCallBackControllerLegal = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersApiResponse,
      [this.callGetLegalDataApiId]: this.handleGetLegalDataApiResponse,
      [this.callEditLegalDataApiId]: this.handleEditLegalDataApiResponse,

    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, any>) => {
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      toast.error(possibleErrors[0].message);
      return true; // Indicates that there was an error
    }
    return false; // Indicates that there was no error
  }

  getDelegateMembers = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };

  handleGetDelegateMembersApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const responses = responseJSON as {
      meta?: { message: string };
      data?: {
        id: string,
        type: string,
        attributes: IDelegateMemberAPIResponse,
      }[];
    };

    if (responses.data) {
      let delegateInCharge: ISelectOptions[] = [];

      responses.data.forEach((member) => {
        delegateInCharge.push({
          value: member.attributes.id.toString(),
          name: member.attributes.first_name + " " + member.attributes.last_name,
        });
      });

      this.setState({ delegateInCharge });
    }
  };

  getLegalData = async() => {
    const token = await getStorageData("token");

    this.callGetLegalDataApiId = sendAPIRequest(
        configJSON.getLegalDataApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: {
            token,
          },
        }
      );
  };

  handleGetLegalDataApiResponse = async(
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as {
      meta?: { message: string };
      data?: ILegalInformationAPIResponse;
    };

    if (response.data) {
      const attributes = response.data.attributes;

      if(attributes.media){
        this.setState({ files: attributes.media.map((mediaFile) => {
          return({
            file_id: mediaFile.file_id,
            file_name: mediaFile.file_name,
            content_type: mediaFile.content_type,
            file_size: mediaFile.file_size,
            url: mediaFile.url,
          })
        }),
      })
      }

      let legalData = {
        legal_information: {
          delegate_id: attributes.delegate_id.toString(),
          have_legal_representative: attributes.have_legal_representative === "Yes" ? "1" : "0",
          have_will: attributes.have_will === "Yes" ? "1" : "0",
          have_life_insurance: attributes.have_life_insurance === "Yes" ? "1" : "0",
        },
        legal_representative: {
          full_name: attributes.legal_representative.full_name,
          email: attributes.legal_representative.email,
          full_phone_number: attributes.legal_representative.full_phone_number,
        },
        kin_detail: {
          full_name: attributes.kin_detail.full_name,
          relation: attributes.kin_detail.relationship,
          date_of_birth: attributes.kin_detail.date_of_birth,
          email: attributes.kin_detail.email,
          full_phone_number: attributes.kin_detail.full_phone_number,
          address_line_1: attributes.kin_detail.address_line_1,
          address_line_2: attributes.kin_detail.address_line_2,
          landmark: attributes.kin_detail.landmark,
          city: attributes.kin_detail.city,
          country: attributes.kin_detail.country,
          post_code: attributes.kin_detail.post_code,
        },
        will: {
          full_name: attributes.will_location.full_name,
          about: attributes.will_location.about,
          address_line_1: attributes.will_location.address_line_1,
          address_line_2: attributes.will_location.address_line_2,
          landmark: attributes.will_location.landmark,
          city: attributes.will_location.city,
          country: attributes.will_location.country,
          post_code: attributes.will_location.post_code,
          files: attributes.media?.map((mediaFile) => {
            return({
              file_id: mediaFile.file_id,
              file_name: mediaFile.file_name,
              content_type: mediaFile.content_type,
              file_size: mediaFile.file_size,
              url: mediaFile.url,
            })
          }) || [],        
        },
        policy: attributes.policy_details.length > 0 ? attributes.policy_details.map(
          (policy) => {
            return(
              {
                policy_number: policy.policy_number,
                provider: policy.provider,
                agent_name: policy.agent_name,
                plan_name: policy.plan_name,
                policy_term: policy.policy_term,
                payment_frequency: policy.payment_frequency,
                start_date: policy.start_date,
                maturity_date: policy.maturity_date,
                sum_assured: policy.sum_assured.toString(),
                }
            )
          }
        ) : [{
          policy_number: "",
          provider: "",
          agent_name: "",
          plan_name: "",
          policy_term: "",
          payment_frequency: "",
          start_date: "",
          maturity_date: "",
          sum_assured: "",
        }],
      } as ILegalInformations;
      this.setState({ legalData });
    }
  };

  handleLegalDataFormSubmit = async(values: ILegalInformations) => {
    if(!this.state.isEdit){
      this.toggleEditMode();
    }
    else{
      const token = await getStorageData("token");
      const formData = new FormData();

      this.appendFormData(formData, 'legal_information', values.legal_information);
      if(values.legal_information.have_legal_representative === "1"){
        this.appendFormData(formData, 'legal_representative', values.legal_representative);
        this.appendFormData(formData, 'kin_detail', values.kin_detail);
      }
      if(values.legal_information.have_will === "1"){
        await this.appendWillData(formData, values.will);
      }
      if(values.legal_information.have_life_insurance === "1"){
        this.appendPolicyData(formData, values.policy);
      }
      
      this.callEditLegalDataApiId = sendAPIRequest(
          configJSON.getLegalDataApiEndPoint,
          {
            method: configJSON.postApiRequest,
            headers: {
              token,
            },
            body: formData,
          }
        );
    }
  };

  // Toggle edit mode and update steps
  toggleEditMode = () => {
    this.setState((prevState) => ({
      isEdit: true,
      steps: [...prevState.steps, { label: 'Edit Details', path: 'LegalData' }],
    }));
  };

  // Append form data for legal information, representative, or kin detail
  appendFormData = (formData: FormData, section: string, data: any) => {
    Object.entries(data).forEach(([keyName, value]) => {
      if (value) {
        formData.append(`${section}[${keyName}]`, value as string);
      }
    });
  };

  // Handle appending of 'will' data, including file handling
  appendWillData = async (formData: FormData, will: any) => {
    Object.entries(will).forEach(([keyName, value]) => {
      if (value && keyName !== 'files') {
        formData.append(`will[${keyName}]`, value as string);
      }
    });

    if (will.files && will.files.length > 0 && this.state.files.length > 0) {
      for (const file of this.state.files) {
        const blobFile = await this.convertToBlob(file);
        formData.append(`will[files][]`, blobFile);
      }
    }
  };

  // Append policy data
  appendPolicyData = (formData: FormData, policies: any[]) => {
    policies.forEach((policy, index) => {
      Object.entries(policy).forEach(([keyName, value]) => {
        if (value) {
          formData.append(`policy[][${keyName}]`, value as string);
        }
      });
    });
  };

  // Convert file (either an existing IMedia or new File) to Blob
  convertToBlob = async (file: IMedia | File): Promise<Blob> => {
    if ('content_type' in file) {
      return await urlToFile(file.url, file.file_name);
    } else {
      return file as Blob;
    }
  };

  handleEditLegalDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    
    const response = responseJSON as {
      meta?: { message: string };
      data?: ILegalInformationAPIResponse;
    };

    if (response.data) {
      this.setState((prevState) => ({
        openDialog: true,
        isEdit: false,
        steps: prevState.steps.filter(
          (step) => !(step.label === 'Edit Details' && step.path === 'LegalData')
        ),
      }));
    }
  };

  validationSchema = (isEdit: boolean) => {
    if(isEdit) {
      return Yup.object().shape({
        legal_information: Yup.object().shape({
          delegate_id: Yup.string().nullable().required(configJSON.delegateInchargeMsg),
        }),
        legal_representative:  Yup.object().when(['legal_information.have_legal_representative'], {
          is: (have_legal_representative) => have_legal_representative === "1",
          then: Yup.object().shape({
            full_name: Yup.string().nullable().required(configJSON.fullNameMsg),
            email: Yup.string().nullable().email(configJSON.validEmailMsg).required(configJSON.emailMsg),
            full_phone_number: Yup.string().nullable().required(configJSON.phoneNumberMsg),
          }),
          otherwise: Yup.object().nullable(),
        }),
        kin_detail: Yup.object().when(['legal_information.have_legal_representative'], {
          is: (have_legal_representative) => have_legal_representative === "1",
          then: Yup.object().shape({
            full_name: Yup.string().nullable().required(configJSON.kinFullNameMsg),
            relation: Yup.string().nullable().required(configJSON.kinRelationMsg),
            email: Yup.string().nullable().email(configJSON.kinValidEmailMsg).required(configJSON.kinEmailMsg),
            full_phone_number: Yup.string().nullable().required(configJSON.phoneNumberMsg),
            date_of_birth: Yup.string().nullable().required(configJSON.kinDOBMsg),
            address_line_1: Yup.string().nullable().required(configJSON.kinAddressline1Msg),
            address_line_2: Yup.string().nullable().required(configJSON.kinAddressline2Msg),
            city: Yup.string().nullable().required(configJSON.kinCityMsg),
            country: Yup.string().nullable().required(configJSON.kinCountryMsg),
            post_code: Yup.string().nullable().required(configJSON.kinPostcodeMsg),
          }),
          otherwise: Yup.object().nullable(),
        }),
        will: Yup.object().when(['legal_information.have_will'], {
          is: (have_will) => have_will === "1",
          then: Yup.object().shape({
          }),
          otherwise: Yup.object().nullable(),
        }),
        policy: Yup.array().when(['legal_information.have_life_insurance'], {
          is: (have_life_insurance) => have_life_insurance === "1",
          then: Yup.array().of(Yup.object().shape({
            policy_number: Yup.string().nullable().required(configJSON.policyNumberMsg),
            provider: Yup.string().nullable().required(configJSON.policyProviderMsg),
            plan_name: Yup.string().nullable().required(configJSON.planNameMsg),
            policy_term:Yup.string().nullable().required(configJSON.policyTermMsg),
            payment_frequency: Yup.string().nullable().required(configJSON.paymentFrequencyMsg),
            start_date: Yup.string().nullable().required(configJSON.startDateOfPolicyMsg),
            maturity_date: Yup.string().nullable().required(configJSON.maturityDateOfPolicyMsg),
            sum_assured: Yup.number().typeError("Please enter number value only").nullable().required(configJSON.sumAssuredMsg),
          })),
          otherwise: Yup.array().of(Yup.object().nullable()),
        }),
      });
    }
      else{
        return Yup.object().nullable();
      }
  };


  handleCreateFileUpload = async(event: ChangeEvent<HTMLInputElement>, setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    }) => {
    if (this.state.files.length > 0 && 'content_type' in this.state.files[0]) {
      this.setState({ files: [] });
    }

    if (event.target.files) {
      const newFilesEditUpload = Array.from(event.target.files);
      this.setState((prevState) => ({
        files: [...prevState.files, ...newFilesEditUpload],
      }), () => {
        // Set the field value after the state has been updated
        setFieldValue('will.files', [...this.state.files]);
      });
    }
  };

  calenderIcon = () => {
    return (
      <div className="calenderIconStyle">
        <svg fill="none" height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke="#A190C2" d="M8 2V5"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 2V5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.5 9.08984H20.5" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#A190C2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M11.9955 13.6992H12.0045"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8.29431 13.6992H8.30329" stroke="#A190C2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path stroke="#A190C2" d="M8.29431 16.6992H8.30329" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </div>
    );
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}