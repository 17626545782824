// Customizable Area Start
import React from "react";
import BlogListController, { Blog, Props } from "./BlogListController.web";
import { styled, ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Avatar, Box, Button, Grid, Typography } from "@material-ui/core";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { configJSON } from "./BlogContentController";
import WebHeader from "../../landingpage/src/WebHeader.web";
import WebFooter from "../../../components/src/Footer.web";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

export const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: "Lato",
        fontWeight: 800,
        fontSize: "48px",
        lineHeight: "63px",
        color: "#0F172A"
      },
      h2: {
        fontFamily: "Open sans",
        fontSize: "24px",
        color: "#000000",
        fontWeight: 700,
        lineHeight: "28.8px",
      },
      h3: {
        fontFamily: "Open sans",
        fontSize: "24px",
        color: "#94A3B8",
        fontWeight: 600,
        lineHeight: "63px",
      },
      h4: {
        fontFamily: "Roboto",
        fontSize: "20px",
        color: "#000000",
        fontWeight: 700,
        lineHeight: "24px",
      },
      h5: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "26px",
        color: "#94A3B8",
        margin: "20px 12px"
      },
      h6: {
        color: "#1E293B",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 500,
        fontFamily: "Work sans",
        marginLeft: "10px"
      },

      body2: {
        fontFamily: "Lato",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#64748B",
        padding: '10px 0px 10px 26px',
        position: "relative",
        cursor: "pointer"
      },
      subtitle1: {
        color: "#000000",
        fontWeight: 400,
        fontFamily: "Roboto",
        fontSize: "16px",
        lineHeight: "24px",
      },
      subtitle2: {
        fontSize: '14px',
        fontWeight: 600,
        color: "#000000",
        fontFamily: "Roboto",
        lineHeight: "21px"
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        fontSize: '16px',
        fontFamily: "Lato",
        fontWeight: 600
      },
      outlined: {
        color: "#DC2626",
        border: "1px solid #DC2626"
      },
      contained: {
        backgroundColor: "#F1FDE3",
        padding: "6px 12px",
        color: "#79B432",
        fontSize: "14px",
        lineHeight: "22px",
        boxShadow: "none",
        marginRight: "10px"
      },
      text: {
        color: "#9176C4",
        fontWeight: 500,
        lineHeight: "24px",
        width: "auto",
        height: "auto",
        textDecoration: 'underline',
        textUnderlineOffset: '4px',
        padding: 0
      }
    },
  },
});

export default class BlogList extends BlogListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderBlogs = (type: string, data: Blog[]) => {
    if(this.checkView(type)){
      return (
        <Grid container spacing={2}>
          <Box display="flex" width="100%" p="0 12px" mt="40px" mb="10px" justifyContent="space-between">
            <Typography variant="h2">
              {type}
            </Typography>
           {this.state.viewAll === "" && <Button variant="text" data-test-id="viewAllBlog" onClick={()=> this.handleViewClick(type)}>
              {configJSON.viewAll}
            </Button>}
          </Box>
          {data.length > 0 ?
          <Box>
            <InfiniteScroll
            dataLength={data.length}
            next={this.fetchMoreData}
            hasMore={this.hasMoreData()}
            loader={<h4 style={{ fontFamily: "Open sans", textAlign: "center" }}>Loading...</h4>}
            scrollableTarget="scrollableDiv"
            scrollThreshold="400px"
            style={{overflow: "hidden"}}
            >
              <Grid container spacing={2}>
           {
             this.blogData(data).map((_data) => (
              <Grid item xs={12} sm={6} md={4} className="blogList">
                <img src={_data?.attributes?.banner_url} className="blogsImage" />
                <Box className="blogsInnerContainer">
                  <Button variant="contained">
                    {_data.attributes.blog_category.name}
                  </Button>
                  <Typography variant="subtitle2">
                    {_data.attributes.reading_time}
                  </Typography>
                </Box>
                <Box className="blogsInnerContainer">
                  {_data.attributes.author_name &&<>
                  <Avatar src={_data.attributes.author_image ?? ""} alt={_data.attributes.author_name}/>
                  <Typography variant="h6">
                    {_data.attributes.author_name}
                  </Typography>
                  </>}
                  <span className="eclipse" />
                  <Typography className="publishDate">
                    {moment(_data.attributes.created_at).format("MMM DD,YYYY")}
                  </Typography>
                </Box>
                <Box className="blogsInnerContainer" display="flex" flexDirection="column" style={{alignItems:"flex-start"}}>
                  <Typography variant="h4">
                    {_data.attributes.title}
                  </Typography>
                <Typography variant="subtitle1"
                          dangerouslySetInnerHTML={{ __html: _data.attributes.post }}
                          className="postDetails"
                        />
                </Box>
                <Grid container>
                  <Button variant="text" data-test-id="blogDetailsPage" onClick={() => this.handleNavigation("BlogDetails", _data.attributes.id)}>
                    {configJSON.readMore}
                  </Button>
                  <ChevronRightIcon className="readMoreIcons" style={{
                    color: "#9176C4"
                  }} />
                </Grid>
              </Grid>
            ))
          }
          </Grid>
            </InfiniteScroll>
            </Box>
            :
            <Typography variant="h5">
              {configJSON.noFound}
            </Typography>
          }
        </Grid>
      )
        }
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <BlogWrapper>
        <WebHeader navigation={this.props.navigation} />
          <Grid container className="mainContainer" direction="column" id="scrollableDiv">
            <BreadcrumbStepper isMap={true} steps={this.state.blogBreadCrumb} onStepClick={(path) => this.handleBreadClick(path)} />
            <Typography variant="h3">
              {configJSON.blogsHeading}
            </Typography>
            <Typography variant="h1">
              {this.state.bannerTitle}
            </Typography>
            <Grid container>
              <Grid item md={2} xs={12} className="categoryList">
                {
                  this.state.blogCategories.map((_categories) => (
                    <Typography variant="body2"
                    data-test-id="categoryList"
                      style={{ color: _categories.attributes.id === this.state.selectedCategorie ? "#9176C4" : "" }}
                      onClick={() => this.changeCategory(_categories.attributes.id)}>

                      {_categories.attributes.id === this.state.selectedCategorie && <ChevronRightIcon className="rightIcons" />}
                      {_categories.attributes.name}
                    </Typography>
                  ))
                }
              </Grid>
              <Grid item md={10} xs={12} className="blogList">
               {this.state.bannerImg && <img src={this.state.bannerImg} className="bannerBlog" />}
                {this.renderBlogs(configJSON.mostRead, this.state.moreReadBlog)}
                {this.renderBlogs(configJSON.recent, this.state.recentBlog)}
              </Grid>
            </Grid>
          </Grid>
          <WebFooter handleNavigation={this.handleNavigation} />
        </BlogWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

export const BlogWrapper = styled("div")(({ theme }) => ({
  "& .mainContainer": {
    padding: '56px 46px'
  },
  "& .categoryList": {
    padding: "20px 14px 20px 8px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      width: '100%',
      overflow: "auto"
    }
  },
  "& .blogList": {
    padding: "20px 14px"
  },
  "& .rightIcons": {
    position: "absolute",
    left: 0,
    top: "calc(50% - 12px)"
  },
  "& .bannerBlog": {
    width: '100%',
    aspectRatio: 3.3,
    borderRadius: "8px"
  },
  "& .blogsImage": {
    width: '100%',
    aspectRatio: 1.39,
    borderRadius: "8px",
    marginBottom: "20px"
  },
  "& .publishDate": {
    color: "#97989F",
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "Open sans"
  },
  "& .blogsInnerContainer": {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px"
  },
  "& .postDetails": {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth:"100%"
  },
  "& .eclipse": {
    width: "4px",
    height: "4px",
    backgroundColor: "#CEC3E3",
    borderRadius: "50%",
    margin: '0 5px'
  },
  readMoreIcons: {
    fontSize: '20px',
    marginLeft: 10
  }
}))
// Customizable Area End
