import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { IStep } from "./LegalDataEditController.web";

export const configJSON = require("./config");

export interface Props {
    id: string;
    navigation: any
}

export interface IMedia {
    file_id: number;
    file_name: string;
    content_type: string;
    file_size: number;
    url: string;
}

export interface S {
    letters: ILetters
    delegateInCharge: ISelectOptions[];
    isEditLetters: boolean
    personalLetterSteps: IStep[]
    successFamilyDialog: boolean
    maxPersonalLetetrs: number
}

export interface ILetters {
    personal_letter: IPersonalLetter[];
};

export interface IPersonalLetter {
    delegate_id: string;
    recipient: string;
    letter: string;
    have_special_day: boolean;
    special_day: string;
    files: File[];
};
export interface SS {

}

export default class PersonalLettersEditController extends BlockComponent<Props,
    S,
    SS
> {
    callGetPersonalLettersCallID: string = ""
    callSetPersonalLettersCallID: string = ""
    callGetMembersApiId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),

        ];
        this.state = {
            letters: {
                personal_letter: [{
                    delegate_id: "",
                    recipient: "",
                    letter: "",
                    have_special_day: false,
                    special_day: "",
                    files: [],
                }],
            },
            delegateInCharge: [],
            isEditLetters: false,
            personalLetterSteps: [
                { label: 'Home', path: 'HomePage' },
                { label: 'My Data', path: 'HomePage' },
                { label: 'Personal Letters', path: 'PersonalDetailsEdit' },
            ],
            successFamilyDialog: false,
            maxPersonalLetetrs: 3
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.callGetPersonalLettersCallID) {
            if (responseJSON.data) {
                let letters = {
                    personal_letter: responseJSON.data.length > 0 ?
                        responseJSON.data.map((_data: { attributes: IPersonalLetter }) => {
                            let { delegate_id,
                                recipient,
                                letter,
                                have_special_day,
                                special_day,
                                files } = _data.attributes
                            return ({
                                delegate_id,
                                recipient,
                                letter,
                                have_special_day,
                                special_day,
                                files
                            }

                            )
                        }) : [{
                            delegate_id: "",
                            recipient: "",
                            letter: "",
                            have_special_day: false,
                            special_day: "",
                            files: [],
                        }]
                }
                this.setState({ letters })
            }
        }
        if (apiRequestCallId === this.callSetPersonalLettersCallID) {
            if (responseJSON.data) {
                this.setState({ successFamilyDialog: true })
            } else {
                this.handleErrorMessage(responseJSON.errors)
            }
        }
        if (apiRequestCallId === this.callGetMembersApiId) {
            if (responseJSON.data) {
                let memberDetails: ISelectOptions[] = [];
                responseJSON.data.forEach((member: { attributes: { id: string, first_name: string, last_name: string } }) => {
                    memberDetails.push({
                        value: member.attributes.id.toString(),
                        name: member.attributes.first_name + " " + member.attributes.last_name,
                    });
                });
                this.setState({ delegateInCharge: memberDetails });
            }
        }
        // Customizable Area End
    }
    handlePersonalLettersUpload = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
        {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void;
            (arg0: string, arg1: string): void;
        },
        index: number,
        prevFiles: File[]
    ) => {
        if (event.target.files) {
            const personalFiles = Array.from(event.target.files);
            let files = [...prevFiles, ...personalFiles]
            setFieldValue(`personal_letter.${index}.files`, files)
        }
    };
    

    getDelegateMembers = async () => {
        const token = await getStorageData("token");

        this.callGetMembersApiId = sendAPIRequest(
            configJSON.getDelegateMembersApiEndPoint,
            {
                method: configJSON.getApiRequest,
                headers: {
                    token,
                },
            }
        );
    };

    getMaxLetters = (index: number, length: number) => {
        return index === 0 && length < this.state.maxPersonalLetetrs
    }

    closeSuccessFamilyDialog = () => {
        this.getFamilyDetailsData()
        this.setState({ successFamilyDialog: false, isEditLetters: false, personalLetterSteps: [
            { label: 'Home', path: 'HomePage' },
            { label: 'My Data', path: 'HomePage' },
            { label: 'Personal Letters', path: 'PersonalDetailsEdit' },
        ], });
    }
    handleErrorMessage = (errors: { [key: string]: string }[]) => {
        if (errors.length > 0) {
            errors.forEach(errorObj => {
                Object.entries(errorObj).forEach(([key, message]) => {
                    let errorMessage = key === "token" ? message : key.replace("_", " ") + " " + message
                    toast.error(errorMessage)
                });
            });
        }
    }

    setEditForm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        this.setState((prevState) => ({
            isEditLetters: true,
            personalLetterSteps: [...prevState.personalLetterSteps, { label: 'Edit Details', path: 'PersonalDetailsEdit' }],
        }))
    }

    async componentDidMount() {
        super.componentDidMount()
        this.getFamilyDetailsData()
        this.getDelegateMembers()
        this.setMaxDocumentUpload()
    }

    setMaxDocumentUpload = async () => {
        let subscriptionData = await getStorageData("active_subscription");
        let parsedSubscriptionData = JSON.parse(subscriptionData)?.features.max_personal_letters
        if (parsedSubscriptionData) {
            this.setState({ maxPersonalLetetrs: parseInt(parsedSubscriptionData) })
        }
    }

    isDisableField = (index: number) =>{
        return this.state.isEditLetters ?  index !==0 : !this.state.isEditLetters

    }

    getFamilyDetailsData = async () => {
        const token = await getStorageData("token");

        this.callGetPersonalLettersCallID = sendAPIRequest(
            configJSON.getPersonalLetterEndPoint,
            {
                method: configJSON.validationApiMethodType,
                headers: {
                    token,
                },
            }
        );
    }

    getClassName = () => {
        return this.state.isEditLetters ? "formSectionBackground" : ""
    }

    getVaraintName = () => {
        return this.state.isEditLetters ? "h3" : "h2"
    }

    getTitle = () => {
        return !this.state.isEditLetters ? "Media" : ""
    }

    getFileLength = (length: number) => {
        return !this.state.isEditLetters && length === 0
    }

    validateFamilyDetails = () => {
        if (this.state.isEditLetters) {
            return (
                Yup.object().shape({
                    personal_letter: Yup.array().of(Yup.object().shape({
                        recipient: Yup.string().nullable().required("Please enter recipient"),
                        special_day: Yup.string().when(['have_special_day'], {
                            is: (have_special_day) => have_special_day,
                            then: Yup.string().nullable().required("Please enter special day"),
                            otherwise: Yup.string().nullable(),
                        }),
                        delegate_id: Yup.string().nullable().required("Please select delegate user"),
                    })),
                })
            )
        } else {
            return Yup.object().shape({});
        }
    }

    handleSwitchChange = async (
        letterIndex: number,
        setFieldValue:
            {
                (field: string,
                    value: any,
                    shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
            },
        checked: boolean) => {
        setFieldValue(`personal_letter.${letterIndex}.have_special_day`, checked);
    }

    handleFamilDetailsForm = async (values: ILetters) => {
        if (this.state.isEditLetters) {
            const token = await getStorageData("token");
            const formData = new FormData();
            for (const personalLetter of values.personal_letter) {
                if (personalLetter) {
                    for (const [keyName, value] of Object.entries(personalLetter)) {
                        if (value) {
                            if (keyName === 'delegate_id') {
                                formData.append(`letter[${keyName}]`, value);
                            } else if (keyName === 'files' && personalLetter.files?.length > 0) {
                                for (const file of personalLetter.files) {
                                    const blobFile = await this.fileToBlob(file as File | IMedia);
                                    formData.append(`personal_letter[][${keyName}][]`, blobFile);
                                }
                            } else if (keyName !== 'files') {
                                formData.append(`personal_letter[][${keyName}]`, value as string);
                            }
                        }
                    }
                }
            }

            this.callSetPersonalLettersCallID = sendAPIRequest(
                configJSON.getPersonalLetterEndPoint,
                {
                    method: configJSON.exampleAPiMethod,
                    headers: {
                        token,
                    },
                    body: formData,
                }
            );
        }
    }

    fileToBlob = async (file: File | IMedia) => {
        if ('content_type' in file) {
            return await urlToFile(file.url, file.file_name);
        } else {
            return file as Blob;
        }
    };


    handleNavigation = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

}