import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";

export interface IMedia {
  file_id: number;
  file_name: string;
  file_size: string;
  url: string;
  content_type: string;
}

interface MarkerDetails {
    name: string
    description: string
    files: (File | IMedia)[]
    mapCenter: {
        lat: number
        lng: number
      },
    isEdit: boolean
    isPreview: boolean
    addLocationDialog: boolean
    loading: boolean,
    handleCloseDetailsDialog: () => void
    selectedId: string
    isReview:boolean
    handlePreviewChange?: ()=> void
    handleDeleteID?: (id:string) => void
    handleAddEditID: (id:string) => void
    isForm?:boolean
    handleForm?: (details: {name:string, description: string, lng:string, lat: string})=>void
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  markerDetails: MarkerDetails
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string
  description: string
  files: ( File | IMedia)[]
  nameError: boolean
  fileError: string
  isEdits:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddEditLocationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getMarkersApiCallID: string = ""
  addEditMarkersApiCallID: string = ""
  deleteMarkerApiCallID :string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
     name: "",
      description: "",
      files: [],
      nameError: false,
      fileError: "",
      isEdits:this.props.markerDetails.isEdit
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    let {name, description, files} = this.props.markerDetails
    this.setState({
        name,
        description,
        files
    })
  }

  handleDeleteClick = async () =>{
    if(this.props.markerDetails.isEdit){
      const token = await getStorageData("token");
      let callId = sendAPIRequest(
        configJSON.getPreviousMarkersEndPoint+`/${this.props.markerDetails.selectedId}`,
        {
          method: configJSON.deleteAPIMethod,
          headers: {
            token,
          }
        }
      );

      this.props.markerDetails.handleDeleteID && this.props.markerDetails.handleDeleteID(callId)
    }else{
      this.props.markerDetails.handleCloseDetailsDialog()
    }
  }


  handleFileUpload = (event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({fileError: ""})
    let files = event.target.files
    if(files){
      const fileArray = Array.from(files); 
      if(this.checkFileType(fileArray)){
        this.setState((prevState)=> ({
          files: [...prevState.files , ...fileArray]
        }))
      }
    }
  }

  removeFileByIndex = (index: number) =>{
    let newFiles = [...this.state.files]
    newFiles.splice(index, 1)
    this.setState(({files: newFiles}))
  }

  checkFileType = (files: File[]) =>{
    let isInvalid = files.some(file => {
      return !(file.type.startsWith('image/') || file.type.startsWith('video/'));
  });
  
  if (isInvalid) {
    this.setState({fileError: configJSON.invalidFileError })
      return false
  }
  return true
  }


  handleAddEditForm = async () =>{
    let {name, description,files} = this.state
    let {isPreview, mapCenter, handlePreviewChange, isForm,handleForm, handleCloseDetailsDialog} = this.props.markerDetails
    if(isPreview){
        handlePreviewChange && handlePreviewChange()
      return
    }
    if(name.trim() === ""){
      this.setState({nameError: true})
      return
    }
    if(isForm){
      handleForm && handleForm({name, description,lat: String(mapCenter.lat), lng: String(mapCenter.lng) })
      handleCloseDetailsDialog()
      return 
    }
    const token = await getStorageData("token");
    const formData = new FormData();
    if (files.length > 0) {
      for (const file of files) {
        const url = await this.convertBlob(file);
        if(url)
        formData.append('map[files][]', url);
      }
    }
    formData.append("map[location_name]", name);
    formData.append("map[description]", description);
    formData.append("map[latitude]", String(mapCenter.lat));
    formData.append("map[longitude]", String(mapCenter.lng));
    formData.append("map[validated]", this.state.isEdits ? "false" : "true" );
    let {endPoint, method} = this.getMethodEndPoint()
    let callId = sendAPIRequest(
      endPoint,
      {
        method,
        headers: {
          token,
        },
        body: formData,
      }
    );
    console.log("callId==>",callId)
    this.props.markerDetails.handleAddEditID(callId)

  }
  getMethodEndPoint= ()=>{
    let {isEdit, selectedId} = this.props.markerDetails
    let method = isEdit ? configJSON.putApiMethod :configJSON.exampleAPiMethod
    let endPoint = configJSON.getPreviousMarkersEndPoint
    if(isEdit){
      endPoint = endPoint+`/${selectedId}`
    }
    return {endPoint, method}
  }
  convertBlob = async (file: File | IMedia)=> {
    if ('content_type' in file) {
      return await urlToFile(file.url, file.file_name);
    } else {
        return file as Blob;
    }
};

  handleNameChange = (value: string) =>{
    this.setState({name: value, nameError: false})
  }
  handleDesChange = (value: string) =>{
    this.setState({description: value})
  }

  getButtonName = (newName: string , prevName: string) =>{
    return this.props.markerDetails.isEdit ? newName : prevName
  }

  // Customizable Area End
}
