import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { IMedia } from "./InvestmentsAndFundsEditController.web";
import { toast } from "react-toastify";

export interface ICVInfo {
    have_cv: string
    have_job: string
    files: (File | IMedia)[]
    job_title: string
    job_description: string
};

export interface ICVInformation {
    id: string;
    type: "cv_informations";
    attributes: {
        id: number;
        have_cv: string;
        have_job: string;
        job_title: string;
        description: string;
        account_id: number;
        files: IMedia[];
    }
};


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleError: (error: string) => void
    handleSuccess: () => void
    handleNext: (path: string) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    aboutCV: ICVInfo,
    cvLoading: boolean,
    isEdit: boolean,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AboutCVEditController extends BlockComponent<
    Props,
    S,
    SS
> {
    maxFileSize: number = 15 * 1024 * 1024;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            aboutCV: {
                have_cv: '0',
                have_job: '0',
                job_title: "",
                job_description: "",
                files: []
            },
            cvLoading: false,
            isEdit: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJSON = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.callGetAboutCVDataApiId) {
            if (responseJSON.data) {
                this.handleGetCVApiResponse(responseJSON.data as ICVInformation);
            } else {
                let messError = responseJSON?.errors[0]
                if (messError) {
                    let key = Object.keys(messError)[0]
                    let message = key + " " + messError[key]
                    this.props.handleError(message.replace("_", " "))
                }
            }
        }
        else if (apiRequestCallId === this.callEditAboutCVDataApiId) {
            this.setState({ isEdit: false })
            if (responseJSON.data) {
                this.props.handleSuccess()
            } else {
                let cvError = responseJSON?.errors[0]
                if (cvError) {
                    let key = Object.keys(cvError)[0]
                    let message = key + " " + cvError[key]
                    this.props.handleError(message.replace("_", " "))
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    callGetAboutCVDataApiId: string = "";
    callEditAboutCVDataApiId: string = "";

    async componentDidMount() {
        super.componentDidMount();
        this.getCVData();
    }

    getCVData = async () => {
        const token = await getStorageData("token");

        this.callGetAboutCVDataApiId = sendAPIRequest(
            configJSON.getAboutMeCVDataApiEndPoint,
            {
                method: configJSON.getApiRequest,
                headers: {
                    token,
                },
            }
        );
    };

    handleGetCVApiResponse = (response: ICVInformation) => {
        const attributes = response.attributes;

        let aboutCV = {
            have_cv: attributes.have_cv === "Yes" ? "1" : "0",
            have_job: attributes.have_job === "Yes" ? "1" : "0",
            job_title: attributes.job_title,
            job_description: attributes.description,
            files: attributes.files?.map((mediaFile) => {
                return ({
                    file_id: mediaFile.file_id,
                    file_name: mediaFile.file_name,
                    content_type: mediaFile.content_type,
                    file_size: mediaFile.file_size,
                    url: mediaFile.url,
                })
            }) || [],
        } as ICVInfo;

        this.setState({ aboutCV });
    };

    toggleEditMode = () => {
        this.setState({ isEdit: true });
    };

    handleAboutCVDataFormSubmit = async (values: ICVInfo) => {
        if (!this.state.isEdit) {
            this.toggleEditMode();
            return;
        }

        const token = await getStorageData("token");
        const cvFormData = new FormData();
        this.setState({ cvLoading: true })
        Object.entries(values).forEach(([keyName, value]) => {
            if (value) {
                if (keyName === 'files' && this.shouldAppendFiles(values)) {
                    values.files.forEach((file: File | IMedia) => {
                        cvFormData.append(`cv[${keyName}][]`, file as Blob);
                    });
                }
                if (keyName !== "files") {
                    if (values.have_job === "1" || keyName === "have_cv" || keyName === "have_job") {
                        cvFormData.append(`cv[${keyName}]`, value);
                    }
                }
            }
        });
        this.callEditAboutCVDataApiId = sendAPIRequest(
            configJSON.getAboutMeCVDataApiEndPoint,
            {
                method: configJSON.postApiRequest,
                headers: {
                    token,
                },
                body: cvFormData,
            }
        );
    };

    private shouldAppendFiles(values: ICVInfo): boolean {
        return values.have_cv === "1" && values.files.length > 0 && !('content_type' in values.files[0]);
    }

    validationSchema = () => {
        if (this.state.isEdit) {
            return (
                Yup.object().shape({
                    job_title: Yup.string().when('have_job', {
                        is: "1",
                        then: Yup.string().required('Job title is required'),
                        otherwise: Yup.string(),
                    }),
                })
            )
        }
        else {
            return Yup.object().shape({});
        }
    }


    handleCVFile = async (event: React.ChangeEvent<HTMLInputElement>, setFieldValue:
        {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void;
            (arg0: string, arg1: string): void;
        },
        prevFiles: (File | IMedia)[]) => {
        if (prevFiles.length > 0 && 'content_type' in prevFiles[0]) {
            prevFiles = [];
        }
        if (event.target.files) {
            const cvFiles = Array.from(event.target.files);
            const cvOversizedFiles = cvFiles.filter(file => file.size > this.maxFileSize);
            if (cvOversizedFiles.length > 0) {
                this.props.handleError(configJSON.maxFileSizeError)
                return
            }
            let files = [...prevFiles, ...cvFiles]
            setFieldValue(`files`, files)
        }
    };


    // Customizable Area End
}
