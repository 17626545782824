import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/Utils";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { IMedia } from "./GeoLocationSearchController.web";

interface MapedImage {
    type: string;
    id: string;
    attributes: Attributes;
}

interface Attributes {
    id: string;
    location_name: string;
    description: string;
    latitude: number;
    longitude: number;
    files: (File | IMedia)[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    locationBreadCrumb: {
        label: string;
        path: string
    }[],
    markersList: MapedImage[],
    mapCenter: {
        lat: number
        lng: number
    }
    addLocationDialog: boolean
    name: string
    description: string
    files: (File | IMedia)[]
    selectedId: string
    successMes: string;
    loading: boolean
    sucessDialog: boolean
    isReview:boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class MyLocationController extends BlockComponent<
    Props,
    S,
    SS
> {
    getAllMarkersApiCallID: string = ""
    editMarkersApiCallID: string = ""
    deleteApiCallID: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            locationBreadCrumb: [
                {
                    label: 'Account',
                    path: 'HomePage'
                },
                {
                    label: 'My Locations',
                    path: 'MyLocation'
                }],
            markersList: [],
            mapCenter: {
                lat: 0,
                lng: 0,
            },
            addLocationDialog: false,
            name: "",
            description: "",
            loading: false,
            files: [],
            selectedId: "",
            successMes: "",
            sucessDialog: false,
            isReview:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.getAllMarkersApiCallID) {
            if (response.data.length > 0) {
                this.setState({ markersList: response.data })
            }
        }
        if (apiRequestCallId === this.editMarkersApiCallID) {
            if (response.data) {
                this.handleEditReponse()
            } else {
                this.handleErrorMessage(response?.errors)
            }
        }
        if (apiRequestCallId === this.deleteApiCallID) {
            this.deleteMarkerResponse(response)
        }

        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getPreviousMarkers()
    }

    handleEditReponse = () => {
        this.getPreviousMarkers()
        this.setState({
            loading: false,
            successMes: configJSON.editSuccessMessage,
            sucessDialog: true
        })
        this.handleCloseDetailsDialog()
    }
    getPreviousMarkers = async () => {
        const token = await getStorageData("token");

        this.getAllMarkersApiCallID = sendAPIRequest(
            configJSON.getPreviousMarkersEndPoint,
            {
                method: configJSON.validationApiMethodType,
                headers: {
                    token,
                }
            }
        );
    }


    handleNavigation = (pathName: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };
    handleSucessClose = () => {
        this.setState({ successMes: "", sucessDialog: false, selectedId: "" })
    }

    deleteMarkerResponse = (responseJSON: { message: string,  errors: {message:string}[] }) => {
        if (responseJSON.message === "Location was successfully removed") {
            let list = this.state.markersList.filter((_data: any) => parseInt(_data.id) !== parseInt(this.state.selectedId))
            this.setState({
                markersList: list,
                successMes: configJSON.deleteSuccessMessage,
                sucessDialog: true
            })
        }else{
            this.handleErrorMessage(responseJSON?.errors)
          }
    }

    handleCloseDetailsDialog = () => {
        this.setState({
            addLocationDialog: false,
            description: "",
            name: "",
            selectedId: "",
            files: [],
        })
    }

    handleAddEditID = (callID: string) => {
        this.editMarkersApiCallID = callID
    }

    handleView = (_marker: Attributes) => {
        let mapCenter = { lat: _marker.latitude, lng: _marker.longitude }
        setStorageData("ViewMarker", JSON.stringify(mapCenter))
        this.handleNavigation("Maps")
    }

    handleDeleteMark = async () => {
        this.setState({sucessDialog: false})
        const token = await getStorageData("token");
        this.deleteApiCallID = sendAPIRequest(
            configJSON.getPreviousMarkersEndPoint + `/${this.state.selectedId}`,
            {
                method: configJSON.deleteAPIMethod,
                headers: {
                    token,
                }
            }
        );
    }

    handleDeleteClick = (id: string) => {
        this.setState({ selectedId: id, successMes: "", sucessDialog: true })
    }

    handleErrorMessage = (errors: { message: string }[]) => {
        let message = errors[0]?.message
        toast.error(message)

    }

    markerDetails = () => {
        let { name, description, files, mapCenter, addLocationDialog, loading, selectedId,isReview } = this.state
        return {
            name,
            description,
            files,
            addLocationDialog,
            mapCenter,
            isEdit: true,
            loading,
            isPreview: false,
            selectedId,
            isReview,
            handleCloseDetailsDialog: this.handleCloseDetailsDialog,
            handleAddEditID: this.handleAddEditID
        }
    }
    markerDetailsText = () => {
        let { name, description, files, mapCenter, addLocationDialog, loading, selectedId,isReview } = this.state
        return {
            name,
            description,
            files,
            addLocationDialog,
            mapCenter,
            isEdit: true,
            loading,
            isPreview: true,
            selectedId,
            isReview,
            handleCloseDetailsDialog: this.handleCloseDetailsDialog,
            handleAddEditID: this.handleAddEditID
        }
    }
    

    handleEdit = (_editData: Attributes) => {
        this.setState({isReview:true})
        let { latitude, longitude, location_name, description, id, files } = _editData
        let mapCenter = { lat: latitude, lng: longitude }
        this.setState({
            mapCenter,
            files,
            name: location_name,
            description,
            selectedId: id
        }, () => this.setState({ addLocationDialog: true }))
    }
    handleEdits = (_editData: Attributes) => {
        this.setState({isReview:false,})
        let { latitude, longitude, location_name, description, id, files } = _editData
        let mapCenter = { lat: latitude, lng: longitude }
        this.setState({
            mapCenter,
            files,
            name: location_name,
            description,
            selectedId: id
        }, () => this.setState({ addLocationDialog: true }))
    }

    isLastIndex = (currentIndex: number) => {
        return currentIndex !== (this.state.markersList.length - 1)
    }



    // Customizable Area End
}
