import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    ThemeProvider,
    Typography,
    createTheme,
    styled
} from "@material-ui/core";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import SubscriptionController, {
    Props,
    configJSON,
} from "./SubscriptionController.web";
import BreadcrumbStepper from "../../../components/src/BreadcrumbStepper.web";
import { checkIcon } from "../../../blocks/customisableusersubscriptions/src/assets";
import { stars, stripeIcon } from "./assets";
import { currencySymbolMap } from "../../../blocks/customisableusersubscriptions/src/CustomisableusersubscriptionsController";


const theme = createTheme({
    overrides: {
        MuiTypography: {
            h1: {
                color: "#0F172A",
                fontFamily: "Quattrocento",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "32px",
            },
            h3: {
                color: "#000000",
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "28px",
            },
            h6: {
                color: "#0F172A",
                fontFamily: "Quattrocento",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "24px",
            },
            subtitle1: {
                color: "#94A3B8",
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "28px",
            },
            body1: {
                color: "#0F172A",
                fontFamily: "Lato",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "28px",
            },
            body2: {
                color: "#0F172A",
                fontFamily: "Lato",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "28px",
            },
        },
        MuiButton: {
            outlined: {
                width: "254px",
                height: "48px",
                color: "#DC2626",
                borderColor: "#DC2626",
                fontFamily: "Lato",
                textTransform: "none",
                fontSize: '16px',
                fontWeight: 600,
                borderRadius: "100px",
            },
            contained: {
                width: "254px",
                height: "48px",
                fontFamily: "Lato",
                fontSize: '18px',
                fontWeight: 700,
                color: "#FFFFFF",
                backgroundColor: "#A190C2",
                borderRadius: "100px",
                textTransform: "none",
            },
            text: {
                fontFamily: "Lato",
                fontSize: '14px',
                fontWeight: 500,
                color: "#9176C4",
                textTransform: "none",
            }
        },
    },
});

const steps = [
    { label: 'Account', path: 'Sunscriptions' },
    { label: 'Sunscriptions', path: 'Sunscriptions' },
];
// Customizable Area End

export default class Subscription extends SubscriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderSubscriptionPlanCard = () => {
        const { subscriptionPlan, cancelledSubscription } = this.state;
        return (
            <Card className="subscriptionCard">
                <Box className="cardHeader">
                    <Typography variant="h6">
                        Your Subscription
                        {this.state.isDataSub !== null && (
                            this.state.cancelledSubscription ? (
                                <span className="cancelledTag" data-test-id="cancelledTagText">{configJSON.cancelledTagText}</span>
                            ) : (
                                <span className="activeTag">{configJSON.activeTagText}</span>
                            )
                        )}
                    </Typography>
                </Box>
                <Box className="cardContent">
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">{configJSON.subscriptionPlanLabel}</Typography>
                        <Typography variant="body1">{subscriptionPlan.name}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">{configJSON.paymentFrequencyLabel}</Typography>
                        <Typography variant="body1">{subscriptionPlan.plan_type}</Typography>
                    </Box>
                </Box>
            </Card>
        );
    }

    renderPaymentMethodCard = () => {
        return (
            <Card className="subscriptionCard">
                <Box className="cardHeader">
                    <Typography variant="h6">{configJSON.PaymentMethodTitle}</Typography>
                </Box>
                <Box className="cardContent">
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">{configJSON.methodLabel}</Typography>
                        <Box display="flex" justifyContent="center">
                            <Typography variant="body1">{configJSON.stripeText}</Typography>
                            <img src={stripeIcon} className="methodBadge" />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">{configJSON.nextBillingDateLabel}</Typography>
                        <Typography variant="body1">{this.getNextBillingDate()}</Typography>
                    </Box>
                </Box>
            </Card>
        );
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            subscriptionPlan,
            changeSubscription,
        } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Wrapper>
                    <WebHeader navigation={this.props.navigation} />
                    <Container className="container">
                        <Box>
                            <Box className="subscriptionPlanTitleWrpper">
                                <BreadcrumbStepper data-test-id="stepper" steps={steps} onStepClick={(path) => this.handleNavigation(path)} />
                            </Box>
                        </Box>
                        <Grid container spacing={2} justifyContent="center">
                            {changeSubscription ? (
                                <>
                                    <Grid item md={6} sm={12} className="cardGrid">
                                        <Box className="upgradePlanMsgBox">
                                            <Box display="flex">
                                                <Typography variant="h6" data-test-id="upgradePlanTitle">{configJSON.upgradePlanTitle}</Typography>
                                                <img src={stars} className="methodBadge" />
                                            </Box>
                                            <Box>
                                                <Typography variant="body2">
                                                    {configJSON.upgradePlanDescription}
                                                </Typography>
                                            </Box>
                                            <Box mt={2}>
                                                <Button
                                                    data-test-id="upgradePlanBtn"
                                                    variant="contained"
                                                    className="btnSize"
                                                    onClick={() => this.handleNavigation("Customisableusersubscriptions")}
                                                >
                                                    {configJSON.upgradePlanBtnText}
                                                </Button>
                                            </Box>
                                        </Box>
                                        {this.renderSubscriptionPlanCard()}
                                        {this.renderPaymentMethodCard()}
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Card data-test-id="planCard">
                                            <CardContent style={{ padding: "32px" }}>
                                                <Box className="checkBoxWrapper">
                                                    <Typography variant="h6" gutterBottom>
                                                        {configJSON.planBenefitsTitle}
                                                    </Typography>
                                                </Box>
                                                <Box mt={2} display="flex" justifyContent="space-between">
                                                    <Box display="flex" gridGap={8}>
                                                        <img alt="plan-img" src={subscriptionPlan.image_link} className="planImg" />
                                                        <Typography align="center" variant="h3" style={{ alignContent: "center" }}>
                                                            {subscriptionPlan.name}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography gutterBottom>
                                                            <span className="priceText">{currencySymbolMap[subscriptionPlan.currency_name]}{subscriptionPlan.price}</span> <span className="pricePerText">{subscriptionPlan.plan_type}</span>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="innerHtmlWrapper" dangerouslySetInnerHTML={{ __html: subscriptionPlan.description }} />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item md={6} sm={12}>
                                        {this.renderSubscriptionPlanCard()}
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        {this.renderPaymentMethodCard()}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Box className="btnWrapper">
                            <Button
                                data-test-id="cancelSubscriptionBtn"
                                variant="outlined"
                                className="btnSize"
                                onClick={() => this.handleCancelSubscriptionClick()}
                            >
                                {configJSON.cancelPlanBtnText}
                            </Button>
                            <Button
                                data-test-id="subscriptionBtn"
                                variant="contained"
                                className="btnSize"
                                type="submit"
                                onClick={() => this.handleChangeSubscriptionClick()}
                            >
                                {this.getBtnText()}
                            </Button>
                        </Box>
                    </Container>
                    <WebFooter navigation={this.handleNavigation} />
                </Wrapper>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .container": {
        height: "auto",
        marginTop: "56px",
        marginBottom: "98px",
    },
    "& .alignContent": {
        display: "flex",
        justifyContent: "center"
    },
    "& .subscriptionPlanTitleWrpper": {
        width: "327px",
        heigth: "86px",
        marginBottom: "44px",
    },
    "& .plan-card": {
        cursor: "pointer",
        transition: "all 0.3s ease",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        maxWidth: "387px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 12px 0px",
        padding: "24px",
        marginBottom: "10px",
        boxSizing: "border-box",
    },
    "& .plan-card.selected": {
        border: "1px solid #9176C4",
    },
    "& .planImg": {
        width: "55px",
        height: "44px",
    },
    "& .checkBoxWrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .planGridItem": {
        display: "flex",
        justifyContent: "center",
    },
    "& .priceText": {
        fontFamily: "Quattrocento",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "28px",
        color: "#9176C4",
    },
    "& .pricePerText": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "28px",
        color: "#64748B",
    },
    "& .subscribeBtnWrapper": {
        display: "flex",
        justifyContent: "center",
    },
    "& .innerHtmlWrapper>p": {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "26px",
        color: "#64748B",
    },
    "& .innerHtmlWrapper>ul": {
        listStyle: "none",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        paddingLeft: "22px",
    },
    "& .innerHtmlWrapper>ul>li": {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "22px",
        color: "#334155",
        position: "relative",
        "&:before": {
            content: `url(${checkIcon})`,
            position: "absolute",
            left: "-25px",
            top: "8%",
            width: "16px",
            height: "16px",
        }
    },
    "& .cardGrid": {
        display: "flex",
        flexDirection: "column",
        gap: "32px",
    },
    "& .upgradePlanMsgBox": {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        backgroundColor: "rgba(206, 195, 229, 0.3)",
        borderRadius: "8px",
        padding: "32px",
    },
    "& .subscriptionCard": {
        padding: "32px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
    },
    "& .cardHeader": {
        margin: "16px 0px",
    },
    "& .cardContent": {
        margin: "16px 0px",
        display: "flex",
        flexDirection: "column",
        gap: "25px",
    },
    "& .activeTag": {
        width: "84px",
        height: "26px",
        backgroundColor: "#D1FAE5",
        color: "#059669",
        borderRadius: "40px",
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 700,
        display: "inline-block",
        marginLeft: "10px",
        textAlign: "center",
    },
    "& .cancelledTag": {
        width: "100px",
        height: "26px",
        backgroundColor: "#FEB9B9",
        color: "#DC2626",
        borderRadius: "40px",
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 700,
        display: "inline-block",
        marginLeft: "10px",
        textAlign: "center",
    },
    "& .methodBadge": {
        marginLeft: "16px",
    },
    "& .btnWrapper": {
        justifyContent: "flex-end",
        display: "flex",
        gap: "8px",
        marginTop: "72px",
    },
}));
// Customizable Area End
