import React from "react";
// Customizable Area Start
import {ThemeProvider } from "@material-ui/core/styles";
import AddEditLocationController, {
    configJSON,
    IMedia,
    Props,
} from "./AddEditLocationController.web";
import { Box, Button, Dialog, FormLabel, Grid,Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "../../../components/src/CustomInput";
import { theme, Wrapper } from "./GeoLocationSearch.web";

// Customizable Area End

export default class AddEditLocation extends AddEditLocationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
renderFilesData=(_files: (File | IMedia) [] )=>{
    return(
    
    <Grid container>
    {
        _files.map((_file: File | IMedia, index: number) => {
            let isFileType = (_file as File).type !== undefined;
            let fileType = isFileType ? (_file as File).type : (_file as IMedia).content_type;
            let fileUrl = isFileType ? URL.createObjectURL(_file as File) : (_file as IMedia).url;
            return (
            <Grid item className="uploadImageContainer">
                 {!this.props.markerDetails.isPreview && <CloseIcon onClick={() => this.removeFileByIndex(index)} className="deleteFilesIcon"/>}
                {
                    fileType.startsWith("image/") ? <img src={fileUrl} className="uploadedFiles" alt="file" /> :
                        <video src={fileUrl} className="uploadedFiles" muted />
                }

            </Grid>
        )})
    }
    </Grid>
)}

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {fileError,name, description, files, nameError} = this.state;
        const {addLocationDialog, isPreview, handleCloseDetailsDialog, loading,isReview, isForm} = this.props.markerDetails
        return (
            <>
                <ThemeProvider theme={theme}>
                    {addLocationDialog && <Dialog
                        open={addLocationDialog}
                        onClose={handleCloseDetailsDialog}
                        style={{ borderRadius: 12 }}
                    >   
                    <Wrapper>
                            <Box padding="25px 30px">
                                <Grid container justifyContent="flex-end">
                                    <CloseIcon onClick={handleCloseDetailsDialog} />
                                </Grid>
                                <Grid container>
                                    {isPreview ? <>
                                        <Typography variant="h4" className="headingDes">
                                            {name}
                                        </Typography>
                                        <Grid container className="inputWrapper" justifyContent="space-between">
                                            <Typography variant="h3">
                                                    {configJSON.locationName}:
                                                </Typography>
                                               <Typography variant="subtitle1" className="headingDes">
                                                    {name}
                                                </Typography>
                                                </Grid>
                                                <Grid container className="inputWrapper" justifyContent="space-between">
                                             <Typography variant="h3">
                                                    {configJSON.locationDescription}:
                                                </Typography>
                                            <Typography variant="subtitle1" className="headingDes" style={{WebkitLineClamp: 4}}>
                                                    {description}
                                                </Typography>
                                        </Grid>
                                        {<Grid item xs={12} className="inputWrapper">
                                            <Typography variant="body1">{configJSON.photosAndVideos}</Typography>
                                            
                                            {this.renderFilesData(files)}
                                        </Grid>}
                                    </> : 
                                    <>
                                    <Typography variant="h1">
                                    {this.getButtonName(configJSON.editMarker, configJSON.addNewLocationButton)}
                                    </Typography>

                                    <Grid item xs={12} className="inputWrapper">
                                        <FormLabel component="label">
                                            {configJSON.locationName}<span style={{ color: "red" }}>*</span>
                                        </FormLabel>
                                        <CustomInput
                                            data-test-id="locationName"
                                            className="mapOutlineInput"
                                            error={nameError}
                                            errorMsg={configJSON.nameErrorMessage}
                                            size="small"
                                            variant="outlined"
                                            placeholder={configJSON.locationNamePlace}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleNameChange(e.target.value)
                                            }}
                                            value={name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="inputWrapper">
                                        <FormLabel component="label">
                                            {configJSON.locationDes}
                                        </FormLabel>
                                        <CustomInput
                                            data-test-id="locationDes"
                                            className="mapOutlineInput"
                                            variant="outlined"
                                            size="small"
                                            multiline
                                            placeholder={configJSON.locationDesPlace}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleDesChange(e.target.value)
                                            }}
                                            value={description}
                                        />
                                    </Grid>
                                   {!isForm && <Grid item xs={12} className="inputWrapper">
                                        <Typography variant="body1">{configJSON.uploadMedia}</Typography>
                                        <Typography variant="body2">
                                            {configJSON.uploadMediaDes}
                                        </Typography>
                                        <label
                                            className="uploadOuterBox"                                        >
                                            <input
                                                data-test-id="uploadMarkerImage"
                                                type="file"
                                                accept="image/*,video/*"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={this.handleFileUpload}
                                            />
                                            <Button variant="contained" component="span" className="chooseFileBtn">
                                                Choose File
                                            </Button>
                                        </label>

                                        {fileError !== "" && <Typography variant="subtitle2">{fileError}</Typography>}
                                           {this.renderFilesData(files)}
                                    </Grid>}
                                    </>
                                    }
                                    {isReview &&  <Box width="100%" display="flex" justifyContent="space-between" gridGap="10px" className="inputWrapper">
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            data-test-id="cancelLocationButton"
                                            onClick={
                                                () => { this.handleDeleteClick() }
                                            }
                                        >
                                            {this.getButtonName(configJSON.deleteMarker, configJSON.cancelDilaog)}
                                        </Button>
                                        <Button
                                            fullWidth
                                            disabled={loading}
                                            variant="contained"
                                            data-test-id="addEditLocation"
                                            onClick={
                                                () => { this.handleAddEditForm() }
                                            }
                                        >   
                                        {this.getButtonName(configJSON.editMarker, configJSON.saveLocation)}
                                        </Button>
                                    </Box>}
                                   
                                </Grid>
                            </Box>
                        </Wrapper>
                    </Dialog>}
                </ThemeProvider>
            </>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// Customizable Area End