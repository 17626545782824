import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { IDelegateMemberAPIResponse } from "../../../blocks/customisableuserprofiles/src/DelegateMembersController.web";
import { sendAPIRequest, urlToFile } from "../../../components/src/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { IMedia } from "./InvestmentsAndFundsEditController.web";
import { ISelectOptions } from "../../../blocks/customform/src/LegalInformationController.web";
import { toast } from "react-toastify";

export interface IAttire {
  is_include_final_pdf: boolean;
  delegate_id: string;
  charge_person: string;
  charger_name: string;
  charger_relationship: string;
  upper_cloth: string;
  lower_cloth: string;
  shoes: string;
  socks: string;
  other: string;
  files: (File | IMedia)[];
};

export interface IGetFuneralAttireDataAPIResponse {
    id: string;
    type: "attires";
    attributes: {
        id: number;
        is_include_final_pdf: boolean;
        delegate_id: number;
        charge_person: string;
        charger_name: string;
        charger_relationship: string;
        upper_cloth: string;
        lower_cloth: string;
        shoes: string;
        socks: string;
        other: string;
        account_id: number;
        files: {
            file_id: number;
            file_name: string;
            content_type: string;
            file_size: number;
            url: string;
        }[];
        delegate_charge: {
            name: string;
        };
    };
};
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleError: (error: string)=> void
  handleSuccess: () => void
  handleNextBtnClick: (path: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  attireInfo: IAttire,
  isLoading: boolean,
  files: (File | IMedia)[],
  delegateInCharge: ISelectOptions[],
  attireInCharge: ISelectOptions[],
  isEdit: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FuneralAttireEditController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      attireInfo: {
        is_include_final_pdf: false,
        delegate_id: "",
        charge_person: "",
        charger_name: "",
        charger_relationship: "",
        upper_cloth: "",
        lower_cloth: "",
        shoes: "",
        socks: "",
        other: "",
        files: [],
      },
        isLoading: false,
        files: [],
        delegateInCharge: [],
        attireInCharge: [],
        isEdit: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  callGetDelegateMembersApiId: string = "";
  callGetFuneralAttireDataApiId: string = "";
  callEditFuneralAttireInfoApiId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getDelegateMembersData();
    this.getFuneralAttireData();
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callGetDelegateMembersApiId]: this.handleGetDelegateMembersDataApiResponse,
        [this.callGetFuneralAttireDataApiId]: this.handleGetFuneralAttireDataApiResponse,
        [this.callEditFuneralAttireInfoApiId]: this.handleEditFuneralAttireDataApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };



  getDelegateMembersData = async () => {
    const token = await getStorageData("token");

    this.callGetDelegateMembersApiId = sendAPIRequest(
        configJSON.getDelegateMembersApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: { token },
        }
      );
  };

  handleGetDelegateMembersDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
     this.props.handleError("something went wrong!"); 
     return; 
   }
 
    const response = responseJSON as {
      meta?: {
        message: string
      };
      data?: {
        id: string,
        type: string,
        attributes: IDelegateMemberAPIResponse,
      }[];
    };
 
     if (response.data) {
       let delegateInCharge: ISelectOptions[] = [];
 
       response.data.forEach((member) => {
         delegateInCharge.push(
          {
           value: member.attributes.id.toString(),
           name: member.attributes.first_name + " " + member.attributes.last_name,
         }
        );
       });
 
       this.setState({ 
        delegateInCharge,
        attireInCharge: [
          ...delegateInCharge,
          {
            value: "0",
            name: "Other"
          }
        ]
       });
     }
   };

  handleErrorResponse = (
    responseJSON: Record<string, unknown>
  ) => {
    const { errors: possibleErrors } = responseJSON;

    if (possibleErrors) {
      return true;
    }
    return false;
  };

  getFuneralAttireData = async () => {
    const token = await getStorageData("token");

    this.callGetFuneralAttireDataApiId = sendAPIRequest(
        configJSON.getFuneralAttireDataApiEndPoint,
        {
          method: configJSON.getApiRequest,
          headers: { token },
        }
      );
  };

  handleGetFuneralAttireDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      this.props.handleError("something went wrong!");
      return; 
    }

    const response = responseJSON as {
        meta?: { message: string };
        data?: IGetFuneralAttireDataAPIResponse;
    };

    if (response.data) {
        const attributes = response.data.attributes;

        if(attributes.files){
            this.setState({ files: attributes.files.map((mediaFile) => {
              return({
                file_id: mediaFile.file_id,
                file_name: mediaFile.file_name,
                content_type: mediaFile.content_type,
                file_size: mediaFile.file_size,
                url: mediaFile.url,
              })
            }),
          })
          }

        let attireInfo = {
            is_include_final_pdf: attributes.is_include_final_pdf,
            delegate_id: attributes.delegate_id.toString(),
            charge_person: attributes.charge_person,
            charger_name: attributes.charger_name,
            charger_relationship: attributes.charger_relationship,
            upper_cloth: attributes.upper_cloth,
            lower_cloth: attributes.lower_cloth,
            shoes: attributes.shoes,
            socks: attributes.socks,
            other: attributes.other,
            files: attributes.files?.map((mediaFile) => {
                return({
                  file_id: mediaFile.file_id,
                  file_name: mediaFile.file_name,
                  content_type: mediaFile.content_type,
                  file_size: mediaFile.file_size,
                  url: mediaFile.url,
                })
              }) || [], 
        } as IAttire;
        this.setState({ attireInfo });
      }
  };


   handleEditFuneralAttireDataApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      this.props.handleError("something went wrong!");
      return; 
    }

    this.setState({isLoading: false, isEdit: false });
    if (responseJSON.data) {
      this.props.handleSuccess();
    }
  };

  toggleEditMode = () => {
    this.setState({ isEdit: true });
  };

  handleFuneralAttireDataFormSubmit = async (values: IAttire) => {
    if (!this.state.isEdit) {
      this.toggleEditMode();
    }
    else {
      const token = await getStorageData("token");
      const formData = new FormData();

      this.setState({ isLoading: true });

      // Append main form values
      Object.entries(values).forEach(([keyName, value]) => {
        if (keyName === 'files') {
          this.appendFiles(values.files, this.state.files, formData);
        } else {
          formData.append(`attire[${keyName}]`, value as string);
        }
      });

      this.callEditFuneralAttireInfoApiId = sendAPIRequest(
        configJSON.getFuneralAttireDataApiEndPoint,
        {
          method: configJSON.postApiRequest,
          headers: {
            token,
          },
          body: formData,
        }
      );
    }
  };

  appendFiles = (files: (File | IMedia)[], stateFiles: (File | IMedia)[], formData: FormData) => {
    if (stateFiles.length > 0 && files && files.length > 0) {
      files.forEach(async (file: File | IMedia) => {
        const blobFile = await this.convertToBlob(file);
        formData.append(`attire[files][]`, blobFile);
      });
    }
  };

  // Convert file (either an existing IMedia or new File) to Blob
  convertToBlob = async (file: IMedia | File): Promise<Blob> => {
    if ('content_type' in file) {
      return await urlToFile(file.url, file.file_name);
    } else {
      return file as Blob;
    }
  };

  validationSchema = (isEdit: boolean) => {
    if (isEdit) {
      return Yup.object().shape({
        delegate_id: Yup.string().nullable().required(configJSON.delegateInchargeMsg),
        charge_person: Yup.string().nullable().required("Please select attire in charge"),
      });
    }
    else {
      return Yup.object().nullable();
    }
  }

  handleFileUploadAttireFineral = async(event: React.ChangeEvent<HTMLInputElement>, 
    setFieldValue:
    {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; 
        (arg0: string, arg1: string): void;
    },
  ) => {
    if (this.state.files.length > 0 && 'content_type' in this.state.files[0]) {
      this.setState({ files: [] });
    }

    if (event.target.files) {
      const newFiles = Array.from(event.target.files);

      this.setState((prevState) => (
      {
        files: [...prevState.files, ...newFiles],
      }), () => {
        setFieldValue('files', [...this.state.files]);
      });
    }
  };
  
  // Customizable Area End
}
