import React from "react";
// Customizable Area Start
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Typography,
  Avatar,
  Button,
  IconButton,
  Switch,
  Badge,
  Menu,
  Fade,
  MenuItem,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-input-2/lib/style.css'

import LeftPanel from "../../../components/src/LeftPanel";
import CustomInput from "../../../components/src/CustomInput";
import CustomSelect from "../../../components/src/CustomSelect";
import GenericModal from "../../../components/src/GenericModal";
import { rightTickImg } from "../../../blocks/forgot-password/src/assets";
import CustomisableUserProfilesController, {
  Props,
  IField,
  configJSON
} from "./CustomisableUserProfilesController";
import CustomLocation from "../../../components/src/CustomLocation.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiTypography: {
      h1: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
      },
      h5: {
        color: "#0F172A",
        fontFamily: "Quattrocento",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
      },
      h6: {
        color: "#9176C4",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "18px",
      },
      subtitle1: {
        color: "#64748B",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
      },
      body1: {
        color: "#9176C4",
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
      },
      body2: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
      },
    },
    MuiStepLabel: {
      root: {
        label: {
          fontFamily: "Lato",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "24px",
          color: "#334155",
        },
        "& [class*=.MuiStepLabel-label-]": {
          color: "#334155",
        },
      }
    },
    MuiFormLabel: {
      root: {
        color: "#334155",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
      }
    },
    MuiOutlinedInput: {
      root: {
        minHeight: "48px",
        borderRadius: "8px",
        "& .Mui-focused": {
          "& [class*=MuiOutlinedInput-notchedOutline]": {
            borderColor: "#94A3B8 !important",
          }
        },
        "& [class*=MuiOutlinedInput-root].Mui-focused": {
          borderColor: "#94A3B8",
        }
      },
      input: {
        "&::focused": {
          borderColor: "#94A3B8 !important",
        },
        "&::placeholder": {
          fontFamily: "Lato !important",
          opacity: 1,
          color: "#94A3B8",
          fontWeight: 400,
        },
      },
    },
    MuiInputBase: {
      root: {
        minHeight: "48px",
        borderRadius: "8px",
        "& .Mui-focused": {
          "& [class*=MuiOutlinedInput-notchedOutline]": {
            borderColor: "#94A3B8 !important",
          }
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: "#9176C4",
        },
      }
    },
    MuiButton: {
      outlined: {
        width: "248px",
        height: "48px",
        color: "#9176C4",
        textTransform: "none",
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: "100px",
      },
      contained: {
        width: "248px",
        height: "48px",
        color: "#FFFFFF",
        backgroundColor: "#A190C2",
        textTransform: "none",
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 600,
        borderRadius: "100px",
      },
    },
    MuiDialog: {
      paper: {
        maxWidth: "418px !important",
      },
    },
    MuiSwitch: {
      root: {
        padding: 0,
        borderRadius: "40px",
        width: "48px",
        height: "28px",
      },
      switchBase: {
        padding: "2px",
        color: "#fff",
        '&$checked': {
          transform: 'translateX(20px)',
          color: "#fff",
          '& + $track': {
            opacity: 1,
            backgroundColor: "#A190C2 !important",
            borderColor: "#A190C2 !important",
          },
        },
      },
      thumb: {
        width: "24px",
        height: "24px",
        boxShadow: 'none',
      },
      track: {
        borderRadius: "40px",
        opacity: 1,
        backgroundColor: "gray",
        color: "fff"
      },
      colorPrimary: {
        '&$checked': {
          color: "#fff",
          padding: "2px",
        }
      }
    },
    MuiTooltip: {
      popper: {
        backgroundColor: "none",
      }
    },
  },
}
);
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.value, field.field_type)
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
          // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Wrapper>
          <LeftPanel>
            <ThemeProvider theme={theme}>
              <Box pl={6} py={9} className="rightPanelWrapper">
                <Box className="stepperWrapper">
                  <Stepper className="stepper" nonLinear>
                    <Step key="Profile">
                      <StepLabel data-test-id="stepLabel" className="stepLabel" StepIconComponent={(props) => (
                        <StepIcon icon={
                          <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="3" y="3.5" width="26" height="26" rx="13" fill="#F8FAFC" />
                            <rect x="3" y="3.5" width="26" height="26" rx="13" stroke="#CEC3E3" stroke-width="6" />
                            <path d="M13.4497 19.7863L10.6883 16.8429C10.5396 16.684 10.3378 16.5948 10.1273 16.5948C9.91679 16.5948 9.71494 16.684 9.56626 16.8429C9.25591 17.1737 9.25591 17.7081 9.56626 18.0389L12.8926 21.5846C13.203 21.9154 13.7043 21.9154 14.0147 21.5846L22.4341 12.6101C22.7444 12.2793 22.7444 11.7449 22.4341 11.4141C22.2854 11.2553 22.0835 11.166 21.873 11.166C21.6625 11.166 21.4607 11.2553 21.312 11.4141L13.4497 19.7863Z" fill="#CEC3E3" />
                          </svg>
                        } />
                      )}><Typography variant="body2">{configJSON.createProfileStep1}</Typography></StepLabel>
                    </Step>
                    <Step key="Delegate">
                      <StepLabel data-test-id="stepLabel" className="stepLabel" StepIconComponent={(props) => (
                        <StepIcon icon={
                          <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="3" y="3.5" width="26" height="26" rx="13" fill="#F8FAFC" />
                            <rect x="3" y="3.5" width="26" height="26" rx="13" stroke="#CEC3E3" stroke-width="6" />
                            <path d="M13.4497 19.7863L10.6883 16.8429C10.5396 16.684 10.3378 16.5948 10.1273 16.5948C9.91679 16.5948 9.71494 16.684 9.56626 16.8429C9.25591 17.1737 9.25591 17.7081 9.56626 18.0389L12.8926 21.5846C13.203 21.9154 13.7043 21.9154 14.0147 21.5846L22.4341 12.6101C22.7444 12.2793 22.7444 11.7449 22.4341 11.4141C22.2854 11.2553 22.0835 11.166 21.873 11.166C21.6625 11.166 21.4607 11.2553 21.312 11.4141L13.4497 19.7863Z" fill="#CEC3E3" />
                          </svg>
                        } />
                      )}><Typography variant="body2">{configJSON.createProfileStep2}</Typography></StepLabel>
                    </Step>
                  </Stepper>
                </Box>
                <Box className="createProfileFormTitleWrapper">
                  <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <Typography data-test-id="profileFormTitle" variant="h5">
                      {configJSON.createProfileTitle}
                    </Typography>
                    <ErrorOutlineIcon className="ErrorOutlineIcon" />
                  </Box>
                  <Typography variant="subtitle1">
                    {configJSON.createProfileSubtitle}
                  </Typography>
                </Box>
                <Box mt={3} mr={7}>
                  <Formik
                    initialValues={this.state.userDetails}
                    validationSchema={this.validationSchema(this.state.phoneNumberLength)}
                    onSubmit={(values) => {
                      this.handleProfileFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="createProfileForm"
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <Box>
                          <input
                            id="profilePicInput"
                            data-test-id="profilePic"
                            name="profile_pic"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(event) => this.handleProfileImg(event, setFieldValue)}
                          />
                          <label htmlFor="profilePicInput">
                            <IconButton component="span" style={{ paddingLeft: '0' }}>
                              {this.state.profileImage || values.profileImage ? (
                                <Badge
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  className="profilePicBadge"
                                  badgeContent={
                                    <>
                                      <div onClick={(e: React.MouseEvent<HTMLInputElement>) => this.handleProfilePicEditMenu(e)}>
                                        <IconButton style={{ padding: "0" }}>
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="24" height="24" rx="12" fill="#9176C4" />
                                            <path d="M17.25 17.834H6.75C6.51083 17.834 6.3125 17.6357 6.3125 17.3965C6.3125 17.1573 6.51083 16.959 6.75 16.959H17.25C17.4892 16.959 17.6875 17.1573 17.6875 17.3965C17.6875 17.6357 17.4892 17.834 17.25 17.834Z" fill="white" />
                                            <path d="M16.095 7.02964C14.9633 5.89798 13.855 5.86881 12.6942 7.02964L11.9883 7.73548C11.93 7.79381 11.9067 7.88714 11.93 7.96881C12.3733 9.51464 13.61 10.7513 15.1558 11.1946C15.1792 11.2005 15.2025 11.2063 15.2258 11.2063C15.29 11.2063 15.3483 11.183 15.395 11.1363L16.095 10.4305C16.6725 9.85881 16.9525 9.30464 16.9525 8.74464C16.9583 8.16714 16.6783 7.60714 16.095 7.02964Z" fill="white" />
                                            <path d="M14.1058 11.726C13.9367 11.6443 13.7733 11.5626 13.6158 11.4693C13.4875 11.3935 13.365 11.3118 13.2425 11.2243C13.1433 11.1601 13.0267 11.0668 12.9158 10.9735C12.9042 10.9676 12.8633 10.9326 12.8167 10.886C12.6242 10.7226 12.4083 10.5126 12.2158 10.2793C12.1983 10.2676 12.1692 10.2268 12.1283 10.1743C12.07 10.1043 11.9708 9.98763 11.8833 9.85346C11.8133 9.76596 11.7317 9.63763 11.6558 9.5093C11.5625 9.3518 11.4808 9.1943 11.3992 9.03096C11.2921 8.80152 10.9909 8.73335 10.8119 8.9124L7.53167 12.1926C7.45583 12.2685 7.38583 12.4143 7.36833 12.5135L7.05333 14.7476C6.995 15.1443 7.10583 15.5176 7.35083 15.7685C7.56083 15.9726 7.8525 16.0835 8.1675 16.0835C8.2375 16.0835 8.3075 16.0776 8.3775 16.066L10.6175 15.751C10.7225 15.7335 10.8683 15.6635 10.9383 15.5876L14.224 12.302C14.3994 12.1265 14.3335 11.8246 14.1058 11.726Z" fill="white" />
                                          </svg>
                                        </IconButton>
                                      </div>
                                      <Menu
                                        id="fade-menu"
                                        anchorEl={this.state.anchorEl}
                                        keepMounted
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                        open={Boolean(this.state.anchorEl)}
                                        onClose={this.handleProfilePicEditMenuClose}
                                        TransitionComponent={Fade}
                                      >
                                        <MenuItem>
                                          <input
                                            id="changeProfilePicInput"
                                            data-test-id="change-image"
                                            name="profile_pic_change"
                                            type="file"
                                            accept="image/*"
                                            onChange={(event) =>
                                              this.handleProfileImg(event, setFieldValue)
                                            }
                                            style={{ display: 'none' }}
                                          />
                                          <label htmlFor="changeProfilePicInput" style={{ display: "flex" }}>
                                            <Box>
                                              <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill="none">
                                                <path stroke="#292D32" d="M9.94501 2.70041L3.78751 9.21791C3.55501 9.46541 3.33001 9.95291 3.28501 10.2904L3.00751 12.7204C2.91001 13.5979 3.54001 14.1979 4.41001 14.0479L6.82501 13.6354C7.16251 13.5754 7.63501 13.3279 7.86751 13.0729L14.025 6.55541C15.09 5.43041 15.57 4.14791 13.9125 2.58041C12.2625 1.02791 11.01 1.57541 9.94501 2.70041Z" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.91748 3.78711C9.23998 5.85711 10.92 7.43961 13.005 7.64961" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M2.25 16.5H15.75" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                              </svg>
                                            </Box>
                                            &nbsp;{configJSON.changePicture}
                                          </label>
                                        </MenuItem>
                                        <MenuItem onClick={() => this.handleRemoveProfilePic(setFieldValue)}>
                                          <Box>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M15.75 4.48438C13.2525 4.23687 10.74 4.10938 8.235 4.10938C6.75 4.10938 5.265 4.18438 3.78 4.33438L2.25 4.48438" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                              <path d="M6.375 3.7275L6.54 2.745C6.66 2.0325 6.75 1.5 8.0175 1.5H9.9825C11.25 1.5 11.3475 2.0625 11.46 2.7525L11.625 3.7275" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                              <path d="M14.1375 6.85547L13.65 14.408C13.5675 15.5855 13.5 16.5005 11.4075 16.5005H6.59255C4.50005 16.5005 4.43255 15.5855 4.35005 14.408L3.86255 6.85547" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                              <path d="M7.74756 12.375H10.2451" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                              <path d="M7.125 9.375H10.875" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                          </Box>
                                          &nbsp;{configJSON.removePicture}</MenuItem>
                                      </Menu>
                                    </>
                                  }
                                >
                                  <Avatar
                                    className="profilePicAvatar"
                                    style={{ margin: '5px 0 10px 0' }}
                                    alt={values.firstName || ''}
                                    src={(this.state.profileImage || values.profileImage) as string}
                                  />
                                </Badge>
                              ) : (
                                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="0.5" y="0.5" width="61" height="61" rx="30.5" fill="#F8FFF0" />
                                  <rect x="0.5" y="0.5" width="61" height="61" rx="30.5" stroke="#ACCC86" />
                                  <path
                                    d="M33.12 24L34.95 26H39V38H23V26H27.05L28.88 24H33.12ZM34 22H28L26.17 24H23C21.9 24 21 24.9 21 26V38C21 39.1 21.9 40 23 40H39C40.1 40 41 39.1 41 38V26C41 24.9 40.1 24 39 24H35.83L34 22ZM31 29C32.65 29 34 30.35 34 32C34 33.65 32.65 35 31 35C29.35 35 28 33.65 28 32C28 30.35 29.35 29 31 29ZM31 27C28.24 27 26 29.24 26 32C26 34.76 28.24 37 31 37C33.76 37 36 34.76 36 32C36 29.24 33.76 27 31 27Z"
                                    fill="#ACCC86"
                                  />
                                </svg>
                              )}
                            </IconButton>
                          </label>
                        </Box>
                        <Grid container spacing={2}>
                          {this.renderUserDetailsForm(values, errors, touched, setFieldValue)}
                          <Grid item style={{ padding: '16px 0px 16px' }} xs={12}>
                            <Typography variant="h6" className="addressTitle">
                              <ErrorOutlineIcon className="ErrorOutlineIcon" />{configJSON.addressTitle}
                            </Typography>
                          </Grid>
                          {this.renderAddressForm(values, errors, touched, setFieldValue)}
                          <Grid item style={{ padding: '16px 0px 12px' }} xs={12}>
                          <CustomLocation navigation={this.props.navigation} ref={this.childsRef} />
                          </Grid>
                          <Grid item style={{ padding: '16px 0px 12px' }} xs={12}>
                            <Typography variant="body1">
                              {configJSON.profileCreationReason}
                            </Typography>
                          </Grid>
                          <Grid item className="paddingLeft" xs={12}>
                            <FormLabel component="label">{configJSON.reasonFieldLabel}</FormLabel>
                            <Field
                              data-test-id="reason"
                              name="reason"
                              size="medium"
                              multiline
                              style={{ minHeight: "84px" }}
                              as={CustomInput}
                              placeholder="Enter your reason"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("reason", e.target.value)
                              }
                              value={values.reason}
                              error={touched.reason && errors.reason}
                              errorMsg={errors.reason}
                            />
                          </Grid>
                          <Grid item className="paddingLeft" xs={12}>
                            <Typography variant="body1">
                              {configJSON.partnerDetails}
                            </Typography>
                          </Grid>
                          <Grid item className="paddingLeft" lg={6}>
                            <FormLabel component="label">
                              {configJSON.partnershipStatusFieldLabel}<span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Field
                              data-test-id="partnershipStatus"
                              name="partnershipStatus"
                              className="selectField"
                              as={CustomSelect}
                              placeholder="Select partnership status"
                              options={[
                                { value: 1, name: "Single" },
                                { value: 2, name: "Engaged" },
                                { value: 3, name: "Married" },
                                { value: 4, name: "Widowed" },
                                { value: 5, name: "Civil Partnership" },
                                { value: 6, name: "Casual" },
                              ]}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("partnershipStatus", e.target.value)
                              }
                              value={values.partnershipStatus}
                              error={touched.partnershipStatus && errors.partnershipStatus}
                              errorMsg={errors.partnershipStatus}
                            />
                          </Grid>
                          {values.partnershipStatus === "Married" && (
                            <Grid item className="paddingLeft" xs={12}>
                              <Box className="delegateMemberWrapper">
                                <Typography align="center" className="delegateMemberLabel">
                                  <ErrorOutlineIcon className="ErrorOutlineIcon" />{configJSON.addyYourPartnerTitle}
                                </Typography>
                                <Switch
                                  data-test-id="partnerSwitch"
                                  color="primary"
                                  checked={this.state.switchChecked}
                                  onChange={() =>
                                    this.handleSwitchChange(setFieldValue)
                                  }
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </Box>
                            </Grid>
                          )}
                          {values.partnershipStatus === "Married" &&
                            this.state.switchChecked &&
                            (this.renderDelegateForm(values, errors, touched, setFieldValue))}
                          <Grid item className="paddingLeft" xs={12}>
                            <Typography variant="body1">
                              {configJSON.healthAndFitness}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className="healthAndFitness paddingLeft">
                            <FormLabel component="label" className="formRadioLabel">
                              {configJSON.areYouFitAndWell}
                            </FormLabel>
                            <RadioGroup
                              data-test-id="healthAndFitness"
                              aria-label="healthAndFitness"
                              name="healthAndFitness"
                              value={values.isHealthy}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("isHealthy", e.target.value)
                              }
                              className="radioGroup"
                            >
                              <FormControlLabel
                                value="Yes"
                                control={<Radio />}
                                label="Yes"
                                className="radioLabel"
                              />
                              <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label="No"
                                className="radioLabel"
                              />
                            </RadioGroup>
                          </Grid>
                          {values.isHealthy === "No" && (
                            <Grid item className="paddingLeft" xs={12}>
                              <FormLabel component="label">{configJSON.issueFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                              <Field
                                data-test-id="issue"
                                name="issue"
                                size="medium"
                                style={{ minHeight: "84px" }}
                                multiline
                                as={CustomInput}
                                placeholder="Enter your issue"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setFieldValue("issue", e.target.value)
                                }
                                value={values.issue}
                                error={touched.issue && errors.issue}
                                errorMsg={errors.issue}
                              />
                            </Grid>
                          )}
                          <Grid item className="paddingLeft" xs={12}>
                            <Box className="btnWrapper">
                              <Button
                                data-test-id="skipForNow"
                                variant="outlined"
                                className="btnSize"
                                onClick={() => this.handleSkipForNowBtnClick()}>
                                {configJSON.skipForNowBtnText}
                              </Button>
                              <Button
                                data-test-id="createProfileBtn"
                                variant="contained"
                                className="btnSize"
                                type="submit"
                              >
                                {configJSON.createProfileBtnText}
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </ThemeProvider>
          </LeftPanel>
        </Wrapper>
        <Box className="dialogWrapper">
          <GenericModal open={this.state.openDialog} dataTest="create-profile" onClose={this.handleCloseDialog}>
            <ThemeProvider theme={theme}>
              <DialogWrapper>
                <Box className="alignCenter">
                  <img alt="success" src={rightTickImg} className="rightTickImg" />
                  <Typography align="center" variant="h1" data-test-id="passwordUpdatedMsg">
                    {configJSON.profileCreated}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{
                      color: "#64748B",
                      fontWeight: 400,
                      fontSize: "18px"
                    }}>
                    {configJSON.modalSuccessMsg}
                  </Typography>
                </Box>
                <Box className="btnWrapper">
                  <Button
                    fullWidth
                    style={{ width: "100%" }}
                    className="containedBtn"
                    variant="contained"
                    data-test-id="addDelegatesBtn"
                    onClick={() => this.handleAddDelegate()}>
                    {configJSON.addDelegatesBtnText}
                  </Button>
                </Box>
              </DialogWrapper>
            </ThemeProvider>
          </GenericModal>
        </Box>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled('div')(({ theme }) => ({
  "& .rightPanelWrapper": {
    maxHeight: "100vh",
    overflowY: "auto",
    boxSizing: "border-box",
  },
  "& .container": {
    minHeight: '100vh',
  },
  "& .stepper": {
    padding: "0",
    fontFamily: "Lato !important",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  "& .stepperWrapper": {
    width: "415px",
  },
  "& .stepLabel": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  "& .createProfileFormTitleWrapper": {
    width: "327px",
    height: "59",
    margin: "16px 0px",
  },
  "& .profilePicAvatar": {
    width: "62px",
    height: "62px",
  },
  "& .ErrorOutlineIcon": {
    color: "#9176C4",
    "&:hover": {
      fill: "#9176C4",
    }
  },
  "& .profilePic": {
    marginBottom: "16px",
  },
  "& .calenderIcon": {
    cursor: "grab",
  },
  "& .inputField": {
    height: "48px",
    width: "300px",
    borderRadius: "8px",
  },
  "& .selectField": {
    borderRadius: "8px",
    margin: "5px 0px",
    display: "block",
    height: "48px",
    width: "300px",
  },
  "& .addressTitle": {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    maxWidth: "570px",
  },
  "& .healthAndFitness": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .formRadioLabel": {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26px",
  },
  "& .radioGroup": {
    display: 'flex',
    flexDirection: 'row',
  },
  "& .radioLabel": {
    color: '#000',
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#9176C4',
    },
  },
  "& .btnWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
  },
  "& .delegateMemberWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .delegateMemberLabel": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#1E293B",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .btnSize": {
    width: "240px",
    height: "48px",
  },
  "& .paddingLeft": {
    paddingLeft: "0px",
  },
  //PhoneInput CSS override
  "& .react-tel-input .flag-dropdown.open .selected-flag": {
    backgroundColor: "unset",
  },
  "& .react-tel-input .selected-flag:hover": {
    backgroundColor: "unset",
  },
  "& .errorIcon": {
    width: "16px",
    height: "16px",
    marginRight: "2px",
    marginBottom: "3px",
  },
  "& .errorMsg": {
    color: "#DC2626",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
}));

const DialogWrapper = styled('div')(({ theme }) => ({
  "& .dialogWrapper": {
    maxWidth: "418px !important",
  },
  "& .btnWrapper": {
    display: "flex",
    gap: "8px",
    justifyContent: "center",
    marginTop: "32px",
  },
  "& .alignCenter": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flexDirection: "column",
  },
  "& .emailSpan": {
    fontWeight: 700,
    fontColor: "Black",
  },
  "& .outlinedBtn": {
    heigh: "56px",
    width: "192px",
    color: "#9176C4",
    padding: "16px",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Lato",
    borderRadius: "100px",
    textTransform: "none",
  },
  "& .containedBtn": {
    width: "192px",
    heigh: "56px",
    color: "#FFFFFF",
    backgroundColor: "#A190C2",
    padding: "16px",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Lato",
    borderRadius: "100px",
    textTransform: "none",
  },
  "& .rightTickImg": {
    height: "72px",
    width: "72px",
  },
  "& .infoPaper": {
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    width: "293px",
    height: "196px",
  },
  [theme.breakpoints.down('xs')]: {
    "& .btnWrapper": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    }
  }
}));
// Customizable Area End
